import api from "./api";

export async function getAllRelatorios() {
    const res = await api.get(`/relatorio/mensal`)
    return res.data
}

export async function createNewRelatorio(id, valor_kwh, data_relatorio) {
    const res = await api.post(`/relatorio/mensal/usina/${id}`, {valor_kwh, data_relatorio})
    return res.data
}
