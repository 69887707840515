import api from "./api";

export async function findLastRegister(usina_id) {
    const res = await api.get(`estacao/usina/${usina_id}/ultimo`);
    return res.data;
}

export async function findBetweenDatesEstacao({ usinaId, intervaloDTO }) {
    const res = await api.get(`estacao/usina/${usinaId}/findBetweenDates`, {
        params: { ...intervaloDTO },
    });
    return res.data;
}

export async function exportRelatorioEstacao({ usinaId, intervaloDTO, reportTypeDto }) {
    const params = {
        startDate: intervaloDTO.startDate,
        endDate: intervaloDTO.endDate,
        ...reportTypeDto,
    };

    const res = await api.get(`estacao/usina/${usinaId}/findBetweenDates`, {
        params: { ...params },
        responseType: reportTypeDto.reportFormat == "JSON" ? "json" : "blob",
    });

    return res.data;
}
