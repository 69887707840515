import styled from "styled-components";

export const DadosMet = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
`;

export const BoxMet = styled.div`
    display: flex;
    font-size: 15px;
    background-color: #ffffff;
    justify-content: space-around;
    border-radius: 10px;
    padding: 10px;
`;

export const TitleMet = styled.div`
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.02em;
    font-size: 14px;
    line-height: 100%;
    color: #105168;
    grid-row-start: 2;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const TextMet = styled.div`
    font-family: Futura LT;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 100%;
    color: #105168;
    grid-row-start: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.02em;
`;
