import moment from "moment";
import { BACKEND_LOCAL } from "../constants";
import { CHART_LIMIT } from "../constants";

export function getBackendUrl() {
    const urlAtual = window.location.host;

    if (urlAtual.indexOf("localhost") !== -1) {
        return BACKEND_LOCAL;
    }

    return "";
}

export const irradianciasMap = {
        3: "Horizontal",
        2: "Refletida",
        1: "Inclinada",
    }

export const monthMap = {
    Jan: '01', Fev: '02', Mar: '03', Abr: '04', Mai: '05', Jun: '06',
    Jul: '07', Ago: '08', Set: '09', Out: '10', Nov: '11', Dez: '12'
};

function divideArrayEm3(arr) {
    let partes = [],
        i = 0,
        n = arr.length;
    while (i < n) {
        partes.push(arr.slice(i, (i += arr.length / 3)));
    }
    return partes;
}

export function obterDadosDistribuidos(inversorData) {
    if (inversorData.length <= CHART_LIMIT) {
        return inversorData;
    }

    const inversorParts = divideArrayEm3(inversorData);
    let distributedData = [];

    // obtendo os primeiros dados da primeira metade do inversor
    for (let i = 0; i < Math.ceil(CHART_LIMIT / 3); i++) {
        distributedData.push(inversorParts[0][i]);
    }

    // obtendo os primeiros dados da segunda metade do inversor
    for (let i = 0; i < Math.floor(CHART_LIMIT / 3 / 2); i++) {
        distributedData.push(inversorParts[1][i]);
    }

    // obtendo os últimos dados da segunda metade do inversor
    for (
        let i = inversorParts[1].length - Math.ceil(CHART_LIMIT / 3 / 2);
        i < inversorParts[1].length;
        i++
    ) {
        if (distributedData.length < CHART_LIMIT - Math.floor(CHART_LIMIT / 3)) {
            // se o tamanho não excedeu o espaço de 2 terços do CHART_LIMIT
            distributedData.push(inversorParts[1][i]);
        }
    }

    // obtendo os últimos dados da terceira e última metade do inversor
    const tamanho =
        distributedData.length === CHART_LIMIT - Math.floor(CHART_LIMIT / 3)
            ? Math.floor(CHART_LIMIT / 3)
            : Math.floor(CHART_LIMIT / 3) + 1;
    for (let i = inversorParts[2].length - tamanho; i < inversorParts[2].length; i++) {
        distributedData.push(inversorParts[2][i]);
    }

    return distributedData;
}

export function formatarData(datahora) {
    const data = datahora.split(".")[0].split("T")[0].split("-");
    const hora = datahora.split(".")[0].split("T")[1].split(":");
    const dia = data[2];
    const mes = data[1];
    const ano = data[0];
    return `${dia}/${mes}/${ano[2]}${ano[3]} ${hora[0]}:${hora[1]}`;
}

export function converteParaHorarioLocal(data) {
    const date = new Date(data);

    var date_utc = Date.UTC(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds()
    );

    return date_utc;
}

export function formatEstacoesComAlbedo1(estacoes) {
        return estacoes.map((item) => {
            return {
                ...item,
                albedo1: item.estacaoAlbedos.find((albedo) =>  albedo.cod == 1)?.valor,
            };
        });
}

export function formatEstacaoComAlbedo1(estacao) {
    try{
        return {
            ...estacao,
            albedo1: estacao.estacaoAlbedos.find((albedo) => albedo.cod == 1).valor,
        };
    }catch{
        return {...estacao}
    }
}

export function formatEstacaoComDni3(estacao) {
    try{
        return {
            ...estacao,
            dni3: estacao?.estacaoDnis?.find((dni) => dni.cod == 3)?.valor,
        };
    }catch{
        return {...estacao}
    }
}

export function formatEstacoesComDni3(estacoes) {
        return estacoes.map((item) => {
            return {
                ...item,
                dni3: item?.estacaoDnis?.find((dni) => dni.cod == 3)?.valor,
            };
        });
}



export const cpfMask = (value) => {
    return value
        .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
        .replace(/(\d{3})(\d)/, "$1.$2") // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d{1,2})/, "$1-$2")
        .replace(/(-\d{2})\d+?$/, "$1"); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
};

export const formatUserProfile = (profile) => {
    if (profile === "RESPONSAVEL_USINA") return "Responsável da usina";
    if (profile === "PROPRIETARIO_USINA") return "Proprietário da usina";
    if (profile === "ADMIN") return "Admin";
};

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const formatAlarmes = (alarmes) => {
    return alarmes.map((item) => {
        const descricao = item.alarmeTipo?.descricao;
        const usina = item.usina?.nome;
        const cod = item.inversor
            ? item.inversor?.cod
            : item.tracker
            ? item.tracker?.cod
            : item.combiner
            ? item.combiner?.cod
            : null;

        const tipo_equipamento = capitalizeFirstLetter(item.alarmeTipo?.tipo_equipamento);
        const equipamento = `${tipo_equipamento} ${cod ? cod : ""} `;
        const status = item.data_fim ? "Inativo" : "Ativo";
        return {
            id: item.id,
            descricao,
            usina,
            equipamento,
            status,
            data_inicio: moment(item.data_inicio).format("DD/MM/YYYY HH:mm:ss"),
            data_fim: item.data_fim
                ? moment(item.data_fim).format("DD/MM/YYYY HH:mm:ss")
                : "Em andamento",
        };
    });
};

export const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType });

    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
};



export const inversorChartOptions = {
    measurer: {
        label: "Medidor",
        potencia: "Potência Ativa",
        geracao: "Geração",
        medicao_distribuida: "Medição Distribuída"
    },
    inversor: {
        label: "Inversor",
        corrente_a: "Corrente A",
        corrente_b: "Corrente B",
        corrente_c: "Corrente C",
        corrente_max: "Corrente Máxima",
        corrente_media: "Corrente Média",
        corrente_total: "Corrente Total",
        eficiencia: "Eficiência",
        e_diaria: "Energia Diária",
        e_mensal: "Energia Mensal",
        e_total: "Energia Total",
        fp: "Fator de Potência (FP)",
        frequencia: "Frequência",
        pot_ativa_medidor: "Potência Ativa",
        pot_ativa: "Potência Ativa",
        pot_reativa: "Potência Reativa",
        pot_total_dc: "Potência Total DC",
        potencia_total_ac: "Potência Total CA",
        potencia_total_dc: "Potência Total DC",
        temperatura_inv: "Temperatura do Inversor",
        tensao_a: "Tensão A",
        tensao_b: "Tensão B",
        tensao_c: "Tensão C",
        e_anual: "Energia Anual"
    },
    tracker: {
        label: "Tracker",
        angulo_real: "Ângulo Real",
        angulo_ideal: "Ângulo Ideal"
    },
    station: {
        label: "Estação Meteorológica",
        temperatura_ar: "Temperatura do Ar",
        vento: "Velocidade do Vento",
        umidade: "Umidade"
    },
    rele: {
        label: "Rele",
        corrente_a: "Corrente A",
        corrente_b: "Corrente B",
        corrente_c: "Corrente C",
        fator_potencia_a: "Fator de Potência A",
        fator_potencia_b: "Fator de Potência B",
        fator_potencia_c: "Fator de Potência C",
        frequencia_linha: "Frequência da Linha",
        frequencia_barra: "Frequência da Barra",
        temperatura: "Temperatura",
        tensao_a: "Tensão A",
        tensao_b: "Tensão B",
        tensao_c: "Tensão C"
    }
};


export const colors = [
    {
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
    {
        borderColor: "rgb(28, 44, 196)",
        backgroundColor: "rgb(28, 44, 196, 0.5)",
    },
    {
        borderColor: "rgb(0, 0, 250)",
        backgroundColor: "rgb(0, 0, 250, 0.5)",
    },
    {
        borderColor: "rgb(102, 102, 255)",
        backgroundColor: "rgb(102, 102, 255, 0.5)",
    },
    {
        borderColor: "rgb(153, 51, 255)",
        backgroundColor: "rgb(153, 51, 255, 0.5)",
    },
    {
        borderColor: "rgb(153, 0, 204)",
        backgroundColor: "rgb(153, 0, 204, 0.5)",
    },
    {
        borderColor: "rgb(204, 0, 204)",
        backgroundColor: "rgb(204, 0, 204, 0.5)",
    },
    {
        borderColor: "rgb(255, 0, 102)",
        backgroundColor: "rgb(255, 0, 102, 0.5)",
    },
    {
        borderColor: "rgb(255, 0, 0)",
        backgroundColor: "rgb(255, 0, 0, 0.5)",
    },
    {
        borderColor: "rgb(128, 0, 0)",
        backgroundColor: "rgb(128, 0, 0, 0.5)",
    },
    {
        borderColor: "rgb(204, 102, 0)",
        backgroundColor: "rgb(204, 102, 0, 0.5)",
    },
    {
        borderColor: "rgb(255, 153, 0)",
        backgroundColor: "rgb(255, 153, 0, 0.5)",
    },
    {
        borderColor: "rgb(204, 255, 51)",
        backgroundColor: "rgb(204, 255, 51, 0.5)",
    },
    {
        borderColor: "rgb(255, 255, 0)",
        backgroundColor: "rgb(255, 255, 0, 0.5)",
    },
    {
        borderColor: "rgb(255, 255, 153)",
        backgroundColor: "rgb(255, 255, 153, 0.5)",
    },
    {
        borderColor: "rgb(102, 255, 51)",
        backgroundColor: "rgb(102, 255, 51, 0.5)",
    },
    {
        borderColor: "rgb(0, 102, 0)",
        backgroundColor: "rgb(0, 102, 0, 0.5)",
    },
    {
        borderColor: "rgb(153, 255, 204)",
        backgroundColor: "rgb(153, 255, 204, 0.5)",
    },
    {
        borderColor: "rgb(0, 153, 153)",
        backgroundColor: " rgb(0, 153, 153, 0.5)",
    },
    {
        borderColor: "rgb(0, 102, 153)",
        backgroundColor: " rgb(0, 102, 153, 0.5)",
    },
    {
        borderColor: "rgb(102, 102, 153)",
        backgroundColor: "rgb(102, 102, 153, 0.5)",
    },
    {
        borderColor: "rgb(0, 0, 102)",
        backgroundColor: "rgb(0, 0, 102, 0.5)",
    },
    {
        borderColor: "rgb(102, 51, 0)",
        backgroundColor: "rgb(102, 51, 0, 0.5)",
    },
    {
        borderColor: "rgb(153, 153, 102)",
        backgroundColor: "rgb(153, 153, 102, 0.5)",
    },
    {
        borderColor: "rgb(204, 102, 153)",
        backgroundColor: "rgb(204, 102, 153, 0.5)",
    },
];


export function getRandomColors(numColors) {
  const colors = [];

  
  const randomComponent = () => Math.floor(Math.random() * 200);

  
  const generateRandomColor = () => {
    const borderColor = `rgb(${randomComponent()}, ${randomComponent()}, ${randomComponent()})`;
    const backgroundColor = `${borderColor.slice(0, -1)}, 0.5)`;
    return { borderColor, backgroundColor };
  };

  
  for (let i = 0; i < numColors; i++) {
    let color = generateRandomColor();

    
    while (colors.some(c => c.borderColor === color.borderColor && c.backgroundColor === color.backgroundColor)) {
      color = generateRandomColor();
    }

    colors.push(color);
  }

  return colors;
}

export function trackersAlarmsFilter(alarmes) {
        let result = new Map()
        alarmes.forEach((alarme) => {
            result.set(alarme.tracker.cod, true)
        })
        return result
    }