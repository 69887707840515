import api from "./api";

export async function sendRecoveryTokenInEmail(email) {
    const res = await api.post(`auth/recover-password`, { email });
    return res.data;
}

export async function validateRecoveryPasswordToken(token) {
    const res = await api.get("/usuario/validate/token", { params: { token } });
    return res.data;
}

export async function recoveryChangePassword({ senha, senhaConfirmacao, token }) {
    const res = await api.post("auth/change-password-by-token", { senha, senhaConfirmacao, token });
    return res.data;
}

export async function sendRecoveryUserInEmail(email) {
    const res = await api.post(`auth/recover-user`, { email });
    return res.data;
}
