import api from "./api";

export async function buscarUltimosRegistrosTrackers(usinaId) {
    const res = await api.get(`tracker/usina/${usinaId}/getAllLastData`);
    return res.data;
}

export async function exportRelatorioTrackers({ usinaId, intervaloDTO, reportFormat }) {
    const params = {
        startDate: intervaloDTO.startDate,
        endDate: intervaloDTO.endDate,
        reportFormat,
    };

    const res = await api.get(`tracker/usina/${usinaId}/getBetweenDates`, {
        params: { ...params },
        responseType: reportFormat == "JSON" ? "json" : "blob",
    });
    console.log("🚀 ~ file: trackerService.jsx ~ line 20 ~ exportRelatorioTrackers ~ res", res);

    return res.data;
}
