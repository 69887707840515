import styled from "styled-components";

export const Container = styled.div`
    height: 100vh;
    background: linear-gradient(
        180deg,
        rgba(1, 10, 9, 1) 0%,
        rgba(28, 109, 135, 1) 50%,
        rgba(2, 163, 163, 1) 65%,
        rgba(3, 161, 162, 1) 70%,
        rgba(18, 79, 100, 1) 100%
    );
    display: flex;
    justify-content: center;
`;

export const InputText = styled.input`
    width: 100%;
    margin: 15px;
    font-size: 15px;
    color: #a5aab6;
    background: transparent;
    border: none;
    border-bottom: 1px solid #a5aab6;

    :hover {
        border-bottom: 2px solid #a5aab6;
    }

    :focus {
        outline: none;
        box-shadow: none;
        border-bottom: 1px solid #a5aab6;
    }

    ::placeholder {
        color: #a5aab6;
    }
`;
