import React, { useEffect } from "react";
import { SpaceBox, SpaceBox2 } from "../styles/InicioStyles";
import { ProgressBar } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "leaflet/dist/leaflet.css";
import "../App.css";
import { useState } from "react";

export default function BarraSuperior({ totalizadores, isHome, medidor }) {
    const [inversorOrMedidorPotencia, setInversorOrMedidorPotencia] = useState(0)
    const [inversorOrMedidorDailyEnergy, setInversorOrMedidorDailyEnergy] = useState(0)
    const [inversorOrMedidorTotalEnergy, setInversorOrMedidorTotalEnergy] = useState(0)
    useEffect(() => {
        setInversorOrMedidorPotencia(inversorOrMedidorPotenciaCalculator)
        setInversorOrMedidorDailyEnergy(inversorOrMedidorDailyEnergyCalculator)
        setInversorOrMedidorTotalEnergy(inversorOrMedidorTotalEnergyCalculator)

    }, []);
    function inversorOrMedidorPotenciaCalculator() {
        const medidorValue = medidor?.at(-1)?.potencia
        const inversorValue = totalizadores?.potencia_ativa?.total

        if (inversorValue != 0) {
            return isNaN(inversorValue) ? 0 : inversorValue
        }
        return isNaN(medidorValue) ? 0 : medidorValue
    }

    function inversorOrMedidorDailyEnergyCalculator() {
        const medidorValue = (medidor?.at(-1)?.medicao_distribuida - medidor?.at(0)?.medicao_distribuida)
        const inversorValue = totalizadores?.diario?.total


        if (inversorValue != 0) {
            return isNaN(inversorValue) ? 0 : inversorValue
        }
        return isNaN(medidorValue) ? 0 : medidorValue
    }

    function inversorOrMedidorTotalEnergyCalculator() {
        const medidorValue = medidor?.at(-1)?.medicao_distribuida
        const inversorValue = totalizadores?.total

        if (inversorValue != 0) {
            return isNaN(inversorValue) ? 0 : inversorValue
        }
        return isNaN(medidorValue) ? 0 : medidorValue
    }

    function percentage(value, totalValue) {
        return (value / totalValue) * 100
    }
    return (
        <div
            style={{
                backgroundColor: "#122127",
                width: "auto",
                height: "100px",
                margin: "10px 0",
                borderRadius: "10px 10px 10px 10px",
                boxShadow: "4px 6px 6px rgba(0, 0, 0, 0.25)",
            }}
        >
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-around",
                    textAlign: "center",
                }}
            >
                <SpaceBox>
                    <label>Capacidade Total</label>
                    <SpaceBox2>
                        {totalizadores?.capacidade_total?.toLocaleString("pt-BR") || 0} kW
                    </SpaceBox2>
                </SpaceBox>
                {isHome && (
                    <SpaceBox>
                        <label>Usinas</label>
                        <SpaceBox2>Online: {totalizadores?.usinas?.online}</SpaceBox2>
                        <SpaceBox2 style={{ color: "red" }}>
                            Falha: {totalizadores?.usinas?.falha}
                        </SpaceBox2>
                    </SpaceBox>
                )}
                {!isHome && (
                    <SpaceBox
                        style={{
                            marginRight: -15,
                            marginLeft: -30,
                            paddingRight: 0,
                            paddingLeft: 0,
                        }}
                    >
                        <label>Cap. instantânea</label>
                        <SpaceBox2>{totalizadores?.capacidade_instantanea || 0}%</SpaceBox2>
                    </SpaceBox>
                )}
                {!isHome && (
                    <SpaceBox
                        style={{
                            marginRight: -15,
                            marginLeft: -15,
                            paddingRight: 0,
                            paddingLeft: 0,
                        }}
                    >
                        <label>Cap. acumulada</label>
                        <SpaceBox2>{totalizadores?.capacidade_acumulada || 0}%</SpaceBox2>
                    </SpaceBox>
                )}
                <SpaceBox>
                    <label>Potência Ativa</label>
                    <SpaceBox2>
                        {
                            inversorOrMedidorPotencia.toLocaleString("pt-BR", { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 })
                        } kW
                    </SpaceBox2>
                    <ProgressBar
                        style={{ marginTop: "13px" }}
                        animated
                        now={percentage(inversorOrMedidorPotencia, totalizadores?.capacidade_total).toFixed(2) ?? 0}
                        label={`${percentage(inversorOrMedidorPotencia, totalizadores?.capacidade_total).toFixed(2) ?? 0}%`}
                    />
                </SpaceBox>
                <SpaceBox style={{ width: "70px" }}>
                    <label>Diário</label>
                    <SpaceBox2>
                        <nobr>{inversorOrMedidorDailyEnergy.toLocaleString("pt-BR", { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) ?? 0} kWh</nobr>
                    </SpaceBox2>
                    <ProgressBar
                        style={{ marginTop: "13px" }}
                        animated
                        variant="warning"
                        now={totalizadores?.diario?.porcentagem ?? 0}
                        label={`${totalizadores?.diario?.porcentagem ?? 0}%`}
                    />
                </SpaceBox>
                <SpaceBox>
                    <label>Mensal</label>
                    <SpaceBox2>
                        {(totalizadores?.mensal?.total / 1000)?.toLocaleString("pt-BR", { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) ?? 0} MWh
                    </SpaceBox2>
                    <ProgressBar
                        style={{ marginTop: "13px" }}
                        animated
                        variant="danger"
                        now={totalizadores?.mensal?.porcentagem ?? 0}
                        label={`${totalizadores?.mensal?.porcentagem ?? 0}%`}
                    />
                </SpaceBox>
                <SpaceBox>
                    <label>Total</label>
                    <SpaceBox2>{(inversorOrMedidorTotalEnergy / 1000).toLocaleString("pt-BR", { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) ?? 0} MWh</SpaceBox2>
                </SpaceBox>
                <SpaceBox>
                    <label>Alarmes Ativos</label>
                    <SpaceBox2 style={{ color: "red", marginTop: "2px" }}>
                        Grave: {totalizadores?.alarmes?.grave}
                    </SpaceBox2>
                    <SpaceBox2 style={{ color: "orange", marginTop: "2px" }}>
                        Aviso: {totalizadores?.alarmes?.aviso}
                    </SpaceBox2>
                    <SpaceBox2 style={{ marginTop: "2px" }}>
                        Total: {totalizadores?.alarmes?.total}
                    </SpaceBox2>
                </SpaceBox>
            </div>
        </div>
    );
}
