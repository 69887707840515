import api from "./api";

export async function findLastDataFromInversor({ usinaId, inversorId }) {
    const res = await api.get(`/inversor/${inversorId}/usina/${usinaId}/getLastData`);
    return res.data;
}

export async function findBetweenDatesFromInversor({ usinaId, intervaloDTO, inversorId }) {
    const res = await api.get(`/inversor/${inversorId}/usina/${usinaId}/getBetweenDates`, {
        params: {
            ...intervaloDTO,
        },
    });
    return res.data;
}

export async function getSumFromLastAttributeFromUsina({ usinaId, column }) {
    const res = await api.get(`inversor/usina/${usinaId}/getSumFromColumn/${column}`);
    return res.data;
}

export async function findAllLastRegister(usinaId) {
    const res = await api.get(`inversor/usina/${usinaId}/getAllLastData`);
    return res.data;
}

export async function getFromDay({ usinaId, date }) {
    const res = await api.get(`/inversor/usina/${usinaId}/getFromDay?date=${date}`);
    return res.data;
}

export async function exportRelatorioInversor({ usinaId, intervaloDTO, reportTypeDto }) {
    const params = {
        startDate: intervaloDTO.startDate,
        endDate: intervaloDTO.endDate,
        ...reportTypeDto,
    };

    const res = await api.get(`inversor/0/usina/${usinaId}/getBetweenDates`, {
        params: { ...params },
        responseType: reportTypeDto.reportFormat == "JSON" ? "json" : "blob",
    });

    return res.data;
}

export async function getColumn(usinaId, column, startDate, endDate) {
    const res = await api.get(`inversor/usina/${usinaId}/getColumn/${column}`, {
        params: {
            startDate,
            endDate
        }
    });
    return res.data;
}
