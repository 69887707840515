import React from "react";
import { Divider } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import RICIBs from "react-individual-character-input-boxes";
import { useLocation, useNavigate } from "react-router-dom";
import { validateRecoveryPasswordToken } from "../services/recoverPassword";
import { Container } from "../styles/LoginStyle";
import logo_mvp from "../assets/logo_mvp.png";

const CustomButton = styled(Button)({
    color: "white",
    backgroundColor: "#232b2b",
    borderRadius: 28,
    padding: 12,
    paddingLeft: 40,
    paddingRight: 40,
    fontWeight: "bold",
    "&:hover": {
        backgroundColor: "#000",
    },
});

export default function InsertTokenRecoverPassword() {
    const { state } = useLocation();

    const { email } = state;

    useEffect(() => {
        if (!email) navigate("/");
    }, []);

    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const [token, setToken] = useState("");
    const [loading, setLoading] = useState(false);

    const handleRecoverPassword = async (event) => {
        try {
            event.preventDefault();
            setLoading(true);

            const responseValidate = await validateRecoveryPasswordToken(token);
            if (responseValidate.email === email && responseValidate.token === token) {
                enqueueSnackbar("Código validado com sucesso!", { variant: "success" });
                navigate("/change_password", { state: { token } });
            } else {
                enqueueSnackbar("Código incorreto!", { variant: "error" });
            }

            setLoading(false);
        } catch (error) {
            enqueueSnackbar("Token incorreto!", { variant: "error" });

            setLoading(false);
        }
    };

    return (
        <Container
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Box
                width="600px"
                sx={{
                    display: "flex",
                    gridTemplateColumns: "repeat(1, 2fr)",
                    gridTemplateRows: "auto",
                    gridTemplateAreas: `"main"`,
                }}
            >
                <form onSubmit={handleRecoverPassword}>
                    <Box width="100%">
                        <Box
                            sx={{
                                width: "75%",
                                marginTop: "14px",
                                display: "flex",
                                justifyContent: "space-between",
                                flexDirection: "column",
                            }}
                        >
                            <span
                                style={{
                                    marginLeft: "10px",
                                    color: "#d7dae0",
                                    fontSize: 16,
                                    alignContent: "center",
                                    alignSelf: "center",
                                }}
                            >
                                DIGITE O CÓDIGO QUE ENVIAMOS POR EMAIL
                            </span>

                            <Divider
                                variant="middle"
                                sx={{ mt: 3, mb: 3, color: "#d7dae0", padding: "0.5px" }}
                            />
                            <span
                                style={{
                                    marginLeft: "10px",
                                    color: "#d7dae0",
                                    fontSize: 16,
                                }}
                            >
                                Para confirmar de que se trata do seu email, enviamos o token para
                                <b> {email}</b>. Caso não o encontre, confira na sua pasta de spam.
                            </span>
                        </Box>
                        <Box
                            sx={{
                                width: "75%",
                                display: "flex",
                                alignContent: "center",
                                justifyContent: "center",
                                alignSelf: "center",
                                marginTop: "30px",
                            }}
                        >
                            <RICIBs
                                amount={4}
                                autoFocus
                                handleOutputString={(string) => setToken(string)}
                                style={{}}
                                inputRegExp={/^[a-zA-Z0-9_.-]*$/}
                            />
                        </Box>

                        <Box sx={{ width: "74%" }} textAlign="right">
                            <CustomButton
                                style={{
                                    marginTop: "40px",
                                    alignSelf: "flex-end",
                                    justifyContent: "flex-end",
                                }}
                                variant="contained"
                                color="primary"
                                size="large"
                                type="submit"
                                disabled={loading}
                            >
                                Continuar
                            </CustomButton>
                        </Box>
                    </Box>
                </form>
            </Box>
            <Box>
                <img
                    src={logo_mvp}
                    alt="logo"
                    style={{
                        width: "200px",
                        margin: "auto",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                />
                <figcaption
                    style={{
                        color: "white",
                        fontStyle: "italic",
                        padding: "2px",
                        textAlign: "center",
                    }}
                >
                    Soluções de energia fotovoltaica
                </figcaption>
            </Box>
        </Container>
    );
}
