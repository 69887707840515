import React from "react";
import "../styles/inversores.css";

export default function DadoEletricoUsina({ dado = "", unidade = "", valor = "" }) {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "space-between",
            }}
        >
            <div
                style={{
                    flexDirection: "column",
                }}
            >
                <div style={{ display: "flex" }}>
                    <label className="inversor-label">{dado}</label>
                </div>
                <span className="um">{unidade}</span>
            </div>
            {valor.length >= 9 && (
                <span
                    className="valor-inversor"
                    style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                >
                    {valor}
                </span>
            )}
            {valor.length < 9 && <span className="valor-inversor">{valor}</span>}
        </div>
    );
}
