import { useEffect, useState } from "react";
import Select from "react-select";
import GraficoCorrentes from "../components/GraficoCorrentes";
import GraficoTensoes from "../components/GraficoTensoes";
import * as inversoresService from "../services/inversoresService";
// import DateTimeRangeContainer from "react-advanced-datetimerange-picker";
import "bootstrap/dist/css/bootstrap.min.css";
import { Col, Row, Table } from "react-bootstrap";
import "../styles/inversores.css";
import { InversorStatus } from "../styles/InversoresStyle";
// import { obterDadosDistribuidos } from "../utils/utils";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ModelTrainingOutlinedIcon from "@mui/icons-material/ModelTrainingOutlined";
import ModeStandbyOutlinedIcon from "@mui/icons-material/ModeStandbyOutlined";
import CloudOffOutlinedIcon from '@mui/icons-material/CloudOffOutlined';
import moment from "moment";
import DatePicker from "@hassanmojab/react-modern-calendar-datepicker";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { useDispatch } from "react-redux";
import DadoEletricoInversores from "../components/DadoEletricoInversores";
import { CUSTOM_LOCALE } from "../constants";
import { HrDegrade, Painel } from "../styles/GlobalStyles";
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
} from "@mui/material";
import { useParams } from "react-router-dom";
import GraficoInversorCorrentesString from "../components/GraficoInversorCorrentesString";
import GraficoTemperaturaInversorCombiner from "../components/GraficoTemperaturaInversorCombiner";
import { useSnackbar } from "notistack";
import { downloadFile, converteParaHorarioLocal } from "../utils/utils";
import LoadingSpin from "react-loading-spin";
import { SiGooglesheets, SiJson, SiMicrosoftexcel } from "react-icons/si";

// import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

export default function Inversores() {
    const { usinaId } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(true);
    const [inversor, setInversor] = useState([]);
    const [dadosInversorSelecionado, setDadosInversorSelecionado] = useState();
    const [statusInv, setStatusInv] = useState([]);
    const [options, setOptions] = useState([{ value: 1, label: "Inversor 1" }]);
    const [selectedOption, setSelectedOption] = useState(options[0]);
    const dispatch = useDispatch();
    const [date, setDate] = useState({
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        day: new Date().getDate(),
    });
    const [isOpenExportRelatorioTensoesDeFase, setIsOpenExportRelatorioTensoesDeFase] =
        useState(false);
    const [isOpenExportRelatorioCorrentesDeFase, setIsOpenExportRelatorioCorrentesDeFase] =
        useState(false);
    const [isOpenExportRelatorioCorrentesString, setIsOpenExportRelatorioCorrentesString] =
        useState(false);
    const [isOpenExportRelatorioTemperatura, setIsOpenExportRelatorioTemperatura] = useState(false);
    const [isLoadingExportRelatorio, setIsLoadingExportRelatorio] = useState(false);
    const horaAtual = moment(new Date, "YYYY-MM-DDTHH:mm:ss")

    async function getInversorDataBetweenDates() {
        try {
            let intervaloDTO = {
                startDate: "",
                endDate: "",
            };

            intervaloDTO.startDate = moment(
                new Date(date.year, date.month - 1, date.day, 0, 0, 0, 0)
            ).format("YYYY-MM-DDTHH:mm");

            intervaloDTO.endDate = moment(
                new Date(date.year, date.month - 1, date.day, 23, 59, 59)
            ).format("YYYY-MM-DDTHH:mm");

            const [inversorData, statusInversor] = await Promise.all([
                inversoresService.findBetweenDatesFromInversor({
                    usinaId,
                    intervaloDTO,
                    inversorId: selectedOption.value,
                }),
                inversoresService.findAllLastRegister(usinaId),
            ]);

            setOptions(
                statusInversor.map((inversor) => ({
                    value: inversor.cod,
                    label: `Inversor ${inversor.cod}`,
                }))
            );

            setInversor(inversorData);
            setStatusInv(statusInversor);

            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    useEffect(() => {
        // usar setLoading(true) aqui para o usuário não sentir a atualização intervalada
        setLoading(true);
        async function updateInversorData() {
            // usar setLoading(true) aqui para o usuário sentir a atualização intervalada
            // setLoading(true);

            const inversorData = await inversoresService.findLastDataFromInversor({
                usinaId,
                inversorId: selectedOption.value,
            });

            setDadosInversorSelecionado(inversorData);

            await getInversorDataBetweenDates();
        }

        updateInversorData(); // carregamento inicial

        let counter = 0;
        dispatch({ type: "SET_COUNTER", value: counter });

        const intervalId = setInterval(() => {
            counter++;
            dispatch({ type: "SET_COUNTER", value: counter });
            if (counter === 60) {
                // atualização a cada 1 minuto
                updateInversorData();
                counter = 0;
                dispatch({ type: "SET_COUNTER", value: counter });
            }
        }, 1000);

        return () => clearInterval(intervalId);
    }, [selectedOption, date]);

    // function applyCallback(startDate, endDate) {
    //     setDataInicial(startDate);
    //     setDataFinal(endDate);
    // }

    function handleSelectChange(e) {
        setSelectedOption(e);
    }

    function responseTimeHandle(responseData) {
        let responseConverted = moment(new Date(converteParaHorarioLocal(responseData)), "YYYY-MM-DDTHH:mm:ss")

        let diff = horaAtual.diff(responseConverted, 'minutes')

        return diff
    }

    const handleExportRelatorioTensoesDeFase = async ({ reportFormat }) => {
        try {
            setIsLoadingExportRelatorio(true);
            let intervaloDTO = {
                startDate: "",
                endDate: "",
            };
            intervaloDTO.startDate = moment(
                new Date(date.year, date.month - 1, date.day, 0, 0, 0, 0)
            ).format("YYYY-MM-DDTHH:mm");

            intervaloDTO.endDate = moment(
                new Date(date.year, date.month - 1, date.day, 23, 59, 59)
            ).format("YYYY-MM-DDTHH:mm");

            const responseRelatorio = await inversoresService.exportRelatorioInversor({
                usinaId,
                intervaloDTO,
                reportTypeDto: {
                    reportType: "VOLTS",
                    reportFormat,
                },
            });

            const formattedDate = `${date.day}-${date.month}-${date.year}`;
            downloadFile({
                data:
                    reportFormat == "JSON" ? JSON.stringify(responseRelatorio) : responseRelatorio,
                fileName: `relatorio-tensoes-de-fase-${formattedDate}.${reportFormat.toLowerCase()}`,
                fileType: `text/${reportFormat.toLowerCase()}`,
            });
            setIsLoadingExportRelatorio(false);
        } catch (error) {
            setIsLoadingExportRelatorio(false);
            enqueueSnackbar("Ocorreu um ao gerar o relatório!", { variant: "error" });
            console.log(
                "🚀 ~ file: Usinas.jsx ~ line 127 ~ handleExportRelatorioGeracaoDiaria ~ error",
                error
            );
        }
    };

    const handleExportRelatorioCorrentesDeFase = async ({ reportFormat }) => {
        try {
            setIsLoadingExportRelatorio(true);

            let intervaloDTO = {
                startDate: "",
                endDate: "",
            };
            intervaloDTO.startDate = moment(
                new Date(date.year, date.month - 1, date.day, 0, 0, 0, 0)
            ).format("YYYY-MM-DDTHH:mm");

            intervaloDTO.endDate = moment(
                new Date(date.year, date.month - 1, date.day, 23, 59, 59)
            ).format("YYYY-MM-DDTHH:mm");

            const responseRelatorio = await inversoresService.exportRelatorioInversor({
                usinaId,
                intervaloDTO,
                reportTypeDto: {
                    reportType: "AMPERES",
                    reportFormat,
                },
            });

            const formattedDate = `${date.day}-${date.month}-${date.year}`;
            downloadFile({
                data:
                    reportFormat == "JSON" ? JSON.stringify(responseRelatorio) : responseRelatorio,
                fileName: `relatorio-correntes-de-fase-${formattedDate}.${reportFormat.toLowerCase()}`,
                fileType: `text/${reportFormat.toLowerCase()}`,
            });
            setIsLoadingExportRelatorio(false);
        } catch (error) {
            setIsLoadingExportRelatorio(false);
            enqueueSnackbar("Ocorreu um ao gerar o relatório!", { variant: "error" });
            console.log(
                "🚀 ~ file: Usinas.jsx ~ line 127 ~ handleExportRelatorioGeracaoDiaria ~ error",
                error
            );
        }
    };

    const handleExportRelatorioCorrentesString = async ({ reportFormat }) => {
        try {
            setIsLoadingExportRelatorio(true);
            let intervaloDTO = {
                startDate: "",
                endDate: "",
            };
            intervaloDTO.startDate = moment(
                new Date(date.year, date.month - 1, date.day, 0, 0, 0, 0)
            ).format("YYYY-MM-DDTHH:mm");

            intervaloDTO.endDate = moment(
                new Date(date.year, date.month - 1, date.day, 23, 59, 59)
            ).format("YYYY-MM-DDTHH:mm");

            const responseRelatorio = await inversoresService.exportRelatorioInversor({
                usinaId,
                intervaloDTO,
                reportTypeDto: {
                    reportType: "CORRENTES_STRING",
                    reportFormat,
                },
            });

            const formattedDate = `${date.day}-${date.month}-${date.year}`;
            downloadFile({
                data:
                    reportFormat == "JSON" ? JSON.stringify(responseRelatorio) : responseRelatorio,
                fileName: `relatorio-correntes-string-${formattedDate}.${reportFormat.toLowerCase()}`,
                fileType: `text/${reportFormat.toLowerCase()}`,
            });
            setIsLoadingExportRelatorio(false);
        } catch (error) {
            setIsLoadingExportRelatorio(false);
            enqueueSnackbar("Ocorreu um ao gerar o relatório!", { variant: "error" });
            console.log(
                "🚀 ~ file: Usinas.jsx ~ line 127 ~ handleExportRelatorioGeracaoDiaria ~ error",
                error
            );
        }
    };

    const handleExportRelatorioTemperatura = async ({ reportFormat }) => {
        try {
            setIsLoadingExportRelatorio(true);

            let intervaloDTO = {
                startDate: "",
                endDate: "",
            };
            intervaloDTO.startDate = moment(
                new Date(date.year, date.month - 1, date.day, 0, 0, 0, 0)
            ).format("YYYY-MM-DDTHH:mm");

            intervaloDTO.endDate = moment(
                new Date(date.year, date.month - 1, date.day, 23, 59, 59)
            ).format("YYYY-MM-DDTHH:mm");

            const responseRelatorio = await inversoresService.exportRelatorioInversor({
                usinaId,
                intervaloDTO,
                reportTypeDto: {
                    reportType: "TEMPERATURA",
                    reportFormat,
                },
            });

            const formattedDate = `${date.day}-${date.month}-${date.year}`;
            downloadFile({
                data:
                    reportFormat == "JSON" ? JSON.stringify(responseRelatorio) : responseRelatorio,
                fileName: `relatorio-temperatura-${formattedDate}.${reportFormat.toLowerCase()}`,
                fileType: `text/${reportFormat.toLowerCase()}`,
            });
            setIsLoadingExportRelatorio(false);
        } catch (error) {
            setIsLoadingExportRelatorio(false);
            enqueueSnackbar("Ocorreu um ao gerar o relatório!", { variant: "error" });
            console.log(
                "🚀 ~ file: Usinas.jsx ~ line 127 ~ handleExportRelatorioGeracaoDiaria ~ error",
                error
            );
        }
    };

    let InversoresTsx = (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                height: "75vh",
            }}
        >
            <CircularProgress />
        </Box>
    );

    function formatInputValue() {
        if (!date) return "";

        let format = date.day > 9 ? `${date.day}/` : `0${date.day}/`;
        format += date.month > 9 ? `${date.month}/` : `0${date.month}/`;
        format += date.year;

        return format;
    }

    if (!loading) {
        const renderCustomInput = ({ ref }) => (
            <input
                readOnly
                ref={ref} // necessary
                placeholder="Filtre por data"
                value={date ? formatInputValue() : ""}
                style={{
                    width: "96px",
                    height: "34px",
                    padding: "6px 12px",
                    fontSize: "14px",
                    lineHeight: "1.42857143",
                    color: "#555",
                    backgroundColor: "#fff",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                }}
                className="custom-input-class" // a styling class
            />
        );
        // let now = new Date();
        // let start = dataInicial
        //     ? dataInicial
        //     : moment(new Date(now.getFullYear(), now.getMonth() - 1, now.getDate(), 0, 0, 0, 0));
        // let today = moment(
        //     new Date(
        //         now.getFullYear(),
        //         now.getMonth(),
        //         now.getDate(),
        //         now.getHours(),
        //         now.getMinutes()
        //     )
        // );
        // let end = dataFinal ? dataFinal : today;

        // let maxDate = moment();
        // let ranges = {
        //     // "Today Only": [moment(start), moment(end)],
        //     Hoje: [
        //         moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)),
        //         moment(),
        //     ],
        //     Ontem: [
        //         moment(new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1, 0, 0, 0, 0)),
        //         moment(new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1, 23, 59, 59)),
        //     ], // ajustar hora
        //     "Última semana": [
        //         moment(new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7, 0, 0, 0, 0)),
        //         moment(),
        //     ], // ajustar hora
        //     // 'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        //     // 'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        //     // 'This Month': [moment().startOf('month'), moment().endOf('month')],
        //     // 'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
        // };

        // let local = {
        //     format: "DD-MM-YYYY HH:mm",
        //     sundayFirst: false,
        //     days: ["Seg", "Ter", "Qua", "Qui", "Sex", "Sab", "Dom"],
        //     months: [
        //         "Janeiro",
        //         "Fevereiro",
        //         "Março",
        //         "Abril",
        //         "Maio",
        //         "Junho",
        //         "Julho",
        //         "Agosto",
        //         "Setembro",
        //         "Outubro",
        //         "Novembro",
        //         "Dezembro",
        //     ],
        //     fromDate: "De",
        //     toDate: "Até",
        //     selectingFrom: "Selecionando de",
        //     selectingTo: "Selecionando até",
        //     maxDate: "Data limite",
        //     close: "Fechar",
        //     apply: "Aplicar",
        //     cancel: "Cancelar",
        //     customRangeButtonText: "Teste",
        // };
        InversoresTsx = (
            <Painel>
                <Row>
                    <Col
                        sm={4}
                        style={{
                            borderRight: "0.6px solid #C4C4C4",
                        }}
                    >
                        <Row>
                            <Col sm={6}>
                                <Select
                                    defaultValue={selectedOption}
                                    onChange={handleSelectChange}
                                    options={options}
                                />
                                {/* <DateTimeRangeContainer
                                    ranges={ranges}
                                    start={start}
                                    end={end}
                                    local={local}
                                    maxDate={maxDate}
                                    applyCallback={applyCallback}
                                    smartMode={false}
                                >
                                    <Row>
                                        <Col sm={12}>
                                            <InputText
                                                id="formControlsTextB"
                                                type="text"
                                                autoComplete="off"
                                                placeholder={"Filtre por período"}
                                                defaultValue={`${
                                                    dataFinal
                                                        ? dataInicial.format("DD/MM/YYYY HH:mm") +
                                                          " ~ " +
                                                          dataFinal.format("DD/MM/YYYY HH:mm")
                                                        : ""
                                                }`}
                                            />
                                        </Col>
                                        {/* <Col  // ICONE DE CALENDARIO
                                            sm={1}
                                            style={{
                                                alignSelf: "center",
                                                padding: 0,
                                            }}
                                        >
                                            <button
                                                style={{
                                                    border: "none",
                                                    background: "transparent",
                                                    padding: 0,
                                                }}
                                            >
                                                <CalendarMonthIcon
                                                    fontSize={"medium"}
                                                />
                                            </button>
                                        </Col>
                                    </Row>
                                </DateTimeRangeContainer> */}
                            </Col>

                            <Col sm={6} style={{ textAlign: "right" }}>
                                {dadosInversorSelecionado?.status === 0 && responseTimeHandle(dadosInversorSelecionado.data) <= 20 ? (
                                    <span
                                        style={{
                                            fontWeight: 700,
                                            fontSize: "18px",
                                            letterSpacing: "0.02rem",
                                            color: "#049B5F",
                                        }}
                                    >
                                        On-grid
                                        <CheckCircleOutlineIcon style={{ color: "#049B5F" }} />
                                    </span>
                                ) : dadosInversorSelecionado?.status === 5120 && responseTimeHandle(dadosInversorSelecionado.data) <= 20 ? (
                                    <span
                                        style={{
                                            fontWeight: 700,
                                            fontSize: "18px",
                                            letterSpacing: "0.02rem",
                                            color: "#FBB041",
                                        }}
                                    >
                                        Stand By
                                        <ModeStandbyOutlinedIcon style={{ color: "#FBB041" }} />
                                    </span>
                                ) : dadosInversorSelecionado?.status === 5632 && responseTimeHandle(dadosInversorSelecionado.data) <= 20 ? (
                                    <span
                                        style={{
                                            fontWeight: 700,
                                            fontSize: "18px",
                                            letterSpacing: "0.02rem",
                                            color: "#3C96C6",
                                        }}
                                    >
                                        Iniciando
                                        <ModelTrainingOutlinedIcon style={{ color: "#3C96C6" }} />
                                    </span>
                                ) : dadosInversorSelecionado?.status === 21760 && responseTimeHandle(dadosInversorSelecionado.data) <= 20 ? (
                                    <span
                                        style={{
                                            fontWeight: 700,
                                            fontSize: "18px",
                                            letterSpacing: "0.02rem",
                                            color: "#D40707",
                                        }}
                                    >
                                        Falha
                                        <CancelOutlinedIcon style={{ color: "#D40707" }} />
                                    </span>
                                ) : (
                                    <span
                                        style={{
                                            fontWeight: 700,
                                            fontSize: "18px",
                                            letterSpacing: "0.02rem",
                                            color: "#ff9232",
                                            paddingLeft: "1%",
                                        }}
                                    >
                                        Offline
                                        <CloudOffOutlinedIcon style={{ paddingLeft: "1%", color: "#ff9233" }} />
                                    </span>
                                )}
                            </Col>
                        </Row>

                        <hr />

                        <Row style={{ marginBottom: "1rem" }}>
                            <Col>
                                <label className="device-label">Modelo</label>
                            </Col>
                            <Col>
                                <nobr>
                                    <span className="device-value">
                                        {dadosInversorSelecionado?.modelo?.modelo}
                                    </span>
                                </nobr>
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: "1rem" }}>
                            <Col>
                                <label className="device-label">SN</label>
                            </Col>
                            <Col>
                                <span className="device-value">
                                    {dadosInversorSelecionado?.modelo?.sn}
                                </span>
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: "1rem" }}>
                            <Col>
                                <label className="device-label">PN</label>
                            </Col>
                            <Col>
                                <span className="device-value">
                                    {" "}
                                    {dadosInversorSelecionado?.modelo?.pn}
                                </span>
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: "1rem" }}>
                            <Col>
                                <label className="device-label">ID</label>
                            </Col>
                            <Col>
                                <span className="device-value">{selectedOption.value}</span>
                            </Col>
                        </Row>
                    </Col>

                    <Col
                        style={{
                            textAlign: "left",
                            borderRight: "0.6px solid #C4C4C4",
                        }}
                        sm={4}
                    >
                        <br />
                        <Row>
                            <Col
                                sm={6}
                                style={{
                                    borderRight: "0.6px dashed #C4C4C4",
                                }}
                            >
                                <Row style={{ marginBottom: "3px" }}>
                                    <DadoEletricoInversores
                                        dado="Tensão A:"
                                        unidade="(Volts)"
                                        valor={dadosInversorSelecionado?.tensao_a?.toLocaleString(
                                            "pt-BR"
                                        )}
                                    />
                                </Row>
                                <Row style={{ marginBottom: "3px" }}>
                                    <DadoEletricoInversores
                                        dado="Tensão B:"
                                        unidade="(Volts)"
                                        valor={dadosInversorSelecionado?.tensao_b?.toLocaleString(
                                            "pt-BR"
                                        )}
                                    />
                                </Row>
                                <Row style={{ marginBottom: "3px" }}>
                                    <DadoEletricoInversores
                                        dado="Tensão C:"
                                        unidade="(Volts)"
                                        valor={dadosInversorSelecionado?.tensao_c?.toLocaleString(
                                            "pt-BR"
                                        )}
                                    />
                                </Row>
                                <Row style={{ marginBottom: "3px" }}>
                                    <DadoEletricoInversores
                                        dado="Corrente A:"
                                        unidade="(Amperes)"
                                        valor={dadosInversorSelecionado?.corrente_a?.toLocaleString(
                                            "pt-BR"
                                        )}
                                    />
                                </Row>
                                <Row>
                                    <DadoEletricoInversores
                                        dado="Corrente B:"
                                        unidade="(Amperes)"
                                        valor={dadosInversorSelecionado?.corrente_b?.toLocaleString(
                                            "pt-BR"
                                        )}
                                    />
                                </Row>
                            </Col>

                            <Col sm={6}>
                                <Row style={{ marginBottom: "3px" }}>
                                    <DadoEletricoInversores
                                        dado="Corrente C:"
                                        unidade="(Amperes)"
                                        valor={dadosInversorSelecionado?.corrente_c?.toLocaleString(
                                            "pt-BR"
                                        )}
                                    />
                                </Row>
                                <Row style={{ marginBottom: "3px" }}>
                                    <DadoEletricoInversores
                                        dado="Potência total AC:"
                                        unidade="(Watts)"
                                        valor={dadosInversorSelecionado?.potencia_total_ac?.toLocaleString(
                                            "pt-BR"
                                        )}
                                    />
                                </Row>
                                <Row style={{ marginBottom: "3px" }}>
                                    <DadoEletricoInversores
                                        dado="Potência total DC:"
                                        unidade="(Watts)"
                                        valor={dadosInversorSelecionado?.potencia_total_dc?.toLocaleString(
                                            "pt-BR"
                                        )}
                                    />
                                </Row>
                                <Row style={{ marginBottom: "3px" }}>
                                    <DadoEletricoInversores
                                        dado="Eficiência"
                                        unidade="(%)"
                                        valor={dadosInversorSelecionado?.eficiencia?.toLocaleString(
                                            "pt-BR"
                                        )}
                                    />
                                </Row>
                            </Col>
                        </Row>
                    </Col>

                    <Col style={{ textAlign: "left" }} sm={4}>
                        <br />
                        <Row>
                            <Col
                                sm={6}
                                style={{
                                    borderRight: "0.6px dashed #C4C4C4",
                                }}
                            >
                                <Row style={{ marginBottom: "3px" }}>
                                    <DadoEletricoInversores
                                        dado="Energia Diária:"
                                        unidade="(Kwh)"
                                        valor={dadosInversorSelecionado?.e_diaria?.toLocaleString(
                                            "pt-BR"
                                        )}
                                    />
                                </Row>
                                <Row style={{ marginBottom: "3px" }}>
                                    <DadoEletricoInversores
                                        dado="Energia Mensal:"
                                        unidade="(Kwh)"
                                        valor={dadosInversorSelecionado?.e_mensal?.toLocaleString(
                                            "pt-BR"
                                        )}
                                    />
                                </Row>
                                <Row style={{ marginBottom: "3px" }}>
                                    <DadoEletricoInversores
                                        dado="Energia Anual:"
                                        unidade="(Kwh)"
                                        valor={dadosInversorSelecionado?.e_anual?.toLocaleString(
                                            "pt-BR"
                                        )}
                                    />
                                </Row>
                                <Row style={{ marginBottom: "3px" }}>
                                    <DadoEletricoInversores
                                        dado="Energia Total:"
                                        unidade="(Kwh)"
                                        valor={dadosInversorSelecionado?.e_total?.toLocaleString(
                                            "pt-BR"
                                        )}
                                    />
                                </Row>
                                <Row>
                                    <DadoEletricoInversores
                                        dado="Frequência:"
                                        unidade="(Hertz)"
                                        valor={dadosInversorSelecionado?.frequencia?.toLocaleString(
                                            "pt-BR"
                                        )}
                                    />
                                </Row>
                            </Col>

                            <Col sm={6}>
                                <Row style={{ marginBottom: "3px" }}>
                                    <DadoEletricoInversores
                                        dado="Temperatura:"
                                        unidade="(ºC)"
                                        valor={dadosInversorSelecionado?.temperatura_inv?.toLocaleString(
                                            "pt-BR"
                                        )}
                                    />
                                </Row>
                                <Row style={{ marginBottom: "3px" }}>
                                    <DadoEletricoInversores dado="DSP Colection:" valor={"YES"} />
                                </Row>
                                <Row>
                                    <DadoEletricoInversores
                                        dado="PV Connection:"
                                        valor={"CONNECTED"}
                                    />
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <HrDegrade />

                <Row style={{ textAlign: "center", marginBottom: "1rem" }}>
                    <DatePicker
                        value={date} // ? `${date?.day}/${date?.month}/${date?.year}` : null
                        // formatInputText={formatInputValue}
                        onChange={setDate}
                        renderInput={renderCustomInput} // render a custom input
                        // inputPlaceholder="Selecione um dia"
                        locale={CUSTOM_LOCALE}
                    // maximumDate={maximumDate}
                    // shouldHighlightWeekends
                    ></DatePicker>
                </Row>
                <Row>
                    <Col md={12} lg={6} style={{ textAlign: "center" }}>
                        <b style={{ fontSize: "20px" }}>Tensões de fase (Volts) x Tempo </b>
                        <Button
                            variant="contained"
                            style={{
                                padding: "0 14px",
                                color: "white",
                                backgroundColor: "#0a5068",
                                textTransform: "none",
                                marginLeft: "20px",
                            }}
                            onClick={() => setIsOpenExportRelatorioTensoesDeFase(true)}
                        >
                            Exportar relatório
                        </Button>
                        <div style={{ width: "100%" }}>
                            <GraficoTensoes inversor={inversor} date={date} />
                        </div>
                    </Col>
                    <Col md={12} lg={6} style={{ textAlign: "center" }}>
                        <b style={{ fontSize: "20px" }}>Correntes de fase (Amperes) x Tempo</b>
                        <Button
                            variant="contained"
                            style={{
                                padding: "0 14px",
                                color: "white",
                                backgroundColor: "#0a5068",
                                textTransform: "none",
                                marginLeft: "20px",
                            }}
                            onClick={() => setIsOpenExportRelatorioCorrentesDeFase(true)}
                        >
                            Exportar relatório
                        </Button>
                        <div style={{ width: "100%" }}>
                            <GraficoCorrentes inversor={inversor} date={date} />
                        </div>
                    </Col>
                </Row>
                <Row style={{ marginTop: 32 }}>
                    <Col md={12} lg={6} style={{ textAlign: "center" }}>
                        <b style={{ fontSize: "20px" }}>Correntes string x Tempo </b>
                        <Button
                            variant="contained"
                            style={{
                                padding: "0 14px",
                                color: "white",
                                backgroundColor: "#0a5068",
                                textTransform: "none",
                                marginLeft: "20px",
                            }}
                            onClick={() => setIsOpenExportRelatorioCorrentesString(true)}
                        >
                            Exportar relatório
                        </Button>
                        <div style={{ width: "100%" }}>
                            <GraficoInversorCorrentesString inversor={inversor} date={date} />
                        </div>
                    </Col>
                    <Col md={12} lg={6} style={{ textAlign: "center" }}>
                        <b style={{ fontSize: "20px" }}>Temperatura (Cº) x Tempo</b>
                        <Button
                            variant="contained"
                            style={{
                                padding: "0 14px",
                                color: "white",
                                backgroundColor: "#0a5068",
                                textTransform: "none",
                                marginLeft: "20px",
                            }}
                            onClick={() => setIsOpenExportRelatorioTemperatura(true)}
                        >
                            Exportar relatório
                        </Button>
                        <div style={{ width: "100%" }}>
                            <GraficoTemperaturaInversorCombiner inversor={inversor} date={date} />
                        </div>
                    </Col>
                </Row>

                <HrDegrade />

                <Table hover className="table table-striped">
                    <thead className="text-light" style={{ backgroundColor: "#0a5068" }}>
                        <tr>
                            <th>Inversor</th>
                            <th>Modelo</th>
                            <th className="text-end">Energia Diária(kWh)</th>
                            <th className="text-end">Potência (kW)</th>
                            <th className="text-end">Status</th>
                        </tr>
                    </thead>
                    <tbody className="thead-light" style={{ color: "#4E596B", fontWeight: 700 }}>
                        {statusInv.map((inv, index) => (
                            <tr key={index}>
                                <td>Inversor {inv?.cod}</td>
                                <td>{inv?.modelo?.modelo}</td>
                                <td className="text-end">{inv?.e_diaria.toLocaleString(
                                    'pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                                )}</td>
                                <td className="text-end">{inv?.pot_ativa.toLocaleString(
                                    'pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                                )}</td>
                                <td className="text-end">
                                    {
                                        inv?.status === 0 && responseTimeHandle(inv?.data) <= 20 ? (
                                            <span
                                                style={{
                                                    fontWeight: 700,
                                                    fontSize: "18px",
                                                    letterSpacing: "0.02rem",
                                                    color: "#049B5F",
                                                }}
                                            >
                                                On-grid
                                                <CheckCircleOutlineIcon style={{ color: "#049B5F" }} />
                                            </span>
                                        ) : inv?.status === 5120 && responseTimeHandle(inv?.data) <= 20 ? (
                                            <span
                                                style={{
                                                    fontWeight: 700,
                                                    fontSize: "18px",
                                                    letterSpacing: "0.02rem",
                                                    color: "#FBB041",
                                                }}
                                            >
                                                Stand By
                                                <ModeStandbyOutlinedIcon style={{ color: "#FBB041" }} />
                                            </span>
                                        ) : inv?.status === 5632 && responseTimeHandle(inv?.data) <= 20 ? (
                                            <span
                                                style={{
                                                    fontWeight: 700,
                                                    fontSize: "18px",
                                                    letterSpacing: "0.02rem",
                                                    color: "#3C96C6",
                                                }}
                                            >
                                                Iniciando
                                                <ModelTrainingOutlinedIcon style={{ color: "#3C96C6" }} />
                                            </span>
                                        ) : dadosInversorSelecionado?.status === 21760 && responseTimeHandle(inv?.data) <= 20 ? (
                                            <span
                                                style={{
                                                    fontWeight: 700,
                                                    fontSize: "18px",
                                                    letterSpacing: "0.02rem",
                                                    color: "#D40707",
                                                }}
                                            >
                                                Falha
                                                <CancelOutlinedIcon style={{ color: "#D40707" }} />
                                            </span>
                                        ) : (
                                            <span
                                                style={{
                                                    fontWeight: 700,
                                                    fontSize: "18px",
                                                    letterSpacing: "0.02rem",
                                                    color: "#ff9233",
                                                    paddingLeft: "1%",
                                                }}
                                            >
                                                Offline
                                                <CloudOffOutlinedIcon style={{ paddingLeft: "1%", color: "#ff9233" }} />
                                            </span>
                                        )
                                    }
                                </td>

                            </tr>
                        ))}
                    </tbody>
                </Table>

                {/* <Row style={{ textAlign: "center" }}>
                    {statusInv?.map((inv, index) => (

                        <Col
                            key={index}
                            sm={1}
                            style={{
                                background: "#FFFFFF",
                                border: "1px solid #123C4B",
                                boxSizing: "border-box",
                                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                                borderRadius: "5px",
                                margin: "5px",
                                paddingBottom: "3px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                            }}
                        >

                            <InversorStatus>
                                Inversor
                                <br />
                                {inv?.cod.toString().padStart(2, "0")}{" "}
                            </InversorStatus>
                            <br />
                            {inv.status === 0 && responseTimeHandle(inv.data) <= 20 ? (
                                <span
                                    style={{
                                        fontWeight: 700,
                                        fontSize: "18px",
                                        letterSpacing: "0.02rem",
                                        color: "#049B5F",
                                    }}
                                >
                                    Ativo
                                    <CheckCircleOutlineIcon style={{ color: "#049B5F" }} />
                                </span>
                            ) : inv.status === 5120 && responseTimeHandle(inv.data) <= 20 ? (
                                <span
                                    style={{
                                        fontWeight: 700,
                                        fontSize: "18px",
                                        letterSpacing: "0.02rem",
                                        color: "#FBB041",
                                    }}
                                >
                                    Stand By
                                    <ModeStandbyOutlinedIcon style={{ color: "#FBB041" }} />
                                </span>
                            ) : inv.status === 5632 && responseTimeHandle(inv.data) <= 20 ? (
                                <span
                                    style={{
                                        fontWeight: 700,
                                        fontSize: "18px",
                                        letterSpacing: "0.02rem",
                                        color: "#3C96C6",
                                    }}
                                >
                                    Iniciando
                                    <ModelTrainingOutlinedIcon style={{ color: "#3C96C6" }} />
                                </span>
                            ) : dadosInversorSelecionado?.status === 21760 && responseTimeHandle(inv.data) <= 20 ? (
                                <span
                                    style={{
                                        fontWeight: 700,
                                        fontSize: "18px",
                                        letterSpacing: "0.02rem",
                                        color: "#D40707",
                                    }}
                                >
                                    Falha
                                    <CancelOutlinedIcon style={{ color: "#D40707" }} />
                                </span>
                            ) : (
                                <span
                                    style={{
                                        fontWeight: 700,
                                        fontSize: "18px",
                                        letterSpacing: "0.02rem",
                                        color: "#ff9233",
                                        paddingLeft: "1%",
                                    }}
                                >
                                    Offline
                                    <CloudOffOutlinedIcon style={{ paddingLeft: "1%", color: "#ff9233" }} />
                                </span>
                            )}
                        </Col>
                    ))}
                </Row> */}
                <Dialog
                    maxWidth="sm"
                    fullWidth
                    disableScrollLock={true}
                    open={isOpenExportRelatorioTensoesDeFase}
                    onClose={() => setIsOpenExportRelatorioTensoesDeFase(false)}
                >
                    <DialogTitle style={{ color: "#0a5068" }}>
                        Exportar relatório de <b>Tensões de fase (Volts) x Tempo</b>
                    </DialogTitle>
                    <DialogContent>
                        {isLoadingExportRelatorio ? (
                            <>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignContent: "center",
                                    }}
                                >
                                    <LoadingSpin size="16px" width="2px" primaryColor={"#2e64a8"} />
                                </Box>
                            </>
                        ) : (
                            <>
                                <Divider
                                    variant="middle"
                                    sx={{ mb: 4, color: "#0a5068", padding: "0.5px" }}
                                />
                                <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
                                    <Button
                                        variant="contained"
                                        style={{
                                            color: "white",
                                            backgroundColor: "#0a5068",
                                            textTransform: "none",
                                        }}
                                        onClick={() =>
                                            handleExportRelatorioTensoesDeFase({
                                                reportFormat: "CSV",
                                            })
                                        }
                                    >
                                        <b>CSV</b>{" "}
                                        <SiGooglesheets style={{ color: "white", marginLeft: 6 }} />
                                    </Button>
                                    <Button
                                        variant="contained"
                                        style={{
                                            color: "white",
                                            backgroundColor: "#0a5068",
                                            textTransform: "none",
                                        }}
                                        onClick={() =>
                                            handleExportRelatorioTensoesDeFase({
                                                reportFormat: "XLSX",
                                            })
                                        }
                                    >
                                        <b>XLSX</b>{" "}
                                        <SiMicrosoftexcel
                                            style={{ color: "white", marginLeft: 6 }}
                                        />
                                    </Button>

                                    <Button
                                        variant="contained"
                                        style={{
                                            color: "white",
                                            backgroundColor: "#0a5068",
                                            textTransform: "none",
                                        }}
                                        onClick={() =>
                                            handleExportRelatorioTensoesDeFase({
                                                reportFormat: "JSON",
                                            })
                                        }
                                    >
                                        <b>JSON</b>{" "}
                                        <SiJson style={{ color: "white", marginLeft: 6 }} />
                                    </Button>
                                </Box>
                            </>
                        )}
                    </DialogContent>
                    <DialogActions style={{ color: "#0a5068" }}>
                        <Button
                            style={{ color: "#0a5068" }}
                            onClick={() => setIsOpenExportRelatorioTensoesDeFase(false)}
                        >
                            Fechar
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    maxWidth="sm"
                    fullWidth
                    disableScrollLock={true}
                    open={isOpenExportRelatorioCorrentesDeFase}
                    onClose={() => setIsOpenExportRelatorioCorrentesDeFase(false)}
                >
                    <DialogTitle style={{ color: "#0a5068" }}>
                        Exportar relatório de <b>Correntes de fase (Amperes) x Tempo</b>
                    </DialogTitle>
                    <DialogContent>
                        {isLoadingExportRelatorio ? (
                            <>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignContent: "center",
                                    }}
                                >
                                    <LoadingSpin size="16px" width="2px" primaryColor={"#2e64a8"} />
                                </Box>
                            </>
                        ) : (
                            <>
                                <Divider
                                    variant="middle"
                                    sx={{ mb: 4, color: "#0a5068", padding: "0.5px" }}
                                />
                                <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
                                    <Button
                                        variant="contained"
                                        style={{
                                            color: "white",
                                            backgroundColor: "#0a5068",
                                            textTransform: "none",
                                        }}
                                        onClick={() =>
                                            handleExportRelatorioCorrentesDeFase({
                                                reportFormat: "CSV",
                                            })
                                        }
                                    >
                                        <b>CSV</b>{" "}
                                        <SiGooglesheets style={{ color: "white", marginLeft: 6 }} />
                                    </Button>
                                    <Button
                                        variant="contained"
                                        style={{
                                            color: "white",
                                            backgroundColor: "#0a5068",
                                            textTransform: "none",
                                        }}
                                        onClick={() =>
                                            handleExportRelatorioCorrentesDeFase({
                                                reportFormat: "XLSX",
                                            })
                                        }
                                    >
                                        <b>XLSX</b>{" "}
                                        <SiMicrosoftexcel
                                            style={{ color: "white", marginLeft: 6 }}
                                        />
                                    </Button>

                                    <Button
                                        variant="contained"
                                        style={{
                                            color: "white",
                                            backgroundColor: "#0a5068",
                                            textTransform: "none",
                                        }}
                                        onClick={() =>
                                            handleExportRelatorioCorrentesDeFase({
                                                reportFormat: "JSON",
                                            })
                                        }
                                    >
                                        <b>JSON</b>{" "}
                                        <SiJson style={{ color: "white", marginLeft: 6 }} />
                                    </Button>
                                </Box>
                            </>
                        )}
                    </DialogContent>
                    <DialogActions style={{ color: "#0a5068" }}>
                        <Button
                            style={{ color: "#0a5068" }}
                            onClick={() => setIsOpenExportRelatorioCorrentesDeFase(false)}
                        >
                            Fechar
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    maxWidth="sm"
                    fullWidth
                    disableScrollLock={true}
                    open={isOpenExportRelatorioCorrentesString}
                    onClose={() => setIsOpenExportRelatorioCorrentesString(false)}
                >
                    <DialogTitle style={{ color: "#0a5068" }}>
                        Exportar relatório de <b>Correntes string</b>
                    </DialogTitle>
                    <DialogContent>
                        {isLoadingExportRelatorio ? (
                            <>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignContent: "center",
                                    }}
                                >
                                    <LoadingSpin size="16px" width="2px" primaryColor={"#2e64a8"} />
                                </Box>
                            </>
                        ) : (
                            <>
                                <Divider
                                    variant="middle"
                                    sx={{ mb: 4, color: "#0a5068", padding: "0.5px" }}
                                />
                                <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
                                    <Button
                                        variant="contained"
                                        style={{
                                            color: "white",
                                            backgroundColor: "#0a5068",
                                            textTransform: "none",
                                        }}
                                        onClick={() =>
                                            handleExportRelatorioCorrentesString({
                                                reportFormat: "CSV",
                                            })
                                        }
                                    >
                                        <b>CSV</b>{" "}
                                        <SiGooglesheets style={{ color: "white", marginLeft: 6 }} />
                                    </Button>
                                    <Button
                                        variant="contained"
                                        style={{
                                            color: "white",
                                            backgroundColor: "#0a5068",
                                            textTransform: "none",
                                        }}
                                        onClick={() =>
                                            handleExportRelatorioCorrentesString({
                                                reportFormat: "XLSX",
                                            })
                                        }
                                    >
                                        <b>XLSX</b>{" "}
                                        <SiMicrosoftexcel
                                            style={{ color: "white", marginLeft: 6 }}
                                        />
                                    </Button>

                                    <Button
                                        variant="contained"
                                        style={{
                                            color: "white",
                                            backgroundColor: "#0a5068",
                                            textTransform: "none",
                                        }}
                                        onClick={() =>
                                            handleExportRelatorioCorrentesString({
                                                reportFormat: "JSON",
                                            })
                                        }
                                    >
                                        <b>JSON</b>{" "}
                                        <SiJson style={{ color: "white", marginLeft: 6 }} />
                                    </Button>
                                </Box>
                            </>
                        )}
                    </DialogContent>
                    <DialogActions style={{ color: "#0a5068" }}>
                        <Button
                            style={{ color: "#0a5068" }}
                            onClick={() => setIsOpenExportRelatorioCorrentesString(false)}
                        >
                            Fechar
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    maxWidth="sm"
                    fullWidth
                    disableScrollLock={true}
                    open={isOpenExportRelatorioTemperatura}
                    onClose={() => setIsOpenExportRelatorioTemperatura(false)}
                >
                    <DialogTitle style={{ color: "#0a5068" }}>
                        Exportar relatório de <b>Temperatura</b>
                    </DialogTitle>
                    <DialogContent>
                        {isLoadingExportRelatorio ? (
                            <>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignContent: "center",
                                    }}
                                >
                                    <LoadingSpin size="16px" width="2px" primaryColor={"#2e64a8"} />
                                </Box>
                            </>
                        ) : (
                            <>
                                <Divider
                                    variant="middle"
                                    sx={{ mb: 4, color: "#0a5068", padding: "0.5px" }}
                                />
                                <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
                                    <Button
                                        variant="contained"
                                        style={{
                                            color: "white",
                                            backgroundColor: "#0a5068",
                                            textTransform: "none",
                                        }}
                                        onClick={() =>
                                            handleExportRelatorioTemperatura({
                                                reportFormat: "CSV",
                                            })
                                        }
                                    >
                                        <b>CSV</b>{" "}
                                        <SiGooglesheets style={{ color: "white", marginLeft: 6 }} />
                                    </Button>
                                    <Button
                                        variant="contained"
                                        style={{
                                            color: "white",
                                            backgroundColor: "#0a5068",
                                            textTransform: "none",
                                        }}
                                        onClick={() =>
                                            handleExportRelatorioTemperatura({
                                                reportFormat: "XLSX",
                                            })
                                        }
                                    >
                                        <b>XLSX</b>{" "}
                                        <SiMicrosoftexcel
                                            style={{ color: "white", marginLeft: 6 }}
                                        />
                                    </Button>

                                    <Button
                                        variant="contained"
                                        style={{
                                            color: "white",
                                            backgroundColor: "#0a5068",
                                            textTransform: "none",
                                        }}
                                        onClick={() =>
                                            handleExportRelatorioTemperatura({
                                                reportFormat: "JSON",
                                            })
                                        }
                                    >
                                        <b>JSON</b>{" "}
                                        <SiJson style={{ color: "white", marginLeft: 6 }} />
                                    </Button>
                                </Box>
                            </>
                        )}
                    </DialogContent>
                    <DialogActions style={{ color: "#0a5068" }}>
                        <Button
                            style={{ color: "#0a5068" }}
                            onClick={() => setIsOpenExportRelatorioTemperatura(false)}
                        >
                            Fechar
                        </Button>
                    </DialogActions>
                </Dialog>
            </Painel>
        );
    }

    return InversoresTsx;
}
