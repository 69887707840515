import React from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    TimeScale,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import "chartjs-adapter-moment";
import moment from "moment";
import { colors, converteParaHorarioLocal } from "../utils/utils";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    TimeScale,
    Title,
    Tooltip,
    Legend
);

export default function GraficoInversorCorrentesString({ inversor, date }) {
    const options = {
        radius: 1, // desabilitar pontos
        locale: 'pt-br',
        responsive: true,
        scales: {
            x: {
                grid: {
                    display: false,
                },
                type: "time",
                time: {
                    unit: "hour",
                    displayFormats: {
                        hour: "HH:00",
                    },
                },
                min: moment(new Date(date.year, date.month - 1, date.day, 5, 0, 0)),
                max: moment(new Date(date.year, date.month - 1, date.day, 20, 0, 0)),
            },
            A: {
                type: "linear",
                position: "left",
                title: {
                    display: true,
                    text: "Corrente string (A)",
                },
            },
        },
        tension: 0.4,
    };

    const data = {
        labels: inversor?.map((d) => converteParaHorarioLocal(d.data)),
        datasets: [],
    };

    inversor[0]?.inversorsCorrenteString?.forEach((item, index) => {
        data.datasets.push({
            label: `Corrente string ${index + 1}`,
            yAxisID: "A",
            fill: true,
            data: inversor?.map(
                (e) => e.inversorsCorrenteString.find((item) => item.cod == index + 1)?.valor
            ),
            borderColor: colors[index].borderColor,
            backgroundColor: colors[index].backgroundColor,
        });
    });

    return <Line options={options} data={data} />;
}
