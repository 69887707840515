// URL BACKEND DOS AMBIENTES
export const BACKEND_LOCAL = "https://api.mv-toth.com";

// limite do número de resultados exibidos nos gráficos
export const CHART_LIMIT = 75;

// locale para ser usado no componente DatePicker
export const CUSTOM_LOCALE = {
    // months list by order
    months: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
    ],

    // week days by order
    weekDays: [
        {
            name: "Domingo", // used for accessibility
            short: "Dom", // displayed at the top of days' rows
            isWeekend: true, // is it a formal weekend or not?
        },
        {
            name: "Segunda",
            short: "Seg",
        },
        {
            name: "Terça",
            short: "Ter",
        },
        {
            name: "Quarta",
            short: "Qua",
        },
        {
            name: "Quinta",
            short: "Qui",
        },
        {
            name: "Sexta",
            short: "Sex",
        },
        {
            name: "Sabado",
            short: "Sab",
            isWeekend: true,
        },
    ],

    // just play around with this number between 0 and 6
    weekStartingIndex: 6,

    // return a { year: number, month: number, day: number } object
    getToday(gregorainTodayObject) {
        return gregorainTodayObject;
    },

    // return a native JavaScript date here
    toNativeDate(date) {
        return new Date(date.day, date.month - 1, date.year);
    },

    // return a number for date's month length
    getMonthLength(date) {
        return new Date(date.year, date.month, 0).getDate();
    },

    // return a transformed digit to your locale
    transformDigit(digit) {
        return digit;
    },

    // texts in the date picker
    nextMonth: "Próximo mês",
    previousMonth: "Mês anterior",
    openMonthSelector: "Abrir seletor de mês",
    openYearSelector: "Abrir seletor de ano",
    closeMonthSelector: "Fechar seletor de mês",
    closeYearSelector: "Fechar seletor de ano",
    defaultPlaceholder: "Selecione...",

    // for input range value
    from: "De",
    to: "Até",

    // used for input value when multi dates are selected
    digitSeparator: ",",

    // if your provide -2 for example, year will be 2 digited
    yearLetterSkip: 0,

    // is your language rtl or ltr?
    isRtl: false,
};

export const roles = {
    ADMIN: "ADMIN",
    PROPRIETARIO: "PROPRIETARIO_USINA",
    RESPONSAVEL: "RESPONSAVEL_USINA",
};
