import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    Title,
    Tooltip,
    Legend,
    BarElement,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import "chartjs-adapter-moment";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);


export default function GraficoFalhasMensal({ falhasMensais }) {

    const falhasMensaisChart = {
        "Falhas de Combiner": falhasMensais?.quantidade_falhas_combiner,
        "Falhas de Inversores": falhasMensais?.quantidade_falhas_inversor,
        "Falhas de Rele": falhasMensais?.quantidade_falhas_rele,
        "Falhas de Tracker": falhasMensais?.quantidade_falhas_tracker,
        "Usina sem Geração": falhasMensais?.usina_sem_geracao,
    }

    const options = {
        locale: 'pt-br',
        responsive: true,
        scales: {
            y: {
                type: 'linear',
                grace: '5%'
            },
            x: {
                grid: {
                    display: false,
                },
            }
        },
        plugins: {
            legend: {
                position: "top",
            },
            title: {
                display: true,
                text: "Falhas Mensais",
                font: {
                    size: 16
                }
            },
        },
    };
    const data = {
        labels: Object.keys(falhasMensaisChart),
        datasets: [
            {

                label: "Número de ocorrências durante o mês",
                data: Object.values(falhasMensaisChart),
                backgroundColor: "rgb(0, 156, 119)",
            },
        ]
    };

    return <Bar options={options} data={data} />;
}


