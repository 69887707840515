import React, { createContext, useState, useCallback, useContext } from "react";
import api from "../services/api";

interface AuthState {
    token: string;
    userId: string;
}

interface SignInCredentials {
    usuario: string;
    senha: string;
}

interface AuthContextData {
    userId: string;
    signIn(credentials: SignInCredentials): Promise<void>;
    signOut(): void;
    isAuthenticated(): boolean;
    getToken(): string | null;
    getUserId(): string | null;
    getUserPerfil(): string | null;
    notificationsCount: unknown;
    setNotificationsCount: unknown;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
    const [notificationsCount, setNotificationsCount] = useState(0);
    const [data, setData] = useState<AuthState>(() => {
        const token = localStorage.getItem("@Toth-token");
        const userId = localStorage.getItem("@Toth-userId");
        if (token && userId) {
            return { token, userId: userId };
        }

        return {} as AuthState;
    });

    const signIn = useCallback(async ({ usuario, senha }) => {
        const response = await api.post(`auth/login`, { usuario, senha });

        const { access_token, id, perfil } = response.data;

        localStorage.setItem("@Toth-token", access_token);
        localStorage.setItem("@Toth-userId", id);
        localStorage.setItem("@Toth-userPerfil", perfil);

        setData({ token: access_token, userId: id });
    }, []);

    const signOut = useCallback(() => {
        localStorage.removeItem("@Toth-token");
        localStorage.removeItem("@Toth-userId");
        localStorage.removeItem("@Toth-userPerfil");

        setData({} as AuthState);
    }, []);

    const isAuthenticated = useCallback(() => {
        return localStorage.getItem("@Toth-token") !== null;
    }, []);

    const getToken = useCallback(() => {
        return localStorage.getItem("@Toth-token");
    }, []);

    const getUserId = useCallback(() => {
        return localStorage.getItem("@Toth-userId");
    }, []);

    const getUserPerfil = useCallback(() => {
        return localStorage.getItem("@Toth-userPerfil");
    }, []);

    return (
        <AuthContext.Provider
            value={{
                userId: data.userId,
                signIn,
                signOut,
                isAuthenticated,
                getUserId,
                getToken,
                notificationsCount,
                setNotificationsCount,
                getUserPerfil,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

function useAuth(): AuthContextData {
    const context = useContext(AuthContext);

    if (!context) {
        throw new Error("useAuth must be used within a AuthProvider");
    }

    return context;
}

export { AuthProvider, useAuth };
