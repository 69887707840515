import { FormControl, IconButton, Input, InputAdornment, InputLabel } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/Auth";
import { Container } from "../styles/LoginStyle";
import logo_mvp from "../assets/logo_mvp.png";

const CustomButton = styled(Button)({
    color: "white",
    backgroundColor: "#232b2b",
    borderRadius: 28,
    padding: 12,
    paddingLeft: 40,
    paddingRight: 40,
    fontWeight: "bold",
    "&:hover": {
        backgroundColor: "#000",
    },
});

export default function Login() {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { signIn } = useAuth();

    const [user, setUser] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [error, setError] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
        try {
            event.preventDefault();
            await signIn({ usuario: user, senha: password });
            enqueueSnackbar("Login realizado com sucesso!", {
                variant: "success",
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right",
                },
            });
            navigate("/");
        } catch (error) {
            //TODO errors nos inputs
            enqueueSnackbar("Nome de usuário ou senha incorretos!", { variant: "error" });
        }
    };

    return (
        <Container
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Box
                width="600px"
                sx={{
                    display: "flex",
                    gridTemplateColumns: "repeat(1, 2fr)",
                    gridTemplateRows: "auto",
                    gridTemplateAreas: `"main"`,
                }}
            >
                <form onSubmit={handleLogin}>
                    <Box width="77%">
                        <FormControl sx={{ m: 1, width: "94%" }} variant="standard">
                            <InputLabel htmlFor="user-input">Nome de usuário</InputLabel>
                            <Input
                                id="user-input"
                                value={user}
                                inputProps={{ color: "white" }}
                                onChange={(evt) => setUser(evt.target.value)}
                                autoComplete="new-password"
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1, mt: 3, width: "94%" }} variant="standard">
                            <InputLabel htmlFor="password-input">Senha</InputLabel>
                            <Input
                                id="password-input"
                                type={showPassword ? "text" : "password"}
                                value={password}
                                onChange={(evt) => setPassword(evt.target.value)}
                                autoComplete="new-password"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword(!showPassword)}
                                        >
                                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>

                        <Box
                            sx={{
                                width: "94%",
                                marginTop: "14px",
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <span
                                style={{
                                    cursor: "pointer",
                                    marginLeft: "14px",
                                    color: "#d7dae0",
                                    fontSize: 12,
                                }}
                                onClick={() => navigate("/recover_password")}
                            >
                                ESQUECI MINHA SENHA
                            </span>
                            <span
                                style={{
                                    cursor: "pointer",
                                    marginRight: "-14px",
                                    color: "#d7dae0",
                                    fontSize: 12,
                                }}
                                onClick={() => navigate("/recover_user")}
                            >
                                ESQUECI MEU NOME DE USUÁRIO
                            </span>
                        </Box>
                        <Box textAlign="right">
                            <CustomButton
                                style={{
                                    marginTop: "36px",
                                    alignSelf: "flex-end",
                                    justifyContent: "flex-end",
                                }}
                                variant="contained"
                                color="primary"
                                size="large"
                                type="submit"
                            >
                                ENTRAR
                            </CustomButton>
                        </Box>
                    </Box>
                </form>
            </Box>
            <Box>
                <img
                    src={logo_mvp}
                    alt="logo"
                    style={{
                        width: "200px",
                        margin: "auto",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                />
                <figcaption
                    style={{
                        color: "white",
                        fontStyle: "italic",
                        padding: "2px",
                        textAlign: "center",
                    }}
                >
                    Soluções de energia fotovoltaica
                </figcaption>
            </Box>
        </Container>
    );
}
