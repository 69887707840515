import React from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    TimeScale,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import "chartjs-adapter-moment";
import moment from "moment";
import { converteParaHorarioLocal } from "../utils/utils";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    TimeScale,
    Title,
    Tooltip,
    Legend
);

export default function GraficoTensoes({ inversor, date }) {
    const options = {
        radius: 1, // desabilitar pontos
        locale: 'pt-br',
        responsive: true,
        scales: {
            x: {
                grid: {
                    display: false,
                },
                type: "time",
                time: {
                    unit: "hour",
                    displayFormats: {
                        hour: "HH:00",
                    },
                },
                min: moment(new Date(date.year, date.month - 1, date.day, 5, 0, 0)),
                max: moment(new Date(date.year, date.month - 1, date.day, 20, 0, 0)),
            },
            A: {
                type: "linear",
                position: "left",
                title: {
                    display: true,
                    text: "Tensão (Volts)",
                },
            },
        },
        plugins: {
            legend: {
                position: "top",
            },
            // title: {
            //     display: true,
            //     text: "Tensões de fase",
            // },
        },
        tension: 0.4,
    };

    const data = {
        labels: inversor?.map((i) => converteParaHorarioLocal(i.data)),
        datasets: [
            {
                label: "Tensão de fase A",
                data: inversor?.map((i) => i.tensao_a),
                borderColor: "rgb(255, 99, 132)",
                backgroundColor: "rgba(255, 99, 132, 0.5)",
            },
            {
                label: "Tensão de fase B",
                data: inversor?.map((i) => i.tensao_b),
                borderColor: "rgb(53, 162, 235)",
                backgroundColor: "rgba(53, 162, 235, 0.5)",
            },
            {
                label: "Tensão de fase C",
                data: inversor?.map((i) => i.tensao_c),
                borderColor: "rgb(0, 128, 8)",
                backgroundColor: "rgba(0, 128, 0, 0.5)",
            },
        ],
    };

    return <Line options={options} data={data} fallbackContent />;
}
