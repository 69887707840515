import { formatAlarmes } from "../utils/utils";
import api from "./api";

export async function updateReceiveAlarm({ usina_id, usuario_id, recebe_alarme }) {
    const res = await api.put(`/alarme/recebe_alarme`, { usina_id, usuario_id, recebe_alarme });
    return res.data;
}

export async function getAllAlarms({ filters, currentPage }) {
    const response = await api.get(`/alarme`, {
        params: { ...filters, currentPage: String(currentPage) },
    });

    const { alarmes, total } = response.data;

    const formattedAlarmes = formatAlarmes(alarmes);
    return { total, alarmes: formattedAlarmes };
}

export async function getAllAlarmsWithoutPagination({ filters, currentPage, itemsPerPage }) {
    const response = await api.get(`/alarme`, {
        params: { ...filters, currentPage: String(currentPage), itemsPerPage: String(itemsPerPage) },
    });

    return response.data;
}

export async function getCountNotifications() {
    const res = await api.get(`/alarme/notification`);
    return res.data;
}
