import styled from "styled-components";

export const SpaceBox = styled.div`
    font-weight: 700;
    letter-spacing: 0.02em;
    font-size: 15px;
    color: #a5aab6;
    margin-top: 10px;
`;

export const SpaceBox2 = styled.div`
    font-weight: 700;
    line-height: 100%;
    letter-spacing: 0.02em;
    display: flex;
    font-size: 16px;
    color: #ffffff;
    justify-content: space-around;
    margin-top: 10px;
`;

export const SpaceBox3 = styled.div`
    height: 143px;
    width: auto;
    background-color: #ffffff;
    margin-right: 23px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-area: aside;
    display: grid;
    grid-template-rows: 30px 14px 22px;
    grid-row-start: 1;
    grid-row-end: 2;
    align-items: center;
    justify-content: space-around;
    align-content: center;
`;

//height: 143px;
export const SpaceBox4 = styled.div`
    height: 400px;
    width: auto;
    background-color: #ffffff;
    margin-right: 23px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    grid-column-start: 1;
    grid-column-end: 5;
    grid-area: aside;
    display: grid;
    grid-template-rows: 30px 14px 22px;
    grid-row-start: 1;
    grid-row-end: 2;
    align-items: center;
    justify-content: space-around;
    align-content: center;
`;

export const WriteBox = styled.div`
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.02em;
    font-size: 15px;
    line-height: 100%;
    color: #105168;
    grid-row-start: 2;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ImageBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const WriteBox2 = styled.span`
    margin-top: 15px;
    font-family: Futura LT;
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 100%;
    color: #105168;
    grid-row-start: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.02em;
`;

export const WriteBox4 = styled.span`
    font-family: Futura LT;
    font-style: normal;
    font-weight: 900;
    font-size: 17px;
    line-height: 100%;
    color: #105168;
`;

export const TableBox = styled.div`
    grid-area: footer;
    margin-top: 40px;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 100%;
    letter-spacing: 0.02em;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export const MapBox = styled.div`
    grid-area: header;
    margin-left: 23px;
    margin-right: 40px;
    width: auto;
`;
