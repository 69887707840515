import { Box, Paper } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import GraficoGeracaoMensal from "../components/GraficoGeracaoMensal";
import moment from "moment";
import logo from "../assets/Logo_nome2.png";
import GraficoFalhasMensal from "../components/GraficoFalhasMensal";
import GraficoDeConsumo from "../components/GraficoDeConsumo";


export default function RelatorioMensal() {
    let relatorioMensalTSX = (<></>)

    try {
        const relatorioData = JSON.parse(localStorage.getItem('data') ?? "")
        const falhasMensais = relatorioData.falhas_mensais ?? {}
        const geracaoMensal = relatorioData.energia_diaria_periodo ?? [{}]
        let geracaoMensalAcumulada = 0

        for (let i = 1; i < geracaoMensal.length; i++) {
            geracaoMensalAcumulada += geracaoMensal[i].energy;
        }

        const pagariaSemUsina = '-'
        const pago = '-'
        const economia = (geracaoMensalAcumulada * relatorioData?.valor_kwh).toLocaleString("pt-BR") ?? '-'
        const consumoTotal = '-'
        const geracao = Number(geracaoMensalAcumulada).toLocaleString("pt-BR") ?? '-'
        const desempenho = '-'
        const dataRelatorio = moment(new Date(relatorioData.data_relatorio.toString())).add('days', 1).format('MMM/YYYY')
        const date = moment(new Date(relatorioData.data_relatorio.toString())).add('days', 1)


        relatorioMensalTSX = (
            <>
                <Box className="p-3 w-90 h-90" style={{ backgroundColor: "#ecf7f4" }}>
                    <header>
                        <Row>
                            <Col>
                                <h1 style={{ color: "#748f89" }} className="mx-3 text-right">Relatorio {relatorioData.id}</h1>
                                <h4 style={{ color: "#748f89" }} className="mx-3 text-right">Segue os resultados da sua usina: </h4>
                            </Col>
                            <Col>
                                <p style={{ color: "#aab7b4" }}>Período</p>
                                <h1 style={{ color: "#0ca07d" }}>{dataRelatorio}</h1>
                            </Col>
                            <Col className="text-end">
                                <img
                                    className="pt-5 me-5"
                                    src={logo}
                                    style={{
                                        width: "152px",
                                    }}
                                />
                            </Col>
                        </Row>

                    </header>

                    <Row className="text-center pt-5">
                        <Col className="d-flex flex-column justify-content-between" >
                            <Box>
                                <p style={{ color: "#7e8080" }}>PAGARIA SEM A USINA (R$)</p>
                            </Box>
                            <Box className="d-flex justify-content-center">
                                <Paper className="h6 text-center pt-3 pb-3 w-50">
                                    {pagariaSemUsina}
                                </Paper>
                            </Box>
                        </Col >
                        <Col className="d-flex flex-column justify-content-between">
                            <Box>
                                <p className="h-100" style={{ color: "#7e8080" }}>PAGO (R$)</p>
                            </Box>
                            <Box className="d-flex justify-content-center">
                                <Paper className="h6 text-center pt-3 pb-3 w-50">
                                    {pago}
                                </Paper>
                            </Box>
                        </Col>
                        <Col className="d-flex flex-column justify-content-between">
                            <Box>
                                <p style={{ color: "#7e8080" }}>ECONOMIA (R$)</p>
                            </Box>
                            <Box className="d-flex justify-content-center">
                                <Paper style={{ color: '#d9a93d' }} className="h6 text-center pt-3 pb-3 w-50">
                                    {economia == 'NaN' ? '-' : economia}
                                </Paper>
                            </Box>
                        </Col>
                        <Col className="d-flex flex-column justify-content-between">
                            <Box>
                                <p style={{ color: "#7e8080" }}>CONSUMO TOTAL (KWH)</p>
                            </Box>
                            <Box className="d-flex justify-content-center">
                                <Paper style={{ color: '#d9a93d' }} className="h6 text-center pt-3 pb-3 w-50">
                                    {consumoTotal}
                                </Paper>
                            </Box>
                        </Col>
                        <Col className="d-flex flex-column justify-content-between">
                            <Box>
                                <p style={{ color: "#7e8080" }}>GERAÇÃO (KWH)</p>
                            </Box>
                            <Box className="d-flex justify-content-center">
                                <Paper style={{ color: '#d9a93d' }} className="h6 text-center pt-3 pb-3 w-50">
                                    {geracao == 'NaN' ? '-' : geracao}
                                </Paper>
                            </Box>
                        </Col>
                        <Col className="d-flex flex-column justify-content-between">
                            <Box>
                                <p style={{ color: "#7e8080" }}>DESEMPENHO (%)</p>
                            </Box>
                            <Box className="d-flex justify-content-center">
                                <Paper style={{ color: '#d9a93d' }} className="h6 text-center pt-3 pb-3 w-50">
                                    {desempenho}
                                </Paper>
                            </Box>
                        </Col>
                    </Row>

                    <Row>
                        <Col className="pt-5" sm={6}>
                            <Paper className="d-flex justify-content-center h-100 p-1">
                                <GraficoFalhasMensal falhasMensais={falhasMensais} />
                            </Paper>
                        </Col>
                        <Col style={{ filter: 'grayscale(100%)' }} className="pt-5" sm={6}>
                            <Paper className="d-flex justify-content-center h-100 p-1">
                                <GraficoDeConsumo />
                            </Paper>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="pt-5 mb-5" lg={12}>
                            <Paper className="d-flex justify-content-center" style={{ minHeight: "50vh" }}>
                                <GraficoGeracaoMensal className={"text-center "} geracaoMensal={geracaoMensal} date={date} />
                            </Paper>
                        </Col>
                    </Row>
                </Box>

            </>
        )

    } catch {
        (window as Window).location = 'relatorios'
    }
    return (relatorioMensalTSX)

};