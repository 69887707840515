import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import PersonIcon from "@mui/icons-material/Person";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    InputAdornment,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    FormControl,
    InputLabel,
    Tooltip,
    TableFooter,
    TablePagination,
    Typography,
    DialogContentText,
    Switch,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { FaEye, FaEyeSlash, FaUserEdit } from "react-icons/fa";
import styled from "styled-components";
import * as userService from "../services/userService";
import * as usinaService from "../services/usinaService";
import { HeaderTitle } from "../styles/UsuariosStyle";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { cpfMask, formatUserProfile } from "../utils/utils";

import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useTheme } from "@mui/system";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useAuth } from "../hooks/Auth";
import { roles } from "../constants";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#0a5068",
        color: "#fff",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: "#e9ecef",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
                style={{ marginTop: -15 }}
            >
                {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
                style={{ marginTop: -15 }}
            >
                {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
                style={{ marginTop: -15 }}
            >
                {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
                style={{ marginTop: -15, marginRight: 8 }}
            >
                {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

function TablePaginationActionsUsina(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
                style={{ marginTop: -15 }}
            >
                {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
                style={{ marginTop: -15 }}
            >
                {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
                style={{ marginTop: -15 }}
            >
                {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
                style={{ marginTop: -15, marginRight: 8 }}
            >
                {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const defaultUser = {
    perfil: "",
    usinas: [],
};

const defaultFilters = {
    nome: "",
    cpf: "",
    perfil: "",
    usina: "",
};

const defaultFiltersUsina = {
    nome: "",
};

export default function Usuarios() {
    const dispatch = useDispatch();
    const [user, setUser] = useState(defaultUser);
    const [isOpenAddUser, setIsOpenAddUser] = useState(false);
    const [isOpenDeleteUser, setIsOpenDeleteUser] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isVisualize, setIsVisualize] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingUsina, setLoadingUsina] = useState(false);
    const [users, setUsers] = useState([]);
    const [total, setTotal] = useState(0);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showNewPasswordConfirmation, setShowNewPasswordConfirmation] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filters, setFilters] = useState(defaultFilters);
    const [tabValue, setTabValue] = useState(0);
    const [filtersUsina, setFiltersUsina] = useState(defaultFiltersUsina);
    const [usinas, setUsinas] = useState([]);
    const [totalUsinas, setTotalUsinas] = useState(0);
    const [rowsPerPageUsina, setRowsPerPageUsina] = useState(10);
    const [pageUsina, setPageUsina] = useState(0);

    const { getUserPerfil } = useAuth();
    const userPerfil = getUserPerfil();

    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
        if (newValue == 1) {
            getFilteredUsinas();
        }
    };

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        getFilteredUsers();

        let counter = 0;
        dispatch({ type: "SET_COUNTER", value: counter });

        const intervalId = setInterval(() => {
            counter++;
            dispatch({ type: "SET_COUNTER", value: counter });
            if (counter === 60) {
                // atualização a cada 1 minuto
                getFilteredUsers();
                counter = 0;
                dispatch({ type: "SET_COUNTER", value: counter });
            }
        }, 1000);

        return () => clearInterval(intervalId);
    }, [page]);

    useEffect(() => {
        getFilteredUsinas();
    }, [pageUsina]);

    const getFilteredUsers = async (limpar = false) => {
        try {
            setLoading(true);
            const { total, usuarios } = await userService.getFilteredUsers({
                filters: limpar ? defaultFilters : filters,
                currentPage: page,
            });
            setUsers(usuarios);
            setTotal(total);
            setLoading(false);
        } catch (error) {
            enqueueSnackbar("Ocorreu um erro ao buscar usinas!", { variant: "error" });
            setLoading(false);
        }
    };

    const getFilteredUsinas = async (limpar = false) => {
        try {
            setLoadingUsina(true);
            const { total, usinas } = await usinaService.getFilteredUsinas({
                filters: limpar ? defaultFiltersUsina : filtersUsina,
                currentPage: pageUsina,
            });
            setUsinas(usinas);
            setTotalUsinas(total);
            setLoadingUsina(false);
        } catch (error) {
            enqueueSnackbar("Ocorreu um erro ao buscar as usinas!", { variant: "error" });
            setLoadingUsina(false);
        }
    };

    const getUser = async (userId) => {
        try {
            const response = await userService.getOneUser(userId);
            const usinasId = response.usinasUsuario
                ? response.usinasUsuario.map((usinaUsuario) => usinaUsuario.usina.id)
                : [];
            const formattedUser = { ...response, usinas: usinasId };
            setUser(formattedUser);
            console.log(
                "🚀 ~ file: Usuarios.jsx ~ line 340 ~ getUser ~ formattedUser",
                formattedUser
            );
        } catch (error) {
            console.log("🚀 ~ file: Usuarios.jsx ~ line 343 ~ getUser ~ error", error);
            enqueueSnackbar("Erro ao buscar usuário", { variant: "error" });
        }
    };

    const handleOpenAddUser = () => {
        setUser(defaultUser);
        setIsOpenAddUser(true);
    };

    const handleCloseAddUser = () => {
        setIsOpenAddUser(false);
        setShowNewPasswordConfirmation(false);
        setShowNewPassword(false);
        setPageUsina(0);
        setTabValue(0);
        setUser(defaultUser);
        setUsinas([]);
        setIsEdit(false);
        setIsVisualize(false);
    };

    const handleOpenDeleteUser = (selectedUser) => {
        setUser(selectedUser);
        setIsOpenDeleteUser(true);
    };

    const handleOpenEditUser = async (userId) => {
        setIsEdit(true);
        await getUser(userId);
        setIsOpenAddUser(true);
    };

    const handleOpenVisualizeUser = async (selectedUser) => {
        await getUser(selectedUser.id);
        setIsVisualize(true);
        setIsOpenAddUser(true);
    };

    const handleAddUser = async () => {
        try {
            if (user.perfil !== "ADMIN" && user.usinas.length < 1) {
                enqueueSnackbar("O usuário deve estar vinculado a pelo menos uma usina!", {
                    variant: "error",
                });
                return;
            }
            setLoading(true);
            handleCloseAddUser();
            await userService.addUser(user);
            enqueueSnackbar("Usuário cadastrado com sucesso!", {
                variant: "success",
            });
            getFilteredUsers();
            setLoading(false);
        } catch (error) {
            setLoading(false);
            if (error.response?.data?.message) {
                enqueueSnackbar(error.response?.data?.message, { variant: "error" });
            } else {
                enqueueSnackbar("Erro ao cadastrar o usuário", { variant: "error" });
            }
        }
    };

    const handleEditUser = async () => {
        try {
            if (user.perfil !== "ADMIN" && user.usinas.length < 1) {
                enqueueSnackbar("O usuário deve estar vinculado a pelo menos uma usina!", {
                    variant: "error",
                });
                return;
            }
            setLoading(true);
            handleCloseAddUser();
            await userService.editUser({ userId: user.id, user });
            enqueueSnackbar("Usuário editado com sucesso!", {
                variant: "success",
            });
            getFilteredUsers();
            setLoading(false);
        } catch (error) {
            setLoading(false);
            enqueueSnackbar("Erro ao editar o usuário", { variant: "error" });
        }
    };

    const handleDeleteUser = async () => {
        try {
            await userService.deleteUser(user.id);
            enqueueSnackbar("Usuário deletado com sucesso!", {
                variant: "success",
            });
            getFilteredUsers();
            setIsOpenDeleteUser(false);
        } catch (error) {
            setLoading(false);
            enqueueSnackbar("Erro ao deletar o usuário", { variant: "error" });
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangePageUsina = (event, newPage) => {
        setPageUsina(newPage);
    };

    const handleChangeRowsPerPageUsina = (event) => {
        setRowsPerPageUsina(parseInt(event.target.value, 10));
        setPageUsina(0);
    };

    const handleCheckUsina = (event, usina_id) => {
        const tempArray = [...user.usinas];
        const isChecked = tempArray.includes(usina_id);
        if (!isChecked) {
            tempArray.push(usina_id);
        } else {
            tempArray.splice(tempArray.indexOf(usina_id), 1);
        }
        setUser({ ...user, usinas: tempArray });
    };

    return (
        <>
            <Paper style={{ minHeight: " 90vh", padding: "38px" }} elevation={0}>
                <>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            mb: 8,
                        }}
                    >
                        <HeaderTitle>Controle de Usuários</HeaderTitle>
                        <Button
                            variant="contained"
                            onClick={handleOpenAddUser}
                            style={{ backgroundColor: "#0a5068" }}
                        >
                            Novo usuário
                        </Button>
                    </Box>
                    <Box sx={{ mb: 3, mt: -3 }}>
                        <HeaderTitle style={{ fontSize: 16 }}>Filtros</HeaderTitle>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            mb: 4,
                        }}
                    >
                        <TextField
                            style={{ width: "20%" }}
                            id="filter-name"
                            label="Nome"
                            variant="outlined"
                            value={filters.nome}
                            onChange={(evt) => setFilters({ ...filters, nome: evt.target.value })}
                        />
                        <TextField
                            style={{ width: "20%" }}
                            id="filtercpf"
                            label="Cpf"
                            variant="outlined"
                            value={filters.cpf}
                            onChange={(evt) =>
                                setFilters({ ...filters, cpf: cpfMask(evt.target.value) })
                            }
                        />
                        <FormControl style={{ width: "20%" }}>
                            <InputLabel>Perfil</InputLabel>
                            <Select
                                value={filters.perfil}
                                onChange={(e) => setFilters({ ...filters, perfil: e.target.value })}
                                label="perfil"
                            >
                                <MenuItem value="">Selecione</MenuItem>
                                {userPerfil === roles.ADMIN && (
                                    <MenuItem value={"ADMIN"}>Admin</MenuItem>
                                )}
                                <MenuItem value={"PROPRIETARIO_USINA"}>
                                    Proprietário da usina
                                </MenuItem>
                                <MenuItem value={"RESPONSAVEL_USINA"}>
                                    Responsável da usina
                                </MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            style={{ width: "20%" }}
                            id="filter-usina"
                            label="Usina"
                            variant="outlined"
                            value={filters.usina}
                            onChange={(evt) => setFilters({ ...filters, usina: evt.target.value })}
                        />
                        <Button
                            variant="outlined"
                            onClick={() => {
                                setFilters(defaultFilters);
                                getFilteredUsers(true);
                            }}
                            style={{ color: "#0a5068", borderColor: "#0a5068" }}
                        >
                            Limpar
                        </Button>

                        <Button
                            onClick={() => {
                                getFilteredUsers();
                            }}
                            size="large"
                            style={{ backgroundColor: "#0a5068", color: "white" }}
                        >
                            Buscar
                        </Button>
                    </Box>

                    {!loading ? (
                        <>
                            <TableContainer sx={{ maxHeight: 460, marginTop: 6 }} component={Paper}>
                                <Table
                                    stickyHeader
                                    sx={{ minWidth: 460 }}
                                    aria-label="customized table"
                                >
                                    <TableHead>
                                        <TableRow style={{ height: 1 }}>
                                            <StyledTableCell>Nome</StyledTableCell>
                                            <StyledTableCell>Email</StyledTableCell>
                                            <StyledTableCell>Cpf</StyledTableCell>
                                            <StyledTableCell>Perfil</StyledTableCell>
                                            <StyledTableCell align="center"></StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {users.map((user) => (
                                            <StyledTableRow key={user.id}>
                                                <StyledTableCell component="th" scope="row">
                                                    {user.nome}
                                                </StyledTableCell>
                                                <StyledTableCell>{user.email}</StyledTableCell>
                                                <StyledTableCell>{user.cpf}</StyledTableCell>
                                                <StyledTableCell>
                                                    {formatUserProfile(user.perfil)}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <Tooltip title="Visualizar" arrow>
                                                        <IconButton
                                                            onClick={() =>
                                                                handleOpenVisualizeUser(user)
                                                            }
                                                            size="large"
                                                        >
                                                            <>
                                                                <RemoveRedEyeIcon
                                                                    style={{ fill: "#0a5068" }}
                                                                />
                                                            </>
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Editar" arrow>
                                                        <IconButton
                                                            size="large"
                                                            onClick={() =>
                                                                handleOpenEditUser(user.id)
                                                            }
                                                        >
                                                            <EditIcon style={{ fill: "#0a5068" }} />
                                                        </IconButton>
                                                    </Tooltip>

                                                    <Tooltip title="Remover" arrow>
                                                        <IconButton
                                                            onClick={() =>
                                                                handleOpenDeleteUser(user)
                                                            }
                                                            size="large"
                                                        >
                                                            <DeleteIcon
                                                                style={{ fill: "#0a5068" }}
                                                            />
                                                        </IconButton>
                                                    </Tooltip>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <TableContainer
                                style={{
                                    marginTop: 16,
                                    marginBottom: 0,
                                    borderBottom: 0,
                                    minHeight: 0,
                                }}
                            >
                                <Table stickyHeader aria-label="customized table">
                                    <TableFooter>
                                        <TablePagination
                                            rowsPerPageOptions={[]}
                                            count={total}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            SelectProps={{
                                                inputProps: {
                                                    "aria-label": "Linhas por página",
                                                },
                                            }}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationActions}
                                        />
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </>
                    ) : (
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "50vh",
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    )}
                </>
            </Paper>
            <Dialog
                maxWidth={tabValue == 0 ? "md" : "md"}
                fullWidth
                disableScrollLock={true}
                open={isOpenAddUser}
                onClose={handleCloseAddUser}
            >
                <DialogTitle style={{ color: "#0a5068" }}>
                    {isEdit
                        ? "Editar usuário"
                        : isVisualize
                        ? "Visualizar usuário"
                        : "Criar novo usuário"}
                </DialogTitle>
                <DialogContent>
                    <Divider variant="middle" sx={{ mb: 3, color: "#0a5068", padding: "0.5px" }} />

                    <Box>
                        <Tabs
                            value={tabValue}
                            onChange={handleChangeTab}
                            centered
                            aria-label="basic tabs example"
                        >
                            <Tab label="Dados do usuário" {...a11yProps(0)} />
                            {user.perfil !== "ADMIN" && <Tab label="Usinas" {...a11yProps(1)} />}
                        </Tabs>
                    </Box>
                    <TabPanel value={tabValue} index={0}>
                        <Box
                            noValidate
                            component="form"
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                            }}
                        >
                            {isEdit ? (
                                <FaUserEdit
                                    sx={{ flexShrink: 0 }}
                                    style={{
                                        fontSize: 180,
                                        marginTop: "-8px",
                                        color: "#0a5068",
                                        alignSelf: "center",
                                        justifyContent: "center",
                                    }}
                                />
                            ) : isVisualize ? (
                                <PersonIcon
                                    sx={{ flexShrink: 0 }}
                                    style={{
                                        fontSize: 180,
                                        marginTop: "-8px",
                                        color: "#0a5068",
                                        alignSelf: "center",
                                        justifyContent: "center",
                                    }}
                                />
                            ) : (
                                <PersonAddIcon
                                    sx={{ flexShrink: 0 }}
                                    style={{
                                        fontSize: 180,
                                        marginTop: "-8px",
                                        marginLeft: "-16px",
                                        color: "#0a5068",
                                        alignSelf: "center",
                                        justifyContent: "center",
                                    }}
                                />
                            )}

                            <Box
                                noValidate
                                component="form"
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "fit-content",
                                    flexGrow: 1,
                                    marginLeft: 3,
                                }}
                            >
                                <TextField
                                    label="Nome"
                                    id="input-nome"
                                    style={{ marginBottom: "8px" }}
                                    sx={{ m: 1, width: "90%" }}
                                    variant="standard"
                                    disabled={isVisualize}
                                    InputProps={{}}
                                    value={user.nome}
                                    onChange={(evt) => setUser({ ...user, nome: evt.target.value })}
                                />

                                <TextField
                                    label="Email"
                                    id="input-email"
                                    sx={{ m: 1, width: "90%" }}
                                    variant="standard"
                                    InputProps={{}}
                                    disabled={isVisualize}
                                    value={user.email}
                                    onChange={(evt) =>
                                        setUser({ ...user, email: evt.target.value })
                                    }
                                />

                                <TextField
                                    label="CPF"
                                    id="cpf-input"
                                    sx={{ m: 1, width: "90%" }}
                                    variant="standard"
                                    disabled={isVisualize}
                                    InputProps={{ maxLength: 14 }}
                                    value={user.cpf}
                                    onChange={(evt) =>
                                        setUser({ ...user, cpf: cpfMask(evt.target.value) })
                                    }
                                />

                                <FormControl style={{ marginTop: 8 }}>
                                    <InputLabel style={{ marginLeft: -4 }}>Perfil</InputLabel>
                                    <Select
                                        sx={{ m: 1, width: "90%" }}
                                        value={user.perfil}
                                        disabled={isVisualize}
                                        variant="standard"
                                        onChange={(e) =>
                                            setUser({ ...user, perfil: e.target.value })
                                        }
                                        label="perfil"
                                    >
                                        <MenuItem value="">
                                            <em>Selecione</em>
                                        </MenuItem>
                                        {userPerfil === roles.ADMIN && (
                                            <MenuItem value={"ADMIN"}>Admin</MenuItem>
                                        )}
                                        <MenuItem value={"PROPRIETARIO_USINA"}>
                                            Proprietário da usina
                                        </MenuItem>
                                        <MenuItem value={"RESPONSAVEL_USINA"}>
                                            Responsável da usina
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField
                                    label="Usuário"
                                    id="usuario-input"
                                    sx={{ m: 1, width: "90%" }}
                                    variant="standard"
                                    InputProps={{}}
                                    value={user.usuario}
                                    disabled={isVisualize}
                                    onChange={(evt) =>
                                        setUser({ ...user, usuario: evt.target.value })
                                    }
                                />
                                {!isVisualize && !isEdit && (
                                    <>
                                        <TextField
                                            label="Senha"
                                            id="input-senha"
                                            sx={{ m: 1, width: "90%" }}
                                            autoComplete="new-password"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="start">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() =>
                                                                setShowNewPassword(!showNewPassword)
                                                            }
                                                        >
                                                            {showNewPassword ? (
                                                                <FaEyeSlash />
                                                            ) : (
                                                                <FaEye />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            variant="standard"
                                            type={showNewPassword ? "text" : "password"}
                                            value={user.senha}
                                            onChange={(evt) =>
                                                setUser({ ...user, senha: evt.target.value })
                                            }
                                        />

                                        <TextField
                                            label="Confirmação de senha"
                                            id="confirm-password"
                                            sx={{ m: 1, width: "90%" }}
                                            autoComplete="new-password"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="start">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() =>
                                                                setShowNewPasswordConfirmation(
                                                                    !showNewPasswordConfirmation
                                                                )
                                                            }
                                                        >
                                                            {showNewPasswordConfirmation ? (
                                                                <FaEyeSlash />
                                                            ) : (
                                                                <FaEye />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            variant="standard"
                                            type={showNewPasswordConfirmation ? "text" : "password"}
                                            value={user.senhaConfirmacao}
                                            onChange={(evt) =>
                                                setUser({
                                                    ...user,
                                                    senhaConfirmacao: evt.target.value,
                                                })
                                            }
                                        />
                                    </>
                                )}
                            </Box>
                        </Box>
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        <DialogContentText
                            sx={{ marginTop: 1, marginBottom: 1 }}
                            id="alert-dialog-slide-description"
                        >
                            {isEdit ? "Edite" : isVisualize ? "Visualize" : "Selecione"} as usinas
                            que o usuário {user.nome ? user.nome : ""} tem acesso:
                        </DialogContentText>
                        <DialogContentText
                            sx={{ marginTop: 2, marginBottom: 1 }}
                            id="alert-dialog-slide-description"
                        >
                            Filtros
                        </DialogContentText>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "start",
                                mb: 4,
                            }}
                        >
                            <TextField
                                style={{ width: "20%", marginRight: 24 }}
                                id="filter-name"
                                label="Nome"
                                variant="outlined"
                                value={filtersUsina.nome}
                                onChange={(evt) =>
                                    setFiltersUsina({ ...filtersUsina, nome: evt.target.value })
                                }
                            />
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    setFiltersUsina(defaultFiltersUsina);
                                    getFilteredUsinas(true);
                                }}
                                style={{
                                    color: "#0a5068",
                                    borderColor: "#0a5068",
                                    marginRight: 16,
                                }}
                            >
                                Limpar
                            </Button>

                            <Button
                                onClick={() => {
                                    getFilteredUsinas();
                                }}
                                size="large"
                                style={{ backgroundColor: "#0a5068", color: "white" }}
                            >
                                Buscar
                            </Button>
                        </Box>
                        {!loadingUsina ? (
                            <>
                                <TableContainer
                                    sx={{ maxHeight: 360, marginTop: 6 }}
                                    component={Paper}
                                >
                                    <Table
                                        stickyHeader
                                        sx={{ minWidth: 460 }}
                                        aria-label="customized table"
                                    >
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell colSpan={1}>ID</StyledTableCell>
                                                <StyledTableCell colSpan={1}>Usina</StyledTableCell>
                                                <StyledTableCell align="center" colSpan={1}>
                                                    Possui acesso?
                                                </StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {usinas.map((usina) => (
                                                <StyledTableRow key={usina.id}>
                                                    <StyledTableCell
                                                        colSpan={1}
                                                        component="th"
                                                        scope="row"
                                                    >
                                                        {usina.id}
                                                    </StyledTableCell>
                                                    <StyledTableCell
                                                        colSpan={1}
                                                        component="th"
                                                        scope="row"
                                                    >
                                                        {usina.nome}
                                                    </StyledTableCell>
                                                    <StyledTableCell
                                                        colSpan={1}
                                                        component="th"
                                                        scope="row"
                                                        align="center"
                                                    >
                                                        <Switch
                                                            onChange={(e) =>
                                                                handleCheckUsina(e, usina.id)
                                                            }
                                                            inputProps={{
                                                                "aria-label": "controlled",
                                                            }}
                                                            checked={user.usinas?.includes(
                                                                usina.id
                                                            )}
                                                            disabled={isVisualize}
                                                        />
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                                <TableContainer
                                    style={{
                                        marginTop: 16,
                                        marginBottom: 0,
                                        borderBottom: 0,
                                        minHeight: 0,
                                    }}
                                >
                                    <Table stickyHeader aria-label="customized table">
                                        <TableFooter>
                                            <TablePagination
                                                rowsPerPageOptions={[]}
                                                count={totalUsinas}
                                                rowsPerPage={rowsPerPageUsina}
                                                page={pageUsina}
                                                SelectProps={{
                                                    inputProps: {
                                                        "aria-label": "Linhas por página",
                                                    },
                                                }}
                                                onPageChange={handleChangePageUsina}
                                                onRowsPerPageChange={handleChangeRowsPerPageUsina}
                                                ActionsComponent={TablePaginationActionsUsina}
                                            />
                                        </TableFooter>
                                    </Table>
                                </TableContainer>
                            </>
                        ) : (
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "50vh",
                                }}
                            >
                                <CircularProgress />
                            </Box>
                        )}
                    </TabPanel>

                    {/* // */}
                </DialogContent>
                <DialogActions>
                    {tabValue == 0 && <Button onClick={handleCloseAddUser}>Fechar</Button>}
                    {tabValue == 0 && <Button onClick={() => setTabValue(1)}>Próximo</Button>}
                    {tabValue == 1 && <Button onClick={() => setTabValue(0)}>Voltar</Button>}
                    {tabValue == 1 && isVisualize && (
                        <Button onClick={handleCloseAddUser}>Fechar</Button>
                    )}
                    {tabValue == 1 && !isVisualize && (
                        <Button onClick={isEdit ? handleEditUser : handleAddUser}>Confirmar</Button>
                    )}
                </DialogActions>
            </Dialog>

            <Dialog
                maxWidth="sm"
                fullWidth
                disableScrollLock={true}
                open={isOpenDeleteUser}
                onClose={() => setIsOpenDeleteUser(false)}
            >
                <DialogTitle style={{ color: "#0a5068" }}>Remover usuário</DialogTitle>
                <DialogContent>
                    <Divider variant="middle" sx={{ mb: 4, color: "#0a5068", padding: "0.5px" }} />
                    Você tem certeza que deseja remover o usuário {user.nome}?
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsOpenDeleteUser(false)}>Fechar</Button>
                    {<Button onClick={handleDeleteUser}>Confirmar</Button>}
                </DialogActions>
            </Dialog>
        </>
    );
}
