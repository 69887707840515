import { createStore, combineReducers } from "redux";
import LastUpdateCounterReducer from "./LastUpdateCounter";

const reducers = combineReducers({
    lastUpdateCounter: LastUpdateCounterReducer,
});

function store() {
    return createStore(reducers);
}

export default store;
