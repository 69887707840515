import "bootstrap/dist/css/bootstrap.min.css";
import L from "leaflet";
import markerIcon from "leaflet/dist/images/marker-icon.png";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import "leaflet/dist/leaflet.css";
import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { useDispatch } from "react-redux";
import "../App.css";
import BarraSuperior from "../components/BarraSuperior";
import * as usinaService from "../services/usinaService";
import {
    ImageBox,
    MapBox,
    SpaceBox3,
    SpaceBox4,
    TableBox,
    WriteBox,
    WriteBox2,
    WriteBox4,
} from "../styles/InicioStyles";
import moment from "moment";
import nuvem from "../assets/nuvem.png";
import casa from "../assets/casa.png";
import arvore from "../assets/arvore.png";
import receita from "../assets/receita.png";
import sol from "../assets/sol.png";
import termometro from "../assets/termometro.png";
import vento from "../assets/vento.png";
import umidade from "../assets/umidade.png";
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Chip,
    Tooltip,
    IconButton,
} from "@mui/material";
export default function Home() {
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const [usinas, setUsinas] = useState([]);
    const [totalizadores, setTotalizadores] = useState({});
    const [isOpenAlbedos, setIsOpenAlbedos] = useState(false);
    const [selectedUsina, setSelectedUsina] = useState({});
    const [usinaSelecionada, setUsinaSelecionada] = useState(0);

    const markerIconConst = L.icon({
        iconUrl: markerIcon,
        iconRetinaUrl: markerIcon,
        iconAnchor: [5, 55],
        popupAnchor: [10, -44],
        iconSize: [25, 40],
    });
    const irradianciasNames = {
        3: "Horizontal",
        2: "Refletida",
        1: "Inclinada",
    }
    const redIcon = new L.Icon({
        iconUrl:
            "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png",
        shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
        iconAnchor: [5, 55],
        popupAnchor: [10, -44],
        iconSize: [25, 40],
    });

    function outDated(date) {
        if (moment(new Date()).diff(moment(date), 'days') >= 1) {
            return true
        } else {
            return false
        }
    }

    useEffect(() => {
        setLoading(true);
        async function updateHomeData() {
            try {
                const responseUsinas = await usinaService.getAllUsinasHome();
                setUsinas(responseUsinas);
                console.log(
                    "🚀 ~ file: Home.jsx ~ line 63 ~ updateHomeData ~ responseUsinas",
                    responseUsinas
                );
                const responseTotalizadores = await usinaService.getAllUsinasTotalizadores();
                setTotalizadores(responseTotalizadores);
                setLoading(false);
            } catch (error) {
                console.log("🚀 ~ file: Home.jsx ~ line 71 ~ updateHomeData ~ error", error);
                setLoading(false);
            }
        }

        updateHomeData(); // carregamento inicial

        let counter = 0;
        dispatch({ type: "SET_COUNTER", value: counter });

        const intervalId = setInterval(() => {
            counter++;
            dispatch({ type: "SET_COUNTER", value: counter });
            if (counter === 60) {
                // atualização a cada 1 minuto
                updateHomeData();
                counter = 0;
                dispatch({ type: "SET_COUNTER", value: counter });
            }
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    let HomeJsx = (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                height: "75vh",
            }}
        >
            <CircularProgress />
        </Box>
    );

    const handleOpenAlbedos = (usina) => {
        setSelectedUsina(usina);
        setIsOpenAlbedos(true);
    };

    const handleNextUsina = () => {
        if (usinas.length > 1)
            usinaSelecionada < usinas.length - 1
                ? setUsinaSelecionada(usinaSelecionada + 1)
                : setUsinaSelecionada(0);
    };

    const handlePreviousUsina = () => {
        if (usinas.length > 1)
            usinaSelecionada == 0
                ? setUsinaSelecionada(usinas.length - 1)
                : setUsinaSelecionada(usinaSelecionada - 1);
    };

    if (!loading) {
        HomeJsx = (
            <div
                style={{
                    backgroundColor: "#E5E5E5",
                    minHeight: "90vh",
                    margin: "0px",
                }}
            >
                <BarraSuperior totalizadores={totalizadores} isHome={true} />
                <section
                    style={{
                        marginTop: "40px",
                        display: "grid",
                        gridTemplateColumns: "2fr 2fr",
                        //backgroundColor: "green",
                        gridTemplateAreas: `
                    'header aside'
                    'footer footer'
                `,
                    }}
                >
                    {/* <SpaceBox3 style={{ marginRight: 0 }}>
                        <ImageBox>
                            <img src={nuvem} alt="" style={{ width: "30px" }} />
                        </ImageBox>
                        <WriteBox>CO2 Evitados</WriteBox>
                        <WriteBox2>
                            {totalizadores.co2_evitado.toLocaleString("pt-BR") || 0} t
                        </WriteBox2>
                        <ImageBox>
                            <img src={arvore} alt="" />
                        </ImageBox>
                        <WriteBox>Arvores Salvas</WriteBox>
                        <WriteBox2>
                            {totalizadores.arvores_salvas.toLocaleString("pt-BR") || 0}
                        </WriteBox2>
                        <ImageBox>
                            <img src={receita} alt="" />
                        </ImageBox>
                        <WriteBox>Receita Gerada</WriteBox>
                        <WriteBox2>
                            {totalizadores.receita_gerada.toLocaleString("pt-BR", {
                                minimumFractionDigits: 2,
                                style: "currency",
                                currency: "BRL",
                            })}
                        </WriteBox2>
                    </SpaceBox3> */}
                    <SpaceBox4 style={{ marginTop: "0px", marginRight: 0, position: "relative" }}>
                        <span style={{ position: "absolute", top: "5%", left: "2%" }}>
                            <WriteBox4>{`${usinas[usinaSelecionada]?.nome} - ${usinas[usinaSelecionada]?.cidade}`}</WriteBox4>
                        </span>

                        <ImageBox></ImageBox>
                        <WriteBox>
                            <IconButton onClick={handlePreviousUsina}>
                                <ChevronLeftIcon />
                            </IconButton>
                        </WriteBox>
                        <WriteBox2></WriteBox2>

                        <ImageBox>
                            <img src={termometro} alt="" style={{ width: "27px" }} />
                        </ImageBox>
                        <WriteBox>Temperatura</WriteBox>
                        <WriteBox2>
                            {outDated(usinas[usinaSelecionada].data) ? '-' : usinas[usinaSelecionada].temperatura_ar.toFixed(2) == 0 ?
                                '-' : usinas[usinaSelecionada].temperatura_ar.toFixed(2)
                            } ºC
                        </WriteBox2>
                        <ImageBox>
                            <img src={termometro} alt="" style={{ width: "27px" }} />
                        </ImageBox>
                        <WriteBox>Temperatura Módulo</WriteBox>
                        <WriteBox2>
                            {
                                outDated(usinas[usinaSelecionada].data) || usinas[usinaSelecionada]?.temperatura_mods?.length == 0 ? '-' :
                                    usinas[usinaSelecionada].temperatura_mods?.find(
                                        (temperatura) => temperatura.cod == 1
                                    )?.valor.toFixed(2)
                            } ºC
                        </WriteBox2>
                        <ImageBox>
                            <img src={sol} alt="" style={{ width: "27px" }} />
                        </ImageBox>
                        <WriteBox>Irradiâncias</WriteBox>
                        <WriteBox2>
                            {" "}
                            <Button
                                onClick={() => handleOpenAlbedos(usinas[usinaSelecionada])}
                                variant="contained"
                                style={{
                                    backgroundColor: "#0a5068",
                                    padding: 4,
                                }}
                                size="small"
                            >
                                Visualizar
                            </Button>
                        </WriteBox2>
                        <ImageBox>
                            <img src={vento} alt="" />
                        </ImageBox>
                        <WriteBox>Vento</WriteBox>
                        <WriteBox2>{outDated(usinas[usinaSelecionada].data) ? '-' : usinas[usinaSelecionada].vento.toFixed(2) == 0 ?
                            '-' : usinas[usinaSelecionada].vento.toFixed(2)
                        } km/h</WriteBox2>
                        <ImageBox>
                            <img src={umidade} alt="" />
                        </ImageBox>
                        <WriteBox>Umidade</WriteBox>
                        <WriteBox2>{outDated(usinas[usinaSelecionada].data) ? '-' : usinas[usinaSelecionada].umidade.toFixed(2) == 0 ?
                            '-' : usinas[usinaSelecionada].umidade.toFixed(2)
                        } %</WriteBox2>
                        <ImageBox></ImageBox>
                        <WriteBox>
                            <IconButton onClick={handleNextUsina}>
                                <ChevronRightIcon />
                            </IconButton>
                        </WriteBox>
                        <WriteBox2></WriteBox2>
                    </SpaceBox4>
                    <MapBox style={{ marginLeft: 0 }}>
                        <MapContainer
                            center={[-16.3460888, -43.6239527]}
                            zoom={4}
                            scrollWheelZoom={true}
                            style={{ height: "100%", width: "100%" }}
                        >
                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            {usinas.map((usina) => (
                                <>
                                    <Marker
                                        icon={usina.online ? markerIconConst : redIcon}
                                        position={[usina.latitude, usina.longitude]}
                                    >
                                        <Popup editable>{`${usina.nome} - ${usina.cidade}`}</Popup>
                                    </Marker>
                                </>
                            ))}
                        </MapContainer>
                    </MapBox>
                    <TableBox>
                        <Table striped bordered hover>
                            <thead
                                style={{
                                    backgroundColor: "#D5DFE2",
                                    borderRadius: "10px 10px 0px 0px",
                                    alignItems: "center",
                                    textAlign: "center",
                                }}
                            >
                                <tr
                                    style={{
                                        color: "#105168",
                                        fontStyle: "normal",
                                    }}
                                >
                                    <th>Usina</th>
                                    <th>Cidade</th>
                                    <th>Capacidade (kW)</th>
                                    <th>Clima (°C)</th>
                                    <th>Irradiação (W/m²)</th>
                                    <th>Hoje (kWh)</th>
                                    <th>Mensal (MWh)</th>
                                    <th>Total (MWh)</th>
                                    <th>Eficiência</th>
                                    <th>Alarmes</th>
                                    <th>Inversores</th>
                                </tr>
                            </thead>
                            <tbody
                                style={{
                                    alignItems: "center",
                                    textAlign: "center",
                                    alignSelf: "center",
                                    color: "#105168",
                                    fontStyle: "normal",
                                    verticalAlign: "middle",
                                }}
                            >
                                {usinas.map((usina) => (
                                    <>
                                        {" "}
                                        <tr
                                            key={usina.nome}
                                            style={{
                                                color: "#105168",
                                                fontStyle: "normal",
                                                verticalAlign: "middle",
                                            }}
                                        >
                                            <td>{usina.nome}</td>
                                            <td>
                                                {usina.cidade}
                                                {/* {usina.cidade ? `${usina.cidade} / ` : ""}
                                                {usina.estado ? usina.estado : ""} */}
                                            </td>
                                            <td>{usina.capacidade?.toLocaleString("pt-BR")}</td>
                                            <td>{usina.temperatura_ar && usina.temperatura_ar > 0 && !outDated(usina.data) ? usina.temperatura_ar.toFixed(2) : '-'}</td>
                                            <td>
                                                {
                                                    outDated(usina.data) || usina.irradiancias.length <= 0 ?
                                                        (<>-</>) :
                                                        (<Button
                                                            onClick={() => handleOpenAlbedos(usina)}
                                                            variant="contained"
                                                            style={{
                                                                backgroundColor: "#0a5068",
                                                                padding: 4,
                                                            }}
                                                            size="small"
                                                        >
                                                            Visualizar
                                                        </Button>)
                                                }
                                            </td>
                                            <td>{usina.diario && usina.diario > 0 ? usina.diario.toLocaleString("pt-BR") : '-'}</td>
                                            <td>{usina.mensal && usina.mensal > 0 ? usina.mensal.toLocaleString("pt-BR") : '-'}</td>
                                            <td>{usina.total && usina.total > 0 ? (usina.total / 1000).toLocaleString("pt-BR") : '-'}</td>
                                            <td>{usina.eficiencia && usina.eficiencia > 0 ? usina.eficiencia + '%' : '-'}</td>
                                            <td>{usina.alarmes}</td>
                                            <td>
                                                <Tooltip title="Ativo" arrow>
                                                    <Chip
                                                        label={usina.inversores.verde}
                                                        style={{
                                                            backgroundColor: "green",
                                                            color: "white",
                                                            marginRight: "4px",
                                                        }}
                                                        size="small"
                                                    />
                                                </Tooltip>

                                                <Tooltip title="Iniciando" arrow>
                                                    <Chip
                                                        label={usina.inversores.azul}
                                                        style={{
                                                            backgroundColor: "#3C96C6",
                                                            color: "white",
                                                            marginRight: "4px",
                                                        }}
                                                        size="small"
                                                    />
                                                </Tooltip>

                                                <Tooltip title="Stand by" arrow>
                                                    <Chip
                                                        label={usina.inversores.amarelo}
                                                        style={{
                                                            backgroundColor: "#F5E216",
                                                            color: "white",
                                                            marginRight: "4px",
                                                        }}
                                                        size="small"
                                                    />
                                                </Tooltip>

                                                <Tooltip title="Offline" arrow>
                                                    <Chip
                                                        label={usina.inversores.laranja}
                                                        style={{
                                                            backgroundColor: "orange",
                                                            color: "white",
                                                            marginRight: "4px",
                                                        }}
                                                        size="small"
                                                    />
                                                </Tooltip>

                                                <Tooltip title="Falha" arrow>
                                                    <Chip
                                                        label={usina.inversores.vermelho}
                                                        style={{
                                                            backgroundColor: "red",
                                                            color: "white",
                                                        }}
                                                        size="small"
                                                    />
                                                </Tooltip>
                                            </td>
                                        </tr>
                                    </>
                                ))}
                            </tbody>
                        </Table>
                    </TableBox>
                </section>
                <Dialog
                    maxWidth="sm"
                    fullWidth
                    disableScrollLock={true}
                    open={isOpenAlbedos}
                    onClose={() => {
                        setIsOpenAlbedos(false);
                        setSelectedUsina({});
                    }}
                >
                    <DialogTitle style={{ color: "#0a5068" }}>
                        <b>Irradiações - {selectedUsina.nome}</b>
                    </DialogTitle>
                    <DialogContent>
                        <>
                            <Divider
                                variant="middle"
                                sx={{ mb: 4, color: "#0a5068", padding: "0.5px" }}
                            />
                            {console.log(selectedUsina)}
                            {selectedUsina?.irradiancias?.estacaoDnis?.map((item, index) => (
                                <>
                                    <Box sx={{ display: "flex" }}>
                                        <p style={{ marginRight: 4 }}>Irradiância {irradianciasNames[item.cod]}:</p>{" "}
                                        <b> {item.valor} W/m²</b>
                                    </Box>
                                </>
                            ))}
                            {selectedUsina?.irradiancias?.estacaoAlbedos?.map((item, index) => (
                                <>
                                    <Box sx={{ display: "flex" }}>
                                        <p style={{ marginRight: 4 }}>Albedo {index + 1}:</p>{" "}
                                        <b> {item.valor} W/m²</b>
                                    </Box>
                                </>
                            ))}
                        </>
                    </DialogContent>
                    <DialogActions style={{ color: "#0a5068" }}>
                        <Button
                            style={{ color: "#0a5068" }}
                            onClick={() => {
                                setIsOpenAlbedos(false);
                                setSelectedUsina({});
                            }}
                        >
                            Fechar
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    return HomeJsx;
}
