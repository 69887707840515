import api from "./api";

export async function findBetweenDatesFromCombiner({ usinaId, intervaloDTO, combinerId }) {
    const res = await api.get(`/combiner/${combinerId}/usina/${usinaId}/getBetweenDates`, {
        params: {
            ...intervaloDTO,
        },
    });
    return res.data;
}

export async function exportRelatorioCombiner({ usinaId, intervaloDTO, reportFormat }) {
    const params = {
        startDate: intervaloDTO.startDate,
        endDate: intervaloDTO.endDate,
        reportFormat,
    };

    const res = await api.get(`combiner/0/usina/${usinaId}/getBetweenDates`, {
        params: { ...params },
        responseType: reportFormat == "JSON" ? "json" : "blob",
    });

    return res.data;
}
