import api from "./api";

export async function getAllUsers() {
    const res = await api.get("/usuario");
    return res.data;
}

export async function getSelfUser() {
    const res = await api.get("/usuario/self");
    return res.data;
}

export async function getOneUser(userId) {
    const res = await api.get(`/usuario/${userId}`);
    return res.data;
}

export async function getFilteredUsers({ filters, currentPage }) {
    const res = await api.get(`/usuario/filtered`, {
        params: {
            ...filters,
            currentPage: String(currentPage),
        },
    });
    return res.data;
}

export async function changePassword({ senha, senhaConfirmacao, senhaAtual }) {
    const res = await api.post("/auth/change-password-by-current-password", {
        senha,
        senhaConfirmacao,
        senhaAtual,
    });
    return res.data;
}

export async function checkPassword(senha) {
    const res = await api.post("/auth/check-password", {senha});
    return res;
}

export async function addUser(user) {
    const res = await api.post("/usuario/", { ...user });
    return res.data;
}

export async function editUser({ userId, user }) {
    const res = await api.put(`/usuario/${userId}`, { ...user });
    return res.data;
}

export async function editSelfUser(data) {
    const res = await api.put(`/usuario/self`, { ...data });
    return res.data;
}

export async function deleteUser(userId) {
    const res = await api.delete(`/usuario/${userId}`);
    return res.data;
}
