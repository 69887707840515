import { Paper, CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import * as trackerService from "../services/trackerService";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import grid_icon from "../assets/grid_icon.png";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import tracker from "../assets/tracker.png";
import moment from "moment";
import CloudOffOutlinedIcon from '@mui/icons-material/CloudOffOutlined';
import { converteParaHorarioLocal, trackersAlarmsFilter } from "../utils/utils";
import Table from 'react-bootstrap/Table';
import { Container, Row, Col } from 'react-bootstrap';
import * as alarmeService from "../services/alarmeService";

const horaAtual = moment(new Date, "YYYY-MM-DDTHH:mm:ss")
const filters = {
    nome: "",
    usina: "39",
    tipo_equipamento: "tracker",
    status: "ativo",
    data_inicio: null,
    data_fim: null,
};

export default function Trackers() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [trackers, setTrackers] = useState([]);
    const { usinaId } = useParams();
    const [trackersAlarmes, setTrackersAlarmes] = useState([])

    function responseTimeHandle(responseData) {
        let responseConverted = moment(new Date(converteParaHorarioLocal(responseData)), "YYYY-MM-DDTHH:mm:ss")

        let diff = horaAtual.diff(responseConverted, 'minutes')

        return diff
    }

    const formatAngulo = (angulo) =>
        `${Math.abs(angulo).toFixed(2)}º ${angulo < 0 ? "Leste" : "Oeste"}`;

    const getTrackers = async () => {
        try {
            setLoading(true);
            const response = await trackerService.buscarUltimosRegistrosTrackers(usinaId);
            const alarmes = await alarmeService.getAllAlarmsWithoutPagination({
                filters: filters,
                currentPage: 0,
                itemsPerPage: 100
            });
            setTrackersAlarmes(trackersAlarmsFilter(alarmes.alarmes))
            setTrackers(response);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(
                "🚀 ~ file: EstacaoMetereologica.jsx ~ line 21 ~ getDadosEstacao ~ error",
                error
            );
        }
    };

    useEffect(() => {
        getTrackers();

        let counter = 0;
        dispatch({ type: "SET_COUNTER", value: counter });

        const intervalId = setInterval(() => {
            counter++;
            dispatch({ type: "SET_COUNTER", value: counter });
            if (counter === 60) {
                // atualização a cada 1 minuto
                getTrackers();
                counter = 0;
                dispatch({ type: "SET_COUNTER", value: counter });
            }
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <>
            {loading ? (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "75vh",
                    }}
                >
                    <CircularProgress />
                </Box>
            ) : trackers.length > 0 ? (
                <Paper style={{ minHeight: " 90vh", padding: "38px" }} elevation={3}>
                    <>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                mb: 8,
                            }}
                        >
                            <img src={tracker} alt="" style={{ marginRight: "5px" }} />

                            <b style={{ fontSize: "20px", marginLeft: 8, marginTop: -3 }}>
                                Trackers
                            </b>
                        </Box>
                        <Container>
                            <Row>
                                <Col>
                                    <Table hover className="table table-striped">
                                        <thead className="text-light" style={{ backgroundColor: "#0a5068" }}>
                                            <tr>
                                                <th>Numero do Tracker</th>
                                                <th>Posição Solar (º)</th>
                                                <th className="text-end">Posição Painel (º)</th>
                                                <th></th>
                                                <th className="text-start">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody className="thead-light" style={{ color: "#4E596B", fontWeight: 700 }}>
                                            {trackers.map((tracker, index) => (
                                                <tr key={index}>
                                                    <td>Tracker {tracker.cod}</td>
                                                    <td>{formatAngulo(tracker.angulo_real)}</td>
                                                    <td className="text-end">{formatAngulo(tracker.angulo_ideal)}</td>
                                                    <td className="text-end">
                                                        {responseTimeHandle(tracker?.data) >= 20 ? (
                                                            <CloudOffOutlinedIcon style={{ color: "#ff9233" }} />
                                                        ) :
                                                            trackersAlarmes.has(tracker.cod) ? (
                                                                <WarningAmberIcon style={{ color: "#ffc107" }} />
                                                            ) :
                                                                tracker.status === 1 ? (
                                                                    <CheckCircleOutlineIcon style={{ color: "#049B5F" }} />
                                                                ) : (
                                                                    <CancelOutlinedIcon style={{ color: "#D40707" }} />
                                                                )}
                                                    </td>
                                                    {responseTimeHandle(tracker?.data) >= 20 ? (
                                                        <td
                                                            style={{
                                                                fontSize: "18px",
                                                                letterSpacing: "0.02rem",
                                                                color: "#ff9233",
                                                            }}>
                                                            Offline
                                                        </td>
                                                    ) : trackersAlarmes.has(tracker.cod) ? (
                                                        <td
                                                            style={{
                                                                fontSize: "18px",
                                                                letterSpacing: "0.02rem",
                                                                color: "#ffc107",
                                                            }}>
                                                            Alarme
                                                        </td>
                                                    ) : tracker.status !== 1 ? (
                                                        <td
                                                            style={{
                                                                fontSize: "18px",
                                                                letterSpacing: "0.02rem",
                                                                color: "#D40707",
                                                            }}>
                                                            Falha
                                                        </td>
                                                    ) :
                                                        (
                                                            <td
                                                                style={{
                                                                    fontSize: "18px",
                                                                    letterSpacing: "0.02rem",
                                                                    color: "#049B5F",
                                                                }}>
                                                                Tracking
                                                            </td>
                                                        )}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </Container>
                        {/* <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                // justifyContent: "space-evenly",
                                flexWrap: "wrap",
                                mb: 8,
                            }}
                        >
                            {trackers.map((tracker, index) => (
                                <Box
                                    key={index}
                                    // sm={1}
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        // justifyContent: "space-evenly",
                                        // m: 4,
                                        padding: 6,
                                        border: 1,
                                    }}
                                >
                                    <div
                                        style={{
                                            borderBottom: "1px solid #A5AAB6",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                width: "110px",
                                                marginBottom: 4,
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <div>
                                                <img
                                                    src={grid_icon}
                                                    style={{
                                                        width: "22px",
                                                    }}
                                                />
                                            </div>
                                            <Box display={"flex"} alignItems={"center"}>
                                                {responseTimeHandle(tracker?.data) >= 20 ? (
                                                    <>
                                                        <span
                                                            style={{
                                                                fontWeight: 700,
                                                                fontSize: "18px",
                                                                letterSpacing: "0.02rem",
                                                                color: "#ff9233",
                                                            }}
                                                        >
                                                            Offline
                                                        </span>
                                                        <CloudOffOutlinedIcon style={{ color: "#ff9233" }} />
                                                    </>
                                                ) : tracker?.status === 1 ? (
                                                    <>
                                                        <span
                                                            style={{
                                                                fontWeight: 700,
                                                                fontSize: "18px",
                                                                letterSpacing: "0.02rem",
                                                                color: "#049B5F",
                                                            }}
                                                        >
                                                            OK
                                                        </span>
                                                        <CheckCircleOutlineIcon
                                                            style={{ color: "#049B5F" }}
                                                        />
                                                    </>
                                                ) : (
                                                    <>
                                                        <span
                                                            style={{
                                                                fontWeight: 700,
                                                                fontSize: "18px",
                                                                letterSpacing: "0.02rem",
                                                                color: "#D40707",
                                                            }}
                                                        >
                                                            Falha
                                                        </span>
                                                        <CancelOutlinedIcon
                                                            style={{ color: "#D40707" }}
                                                        />
                                                    </>
                                                )}
                                            </Box>
                                        </div>
                                        <span
                                            style={{
                                                fontWeight: 700,
                                                color: responseTimeHandle(tracker?.data) >= 20 ? "#4E596B" : tracker?.status === 1 ? "#4E596B" : "#D40707",

                                            }}
                                        >
                                            {`tracker ${tracker?.cod}`.toString().padStart(2, "0")}{" "}
                                        </span>
                                    </div>
                                    <span
                                        style={{
                                            fontWeight: 700,
                                            fontSize: "12px",
                                            letterSpacing: "0.02rem",
                                            color: responseTimeHandle(tracker?.data) >= 20 ? "#A5AAB6" : tracker?.status === 1 ? "#A5AAB6" : "#D40707",
                                        }}
                                    >
                                        {Math.abs(parseFloat(tracker?.angulo_real.toFixed(2)))}º
                                        {tracker?.angulo_real < 0 ? (
                                            <span> Leste</span>
                                        ) : tracker?.angulo_real > 0 ? (
                                            <span> Oeste</span>
                                        ) : (
                                            ""
                                        )}
                                    </span>
                                </Box>
                            ))}
                        </Box> */}

                    </>
                </Paper>
            ) : (
                <h5>Não existem dados para serem disponibilizados</h5>
            )}
        </>
    );
}
