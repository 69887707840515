import axios from "axios";
import { getToken } from "./authService";

const api = axios.create({
    baseURL: "https://api.mv-toth.com",
    //baseURL: "http://localhost:3001",
});

api.interceptors.request.use(async (config) => {
    const token = getToken();
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

api.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response.status === 401) {
            window.location = "/login";
        }
        throw error;
    }
);

export default api;
