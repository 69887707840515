import styled from "styled-components";

export const ReleStatus = styled.span`
    height: 36px;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 0.02em;

    color: #888888;
`;
