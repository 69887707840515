import {
    Paper,
    CircularProgress,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
} from "@mui/material";
import { Box } from "@mui/system";
import WbSunnyOutlinedIcon from "@mui/icons-material/WbSunnyOutlined";
import React, { useEffect, useState } from "react";
import { ImageBox, ItemBox, SubTextMet, TextMet, TitleMet } from "../styles/EstacaoStyles";
import termometro from "./../assets/termometro.png";
import vento from "../assets/vento.png";
import umidade from "../assets/umidade.png";
import sol from "../assets/sol.png";
import * as estacaoService from "../services/estacoesService";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Col, Row } from "react-bootstrap";
import GraficoTemperatura from "../components/GraficoTemperatura";
import moment from "moment";
import GraficoVento from "../components/GraficoVento";
import GraficoUmidade from "../components/GraficoUmidade";
import GraficoIrradiancia from "../components/GraficoIrradiancia";
import { SiGooglesheets, SiJson, SiMicrosoftexcel } from "react-icons/si";
import { useSnackbar } from "notistack";
import { downloadFile, converteParaHorarioLocal } from "../utils/utils";
import LoadingSpin from "react-loading-spin";

export default function EstacaoMetereologica() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [lastDataEstacao, setLastDataEstacao] = useState({});
    const [dataEstacao, setDataEstacao] = useState([]);
    const [isOpenExportRelatorio, setIsOpenExportRelatorio] = useState(false);
    const [isLoadingExportRelatorio, setIsLoadingExportRelatorio] = useState(false);
    const [relatorioType, setRelatorioType] = useState("");
    const [outDated, setOutDated] = useState(false);
    const { usinaId } = useParams();
    const [date, setDate] = useState({
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        day: new Date().getDate(),
    });
    const { enqueueSnackbar } = useSnackbar();

    const getDadosEstacao = async () => {
        try {
            let intervaloDTO = {
                startDate: "",
                endDate: "",
            };

            intervaloDTO.startDate = moment(
                new Date(date.year, date.month - 1, date.day, 0, 0, 0, 0)
            ).format("YYYY-MM-DDTHH:mm");

            intervaloDTO.endDate = moment(
                new Date(date.year, date.month - 1, date.day, 23, 59, 59)
            ).format("YYYY-MM-DDTHH:mm");

            setLoading(true);
            const response = await estacaoService.findLastRegister(usinaId);
            setLastDataEstacao(response);
            setLoading(false);

            const [lastData, estacaoData] = await Promise.all([
                estacaoService.findLastRegister(usinaId),
                estacaoService.findBetweenDatesEstacao({
                    usinaId,
                    intervaloDTO,
                }),
            ]);
            moment(new Date()).diff(moment(
                new Date(converteParaHorarioLocal(lastData.data))
            ), 'days') >= 1 ? setOutDated(true) : setOutDated(false)

            setLastDataEstacao(lastData);
            setDataEstacao(estacaoData);
        } catch (error) {
            setLoading(false);
            console.log(
                "🚀 ~ file: EstacaoMetereologica.jsx ~ line 21 ~ getDadosEstacao ~ error",
                error
            );
        }
    };

    useEffect(() => {
        getDadosEstacao();

        let counter = 0;
        dispatch({ type: "SET_COUNTER", value: counter });

        const intervalId = setInterval(() => {
            counter++;
            dispatch({ type: "SET_COUNTER", value: counter });
            if (counter === 60) {
                // atualização a cada 1 minuto
                getDadosEstacao();
                counter = 0;
                dispatch({ type: "SET_COUNTER", value: counter });
            }
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    const handleExportRelatorio = async ({ reportFormat }) => {
        try {
            setIsLoadingExportRelatorio(true);
            let intervaloDTO = {
                startDate: "",
                endDate: "",
            };
            intervaloDTO.startDate = moment(
                new Date(date.year, date.month - 1, date.day, 0, 0, 0, 0)
            ).format("YYYY-MM-DDTHH:mm");

            intervaloDTO.endDate = moment(
                new Date(date.year, date.month - 1, date.day, 23, 59, 59)
            ).format("YYYY-MM-DDTHH:mm");

            const responseRelatorio = await estacaoService.exportRelatorioEstacao({
                usinaId,
                intervaloDTO,
                reportTypeDto: {
                    reportType: relatorioType,
                    reportFormat,
                },
            });

            let fileName = "";
            if (relatorioType == "Temperatura") fileName = "temperatura";
            if (relatorioType == "Vento") fileName = "vento";
            if (relatorioType == "Umidade") fileName = "umidade";
            if (relatorioType == "Irradiancia") fileName = "irradiancia";

            const formattedDate = `${date.day}-${date.month}-${date.year}`;
            downloadFile({
                data:
                    reportFormat == "JSON" ? JSON.stringify(responseRelatorio) : responseRelatorio,
                fileName: `relatorio-${fileName}-${formattedDate}.${reportFormat.toLowerCase()}`,
                fileType: `text/${reportFormat.toLowerCase()}`,
            });
            setIsLoadingExportRelatorio(false);
        } catch (error) {
            setIsLoadingExportRelatorio(false);
            enqueueSnackbar("Ocorreu um ao gerar o relatório!", { variant: "error" });
            console.log(
                "🚀 ~ file: Usinas.jsx ~ line 127 ~ handleExportRelatorioGeracaoDiaria ~ error",
                error
            );
        }
    };

    return (
        <>
            {loading ? (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "75vh",
                    }}
                >
                    <CircularProgress />
                </Box>
            ) : (
                <Paper style={{ minHeight: " 90vh", padding: "38px" }} elevation={3}>
                    <>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                mb: 8,
                            }}
                        >
                            <WbSunnyOutlinedIcon />{" "}
                            <b style={{ fontSize: "20px", marginLeft: 8, marginTop: -3 }}>
                                Estação metereológica
                            </b>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-evenly",
                                mb: 8,
                            }}
                        >
                            <ItemBox>
                                <ImageBox>
                                    <img src={termometro} alt="" style={{ width: "36px" }} />
                                </ImageBox>
                                <TitleMet className="text-center">Temperatura Módulo</TitleMet>
                                <TextMet>
                                    {!outDated ? parseFloat(
                                        lastDataEstacao?.estacaoTemperaturaMods?.find(
                                            (temperatura) => temperatura.cod == 1
                                        )?.valor
                                    ).toFixed(2) : '-'}{" "}
                                    <SubTextMet>°C</SubTextMet>
                                </TextMet>
                            </ItemBox>
                            <ItemBox>
                                <ImageBox>
                                    <img src={termometro} alt="" style={{ width: "36px" }} />
                                </ImageBox>
                                <TitleMet>Temperatura</TitleMet>
                                <TextMet>
                                    {!outDated ? parseFloat(lastDataEstacao.temperatura_ar).toFixed(2) : '-'}{" "}
                                    <SubTextMet>°C</SubTextMet>
                                </TextMet>
                            </ItemBox>
                            <ItemBox>
                                <ImageBox>
                                    <img src={sol} alt="" style={{ width: "36px" }} />
                                </ImageBox>
                                <TitleMet className="text-center">Irradiação Horizontal</TitleMet>
                                <TextMet>
                                    {!outDated ? parseFloat(
                                        lastDataEstacao?.estacaoDnis?.find(
                                            (dni) => dni.cod == 3
                                        )?.valor
                                    ).toFixed(2) : '-'}{" "}
                                    <SubTextMet>W/m²</SubTextMet>
                                </TextMet>
                            </ItemBox>
                            <ItemBox>
                                <ImageBox>
                                    <img src={sol} alt="" style={{ width: "36px" }} />
                                </ImageBox>
                                <TitleMet className="text-center">Irradiação Inclinada</TitleMet>
                                <TextMet>
                                    {!outDated ? parseFloat(
                                        lastDataEstacao?.estacaoDnis?.find(
                                            (dni) => dni.cod == 1
                                        )?.valor
                                    ).toFixed(2) : '-'}{" "}
                                    <SubTextMet>W/m²</SubTextMet>
                                </TextMet>
                            </ItemBox>
                            <ItemBox>
                                <ImageBox>
                                    <img src={sol} alt="" style={{ width: "36px" }} />
                                </ImageBox>
                                <TitleMet className="text-center">Irradiação Refletida</TitleMet>
                                <TextMet>
                                    {!outDated ? parseFloat(
                                        lastDataEstacao?.estacaoDnis?.find(
                                            (dni) => dni.cod == 2
                                        )?.valor
                                    ).toFixed(2) : '-'}{" "}
                                    <SubTextMet>W/m²</SubTextMet>
                                </TextMet>
                            </ItemBox>
                            <ItemBox>
                                <ImageBox>
                                    <img src={vento} alt="" style={{ width: "36px" }} />
                                </ImageBox>
                                <TitleMet>Vento</TitleMet>
                                <TextMet>
                                    {!outDated ? parseFloat(lastDataEstacao.vento).toFixed(2) : '-'}{" "}
                                    <SubTextMet>m/s</SubTextMet>
                                </TextMet>
                            </ItemBox>
                            <ItemBox>
                                <ImageBox>
                                    <img src={umidade} alt="" style={{ width: "36px" }} />
                                </ImageBox>
                                <TitleMet>Umidade</TitleMet>
                                <TextMet>
                                    {!outDated ? parseFloat(lastDataEstacao.umidade).toFixed(2) : '-'}{" "}
                                    <SubTextMet>%</SubTextMet>
                                </TextMet>
                            </ItemBox>
                        </Box>
                        <Row>
                            <Col md={12} lg={6} style={{ textAlign: "center" }}>
                                <b style={{ fontSize: "20px" }}>Temperatura (°C) x Tempo</b>{" "}
                                <Button
                                    variant="contained"
                                    style={{
                                        padding: "0 14px",
                                        color: "white",
                                        backgroundColor: "#0a5068",
                                        textTransform: "none",
                                        marginLeft: "20px",
                                    }}
                                    onClick={() => {
                                        setIsOpenExportRelatorio(true);
                                        setRelatorioType("Temperatura");
                                    }}
                                >
                                    Exportar relatório
                                </Button>
                                <div style={{ width: "100%" }}>
                                    <GraficoTemperatura dataEstacao={dataEstacao} date={date} />
                                </div>
                            </Col>
                            <Col md={12} lg={6} style={{ textAlign: "center" }}>
                                <b style={{ fontSize: "20px" }}>Vento (m/s) x Tempo</b>{" "}
                                <Button
                                    variant="contained"
                                    style={{
                                        padding: "0 14px",
                                        color: "white",
                                        backgroundColor: "#0a5068",
                                        textTransform: "none",
                                        marginLeft: "20px",
                                    }}
                                    onClick={() => {
                                        setIsOpenExportRelatorio(true);
                                        setRelatorioType("Vento");
                                    }}
                                >
                                    Exportar relatório
                                </Button>
                                <div style={{ width: "100%" }}>
                                    <GraficoVento dataEstacao={dataEstacao} date={date} />
                                </div>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: 64 }}>
                            <Col md={12} lg={6} style={{ textAlign: "center" }}>
                                <b style={{ fontSize: "20px" }}>Umidade (%) x Tempo</b>{" "}
                                <Button
                                    variant="contained"
                                    style={{
                                        padding: "0 14px",
                                        color: "white",
                                        backgroundColor: "#0a5068",
                                        textTransform: "none",
                                        marginLeft: "20px",
                                    }}
                                    onClick={() => {
                                        setIsOpenExportRelatorio(true);
                                        setRelatorioType("Umidade");
                                    }}
                                >
                                    Exportar relatório
                                </Button>
                                <div style={{ width: "100%" }}>
                                    <GraficoUmidade dataEstacao={dataEstacao} date={date} />
                                </div>
                            </Col>
                            <Col md={12} lg={6} style={{ textAlign: "center" }}>
                                <b style={{ fontSize: "20px" }}> Irradiância (W/m²) x Tempo</b>{" "}
                                <Button
                                    variant="contained"
                                    style={{
                                        padding: "0 14px",
                                        color: "white",
                                        backgroundColor: "#0a5068",
                                        textTransform: "none",
                                        marginLeft: "20px",
                                    }}
                                    onClick={() => {
                                        setIsOpenExportRelatorio(true);
                                        setRelatorioType("Irradiancia");
                                    }}
                                >
                                    Exportar relatório
                                </Button>
                                <div style={{ width: "100%" }}>
                                    <GraficoIrradiancia dataEstacao={dataEstacao} date={date} />
                                </div>
                            </Col>
                        </Row>
                        <Dialog
                            maxWidth="sm"
                            fullWidth
                            disableScrollLock={true}
                            open={isOpenExportRelatorio}
                            onClose={() => setIsOpenExportRelatorio(false)}
                        >
                            <DialogTitle style={{ color: "#0a5068" }}>
                                Exportar relatório de <b>{relatorioType}</b>
                            </DialogTitle>
                            <DialogContent>
                                {isLoadingExportRelatorio ? (
                                    <>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignContent: "center",
                                            }}
                                        >
                                            <LoadingSpin
                                                size="16px"
                                                width="2px"
                                                primaryColor={"#2e64a8"}
                                            />
                                        </Box>
                                    </>
                                ) : (
                                    <>
                                        <Divider
                                            variant="middle"
                                            sx={{ mb: 4, color: "#0a5068", padding: "0.5px" }}
                                        />
                                        <Box
                                            sx={{ display: "flex", justifyContent: "space-evenly" }}
                                        >
                                            <Button
                                                variant="contained"
                                                style={{
                                                    color: "white",
                                                    backgroundColor: "#0a5068",
                                                    textTransform: "none",
                                                }}
                                                onClick={() =>
                                                    handleExportRelatorio({
                                                        reportFormat: "CSV",
                                                    })
                                                }
                                            >
                                                <b>CSV</b>{" "}
                                                <SiGooglesheets
                                                    style={{ color: "white", marginLeft: 6 }}
                                                />
                                            </Button>
                                            <Button
                                                variant="contained"
                                                style={{
                                                    color: "white",
                                                    backgroundColor: "#0a5068",
                                                    textTransform: "none",
                                                }}
                                                onClick={() =>
                                                    handleExportRelatorio({
                                                        reportFormat: "XLSX",
                                                    })
                                                }
                                            >
                                                <b>XLSX</b>{" "}
                                                <SiMicrosoftexcel
                                                    style={{ color: "white", marginLeft: 6 }}
                                                />
                                            </Button>

                                            <Button
                                                variant="contained"
                                                style={{
                                                    color: "white",
                                                    backgroundColor: "#0a5068",
                                                    textTransform: "none",
                                                }}
                                                onClick={() =>
                                                    handleExportRelatorio({
                                                        reportFormat: "JSON",
                                                    })
                                                }
                                            >
                                                <b>JSON</b>{" "}
                                                <SiJson style={{ color: "white", marginLeft: 6 }} />
                                            </Button>
                                        </Box>
                                    </>
                                )}
                            </DialogContent>
                            <DialogActions style={{ color: "#0a5068" }}>
                                <Button
                                    style={{ color: "#0a5068" }}
                                    onClick={() => setIsOpenExportRelatorio(false)}
                                >
                                    Fechar
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </>
                </Paper>
            )}
        </>
    );
}
