import { Paper, Box, CircularProgress } from "@mui/material";
import React, { useRef, useEffect, useState } from 'react';
import BarraSuperior from "../components/BarraSuperior";
import moment from "moment";
import { useParams } from "react-router-dom";
import tracker from "../assets/tracker.png";
import * as alarmeService from "../services/alarmeService";
import { useDispatch } from "react-redux";
import { Col, Row, Container } from "react-bootstrap"
import SquareIcon from '@mui/icons-material/Square';
import * as EquipamentsServices from "../services/equipamentsService";


export default function CommunicationMap() {
    const date = {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        day: new Date().getDate(),
    };
    const equipamentMap = {
        clp_01: 12,
        clp_02: 9,
        smartlogger_01: 8,
        waether_station_01: 6,
        ncu_trackers_01: 7,
        energy_meter_01: 10,
        relay_01: 11,
        nobreak_01: 4,
        router: 2,
        internet: 5,
        scada: 3

    }

    const { usinaId } = useParams();
    const iframeRef = useRef(null);
    const [html, setHtmlContent] = useState()
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    async function getData() {
        const response = await fetch('https://mvtoth-files.s3.amazonaws.com/communication_map.txt');
        const data = await response.text();
        setHtmlContent(data);
    }

    async function updatePage() {
        return "string"
    }

    function convertEquipamentsStatusToFault(equipaments) {
        var result = []
        equipaments.forEach((equipament) => {
            const equipamentDate = moment.utc(equipament?.updated_at)
            const minutesDataDiff = moment.utc().diff(equipamentDate, 'minutes')

            //TODO: REMOVE THIS CONDITIONAL
            if (minutesDataDiff >= 20) {
                result.push(
                    { id: equipamentMap["router"] },
                    { id: equipamentMap["scada"] },
                    { id: equipamentMap["internet"] }
                )
            }

            if (!equipament.status || minutesDataDiff >= 20) {
                const equipamentKey = equipament.equipament.toString() + "_" + equipament.cod.toLocaleString('en-US', {
                    minimumIntegerDigits: 2,
                    useGrouping: false
                })
                result.push({ id: equipamentMap[equipamentKey] })
            }
        })
        return result
    }

    useEffect(async () => {
        setLoading(true);

        await getData()

        const equipamentStatus = await EquipamentsServices.getEquipamentsStatus(usinaId)

        const equipamentsFaultArray = convertEquipamentsStatusToFault(equipamentStatus)

        setLoading(false);

        let intervaloDTO = {
            startDate: "",
            endDate: "",
        };

        intervaloDTO.startDate = moment(
            new Date(date.year, date.month - 1, date.day, 0, 0, 0, 0)
        ).format("YYYY-MM-DDTHH:mm");

        intervaloDTO.endDate = moment(
            new Date(date.year, date.month - 1, date.day, 23, 59, 59)
        ).format("YYYY-MM-DDTHH:mm");

        let counter = 0;

        processarResposta(equipamentsFaultArray)

        const intervalId = setInterval(() => {
            counter++;
            if (counter === 60) {
                updatePage();
                counter = 0;
            }
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    // Função para processar a resposta e adicionar classes
    async function processarResposta(equipments) {
        equipments.forEach(equipment => {
            const elemento = document.getElementById(`id${equipment.id}`);
            if (elemento) {
                elemento.classList.add('red');
            }
        });
    }


    let CommunicationMap = (
        <>
            {loading ? (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "75vh",
                    }}
                >
                    <CircularProgress />
                </Box>
            ) : (<>
                <Paper
                    style={{ minHeight: " 90vh", padding: "15px" }} elevation={3}
                >
                    <Container >
                        <Row className="mb-3">
                            <Col className="pt-2 pb-sm-3" >
                                <img src={tracker} alt="" style={{ marginRight: "5px" }} />

                                <b style={{ fontSize: "20px", marginLeft: 8, marginTop: -3 }}>
                                    Equipamentos
                                </b>
                            </Col>
                            <Col md={5}>
                                <Paper style={{ backgroundColor: "#fafdf5" }}>
                                    <SquareIcon sx={{ color: "green" }} /> Equipamento OK <br />
                                    <SquareIcon sx={{ color: "red" }} /> Equipamento sem comunicação
                                </Paper>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div
                                    className="text-center"
                                    dangerouslySetInnerHTML={{ __html: html }}
                                />
                            </Col>
                        </Row>
                    </Container>
                </Paper>
            </>)
            }
        </>
    )

    return CommunicationMap

}