import React from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    Title,
    Tooltip,
    Legend,
    BarElement,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import "chartjs-adapter-moment";
import moment from "moment";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function GraficoEnergiaMensal({ energiaMensal, onBarClick }) {
    const options = {
        locale: 'pt-br',
        responsive: true,
        plugins: {
            legend: {
                position: "top",
            },
            title: {
                display: false,
                text: "Chart.js Bar Chart",
            },
        },
        onClick: handleBarClick
    };
    const data = {
        labels: getLast12Months(),
        datasets: [
            {

                label: "Energia mensal",
                data: energiaMensal.map((i) => parseFloat(i / 1000).toFixed(2)),
                borderColor: "rgb(255, 0, 0)",
                backgroundColor: "rgb(255, 0, 0, 0.5)",
            },
        ]
    };

    function handleBarClick(event, elements) {
        if (elements.length > 0) {
            const clickedIndex = elements[0].index;
            const clickedDate = data.labels[clickedIndex];
            onBarClick(clickedDate);
        }
    }

    return <Bar options={options} data={data} />;
}

const getLast12Months = () => {
    const actualMonth = moment().month();
    const actualYear = moment().year();

    const months = [
        { label: "Jan", id: 0 },
        { label: "Fev", id: 1 },
        { label: "Mar", id: 2 },
        { label: "Abr", id: 3 },
        { label: "Mai", id: 4 },
        { label: "Jun", id: 5 },
        { label: "Jul", id: 6 },
        { label: "Ago", id: 7 },
        { label: "Set", id: 8 },
        { label: "Out", id: 9 },
        { label: "Nov", id: 10 },
        { label: "Dez", id: 11 },
    ];

    const orderedMonths = [];
    let counter = 11;
    for (let i = 0; i < 12; i++) {
        const conditionalId = actualMonth - i < 0 ? counter : actualMonth - i;
        actualMonth - i < 0 && counter--;
        orderedMonths.push(
            `${months.find((m) => m.id === conditionalId)?.label}/${actualMonth - i < 0 ? actualYear - 1 : actualYear
            }`
        );
    }
    return orderedMonths.reverse();
};
