import { Paper, Box, CircularProgress } from "@mui/material";
import React, { useRef, useEffect, useState } from 'react';
import BarraSuperior from "../components/BarraSuperior";
import moment from "moment";
import { useParams } from "react-router-dom";
import tracker from "../assets/tracker.png";
import * as alarmeService from "../services/alarmeService";
import { useDispatch } from "react-redux";
import { Col, Row, Container } from "react-bootstrap"
import SquareIcon from '@mui/icons-material/Square';
import * as trackerService from "../services/trackerService";
import * as usinaService from "../services/usinaService";

const filters = {
    nome: "",
    usina: "39",
    tipo_equipamento: "tracker",
    status: "ativo",
    data_inicio: null,
    data_fim: null,
};

export default function TrackerMap() {
    const [energiaMedidor, setEnergiaMedidor] = useState([]);
    const [totalizadores, setTotalizadores] = useState({});
    const date = {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        day: new Date().getDate(),
    };
    const { usinaId } = useParams();
    const iframeRef = useRef(null);
    const [html, setHtmlContent] = useState()
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    async function getData() {
        const response = await fetch('https://mvtoth-files.s3.amazonaws.com/api_mv.txt');
        const data = await response.text();
        setHtmlContent(data);

        const alarmes = await alarmeService.getAllAlarmsWithoutPagination({
            filters: filters,
            currentPage: 0,
            itemsPerPage: 100
        });


        return alarmes.alarmes
    }

    async function updatePage() {
        const alarmes = await getData()
        const trackers = await trackerService.buscarUltimosRegistrosTrackers(usinaId);

        processarResposta(alarmes, trackers);
    }

    useEffect(async () => {
        setLoading(true);

        let intervaloDTO = {
            startDate: "",
            endDate: "",
        };

        intervaloDTO.startDate = moment(
            new Date(date.year, date.month - 1, date.day, 0, 0, 0, 0)
        ).format("YYYY-MM-DDTHH:mm");

        intervaloDTO.endDate = moment(
            new Date(date.year, date.month - 1, date.day, 23, 59, 59)
        ).format("YYYY-MM-DDTHH:mm");

        const [energiaMedidorData, totalizadorData] =
            await Promise.all([
                usinaService.getEnergiaMedidor({ usinaId, intervaloDTO }),
                usinaService.getUsinaTotalizador(usinaId)
            ]);
        setTotalizadores(totalizadorData);
        setEnergiaMedidor(energiaMedidorData);

        const alarmes = await getData()
        const trackers = await trackerService.buscarUltimosRegistrosTrackers(usinaId);

        setLoading(false);

        processarResposta(alarmes, trackers);

        let counter = 0;

        const intervalId = setInterval(() => {
            counter++;
            if (counter === 60) {
                updatePage();
                counter = 0;
            }
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    function setTrackerFault(cod, description, element) {
        element.classList.add('vermelho');
        element.setAttribute('title', `Tracker ${cod}`);

        // Adiciona o evento de mouseover para exibir o título
        element.addEventListener('mouseover', () => {
            const tooltip = document.createElement('div');
            tooltip.textContent = `Tracker ${cod} - ${description}`;
            tooltip.style.position = 'absolute';
            tooltip.style.backgroundColor = 'white';
            tooltip.style.border = '1px solid black';
            tooltip.style.padding = '5px';
            tooltip.style.zIndex = '1000';

            const rect = element.getBoundingClientRect();
            tooltip.style.left = `${rect.left}px`;
            tooltip.style.top = `${rect.bottom + 5}px`;

            document.body.appendChild(tooltip);

            element.addEventListener('mouseout', () => {
                document.body.removeChild(tooltip);
            }, { once: true });
        });
    }

    // Função para processar a resposta e adicionar classes
    async function processarResposta(alarmes, trackers) {
        alarmes.forEach(alarme => {
            if (
                alarme?.tracker?.cod &&
                alarme?.alarmeTipo?.descricao != "Sobrecorrente na saída 24V"
            ) {
                const elemento = document.getElementById(`trk-${String(alarme.tracker.cod).padStart(2, "0")}`);
                if (elemento) {
                    setTrackerFault(alarme.tracker.cod, alarme?.alarmeTipo?.descricao, elemento)
                }
            }
        });

        trackers.forEach(tracker => {
            if (tracker?.status == 0) {
                const elemento = document.getElementById(`trk-${String(tracker?.cod).padStart(2, "0")}`);
                if (elemento) {
                    setTrackerFault(tracker?.cod, "FALHA", elemento)
                }
            }
        });
    }


    let trackerMap = (
        <>
            {loading ? (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "75vh",
                    }}
                >
                    <CircularProgress />
                </Box>
            ) : (<>
                <Paper
                    style={{ minHeight: " 90vh", padding: "38px" }} elevation={3}
                >

                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            mb: 8,
                        }}
                    >
                        <img src={tracker} alt="" style={{ marginRight: "5px" }} />

                        <b style={{ fontSize: "20px", marginLeft: 8, marginTop: -3 }}>
                            Trackers
                        </b>
                    </Box>
                    <Container>
                        <Row>
                            <Col md={5}>
                                <Paper style={{ backgroundColor: "#fafdf5" }}>
                                    <SquareIcon sx={{ color: "green" }} /> Tracker OK <br />
                                    <SquareIcon sx={{ color: "red" }} /> Tracker com ao menos 1 alarme ativo
                                </Paper>
                            </Col>
                        </Row>
                    </Container>

                    <div
                        className="text-center"
                        dangerouslySetInnerHTML={{ __html: html }}
                    />
                </Paper>
            </>)
            }
        </>
    )

    return trackerMap

}