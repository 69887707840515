/* eslint-disable @typescript-eslint/no-empty-function */
import React from "react";
import { FormControl, Input, InputLabel } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { sendRecoveryTokenInEmail } from "../services/recoverPassword";
import { Container } from "../styles/LoginStyle";
import logo_mvp from "../assets/logo_mvp.png";

const CustomButtonBack = styled(Button)({
    color: "#fff",
    backgroundColor: "gray",
    borderRadius: 28,
    padding: 5,
    paddingLeft: 25,
    paddingRight: 25,
    fontWeight: "bold",
    "&:hover": {
        backgroundColor: "#888888",
    },
});

const CustomButtonContinue = styled(Button)({
    color: "white",
    backgroundColor: "#232b2b",
    borderRadius: 28,
    padding: 5,
    paddingLeft: 25,
    paddingRight: 25,
    fontWeight: "bold",
    "&:hover": {
        backgroundColor: "#000",
    },
});

export default function RecoverPassword() {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);

    const handleRecoverPassword = async (event) => {
        try {
            event.preventDefault();
            setLoading(true);
            await sendRecoveryTokenInEmail(email);
            enqueueSnackbar("Token enviado com sucesso para seu email!", { variant: "success" });
            setLoading(false);
            navigate("/insert_token", { state: { email } });
        } catch (error) {
            enqueueSnackbar("E-mail não encontrado!", { variant: "error" });
            setLoading(false);
        }
    };

    return (
        <Container
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Box
                width="600px"
                sx={{
                    display: "flex",
                    gridTemplateColumns: "repeat(1, 2fr)",
                    gridTemplateRows: "auto",
                    gridTemplateAreas: `"main"`,
                }}
            >
                <form onSubmit={handleRecoverPassword}>
                    <Box width="100%">
                        <Box
                            sx={{
                                width: "100%",
                                marginTop: "14px",
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <span
                                style={{
                                    marginLeft: "10px",
                                    color: "#d7dae0",
                                    fontSize: 16,
                                }}
                            >
                                Digite seu email para recuperar sua senha
                            </span>
                        </Box>

                        <FormControl sx={{ m: 1, mt: 3, width: "100%" }} variant="standard">
                            <InputLabel htmlFor="email-input">Email</InputLabel>
                            <Input
                                id="email-input"
                                value={email}
                                inputProps={{ color: "white" }}
                                onChange={(evt) => setEmail(evt.target.value)}
                            />
                        </FormControl>

                        <Box
                            sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <CustomButtonBack
                                style={{
                                    marginTop: "36px",
                                }}
                                variant="contained"
                                color="primary"
                                size="large"
                                type="button"
                                onClick={() => navigate("/login")}
                            >
                                Voltar
                            </CustomButtonBack>

                            <CustomButtonContinue
                                style={{
                                    marginTop: "36px",
                                }}
                                variant="contained"
                                color="primary"
                                size="large"
                                type="submit"
                                disabled={loading}
                            >
                                Continuar
                            </CustomButtonContinue>
                        </Box>
                    </Box>
                </form>
            </Box>
            <Box>
                <img
                    src={logo_mvp}
                    alt="logo"
                    style={{
                        width: "200px",
                        margin: "auto",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                />
                <figcaption
                    style={{
                        color: "white",
                        fontStyle: "italic",
                        padding: "2px",
                        textAlign: "center",
                    }}
                >
                    Soluções de energia fotovoltaica
                </figcaption>
            </Box>
        </Container>
    );
}
