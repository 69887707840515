import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import * as usinaService from "../services/usinaService";

export const SelectUsina = ({ value, onChange, width }) => {
    const [loadingUsinas, setLoadingUsinas] = useState(false);
    const [usinas, setUsinas] = useState([]);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        getAllUsinas();
    }, []);

    const getAllUsinas = async () => {
        try {
            setLoadingUsinas(true);
            const response = await usinaService.getAllUsinas();
            setUsinas(response);
            setLoadingUsinas(false);
        } catch (error) {
            enqueueSnackbar("Ocorreu um erro ao buscar os usuários!", { variant: "error" });
            setLoadingUsinas(false);
        }
    };

    return (
        <FormControl style={{ width: width ? `${width}%` : "20%" }}>
            <InputLabel>Usina</InputLabel>
            <Select value={value} onChange={onChange} label="Usina" disabled={loadingUsinas}>
                <MenuItem value={""}>
                    <em>Selecione</em>
                </MenuItem>
                {usinas.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                        {item.nome}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};
