import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    TimeScale,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import "chartjs-adapter-moment";
import moment from "moment";
import { converteParaHorarioLocal } from "../utils/utils";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    TimeScale,
    Title,
    Tooltip,
    Legend
);

export default function GraficoGeracaoMensal({ className, geracaoMensal, date }) {


    const options = {
        locale: 'pt-br',
        responsive: true,
        plugins: {

            title: {
                display: true,
                text: 'Energia Mensal da Usina',
                font: {
                    size: 16
                }
            }
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                type: "time",
                time: {
                    unit: "day",
                    displayFormats: {
                        day: "DD",
                    },
                },
                minUnit: 'day',
                min: moment(new Date(
                    date.year(),
                    date.month(),
                    1)).format('YYYY-MM-DD'),
                max: moment(new Date(
                    date.year(),
                    date.month() + 1,
                    0)).format('YYYY-MM-DD'),
                title: {
                    display: true,
                    text: "Dias",
                }
            },
            y: {
                title: {
                    display: true,
                    text: "Geracao (kW)",
                },
            },
        }

    };

    const data = {

        labels: geracaoMensal?.map((g) => (moment(g.date).add('days', 1).format('YYYY-MM-DD'))),
        datasets: [
            {
                label: "Energia",
                data: geracaoMensal?.map((g) => g.energy),
                backgroundColor: "rgb(0, 156, 119)",
            },
        ],
    };

    return <Bar className={className} options={options} data={data} />;
}
