import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    TimeScale,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { colors, converteParaHorarioLocal, getRandomColors, irradianciasMap } from "../utils/utils";
import { Line } from "react-chartjs-2";
import "chartjs-adapter-moment";
import moment from "moment";
import { inversorChartOptions } from "../utils/utils";
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    TimeScale,
    Title,
    Tooltip,
    Legend
);

export default function GenericChart({ selectLabel, randomColors, chartData, date, showIrradiation, irradiation }) {
    let options = {
        radius: 1, // desabilitar pontos
        locale: 'pt-br',
        responsive: true,
        scales: {
            x: {
                grid: {
                    display: false,
                },
                type: "time",
                time: {
                    unit: "hour",
                    displayFormats: {
                        hour: "HH:00",
                    },
                },
                min: moment(new Date(date.year, date.month - 1, date.day, 5, 0, 0)),
                max: moment(new Date(date.year, date.month - 1, date.day, 20, 0, 0)),
            },
            secondXAxis: {
                axis: 'x',
                labels: irradiation?.map((g) => converteParaHorarioLocal(g.data)),
                grid: {
                    display: false,
                },
                type: "time",
                display: false,
                min: moment(new Date(date.year, date.month - 1, date.day, 5, 0, 0)),
                max: moment(new Date(date.year, date.month - 1, date.day, 20, 0, 0)),
            },
            B: {
                type: "linear",
                position: "right",
                grid: {
                    display: false,
                },
                title: {
                    display: true,
                    text: `${showIrradiation ? "Irradiância (W/m²)" : ""}`,
                },
            },
        },
        tension: 0.4,
    };

    const plottedData = {
        labels: [],
        datasets: []
    };


    let colorIndex = 0
    let labelCounter = 0

    for (const equipament in chartData) {
        const variableObject = chartData[equipament];
        const equipamentName = inversorChartOptions[equipament].label
        for (const variable in variableObject) {
            const variableData = variableObject[variable]
            let equipamentLabel = {}
            let ylabels = {}
            let dataset = {}
            let labels = variableData?.map((d) => converteParaHorarioLocal(d?.date))

            variableData.forEach((item, index) => {

                if (!ylabels[`${equipament}-${variable}`]) {
                    ylabels[`${equipament}-${variable}`] = true
                    options.scales[`${equipament}-${variable}`] = createXaxisConfig(
                        `${inversorChartOptions[equipament][variable]} - ${equipament} ${item?.label}`,
                        randomColors[colorIndex].borderColor
                    )
                }
                if (!equipamentLabel[`${equipament}-${variable}-${item.label}`]) {
                    equipamentLabel[`${equipament}-${variable}-${item.label}`] = true

                    dataset[`${equipament}-${variable}-${item.label}`] = {}
                    dataset[`${equipament}-${variable}-${item.label}`]["label"] = `${inversorChartOptions[equipament][variable]} - ${equipamentName} ${item?.label}`
                    dataset[`${equipament}-${variable}-${item.label}`]["yAxisID"] = `${equipament}-${variable}`
                    dataset[`${equipament}-${variable}-${item.label}`]["borderColor"] = randomColors[colorIndex].borderColor
                    dataset[`${equipament}-${variable}-${item.label}`]["backgroundColor"] = randomColors[colorIndex].backgroundColor
                    dataset[`${equipament}-${variable}-${item.label}`]["xAxisID"] = "x"
                    dataset[`${equipament}-${variable}-${item.label}`]["fill"] = true
                    dataset[`${equipament}-${variable}-${item.label}`]["data"] = []
                    colorIndex++
                    labelCounter++
                }

                dataset[`${equipament}-${variable}-${item.label}`]["data"].push(item?.value)
            })


            for (const labelDataset in dataset) {
                plottedData.datasets.push(dataset[labelDataset]);
            }
            labels?.forEach((label) => {
                if (!plottedData.labels.includes(label)) {
                    plottedData.labels.push(label);
                }
            });
        }
    }

    if (showIrradiation) {
        irradiation[0]?.estacaoDnis?.forEach((item, index) => {
            plottedData.datasets.push({
                label: `Irradiância ${irradianciasMap[index + 1]}`,
                yAxisID: "B",
                xAxisID: "secondXAxis",
                fill: true,
                data: irradiation?.map(
                    (e) => e.estacaoDnis.find((item) => item.cod == index + 1)?.valor
                ),
                borderColor: colors[index].borderColor,
                backgroundColor: colors[index].backgroundColor,
                pointRadius: 1,

            });

        });
    }


    return <Line options={options} data={plottedData} />;
}

function createXaxisConfig(labelName, color) {
    return {
        type: "linear",
        position: "left",
        title: {
            color: color,
            display: true,
            text: `${labelName}`,

        },
        ticks: {
            color: color
        }
    }
}
