import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import L from "leaflet";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import PersonIcon from "@mui/icons-material/Person";
import { useDispatch } from "react-redux";
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    InputAdornment,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    FormControl,
    InputLabel,
    Tooltip,
    TableFooter,
    TablePagination,
    Chip,
    DialogContentText,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useRef, useState } from "react";
import { FaUserEdit } from "react-icons/fa";
import styled from "styled-components";
import * as usinaService from "../services/usinaService";
import { HeaderTitle } from "../styles/UsuariosStyle";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import markerIcon from "leaflet/dist/images/marker-icon.png";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useTheme } from "@mui/system";
import { MapBox } from "../styles/InicioStyles";
import { useAuth } from "../hooks/Auth";
import { roles } from "../constants";
const Input = styled("input")({
    display: "none",
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#0a5068",
        color: "#fff",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: "#e9ecef",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const markerIconConst = L.icon({
    iconUrl: markerIcon,
    iconRetinaUrl: markerIcon,
    iconAnchor: [5, 55],
    popupAnchor: [10, -44],
    iconSize: [25, 40],
});

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
                style={{ marginTop: -15 }}
            >
                {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
                style={{ marginTop: -15 }}
            >
                {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
                style={{ marginTop: -15 }}
            >
                {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
                style={{ marginTop: -15, marginRight: 8 }}
            >
                {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

const defaultUsina = {};

const defaultFilters = {
    nome: "",
    online: "",
};

const initialPosition = {
    lat: -15.876809064146757,
    lng: -49.39453125000001,
};

export default function ControleUsinas() {
    const dispatch = useDispatch();
    const [usina, setUsina] = useState(defaultUsina);
    const [isOpenAddUsina, setIsOpenAddUsina] = useState(false);
    const [isOpenDeleteUsina, setIsOpenDeleteUsina] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isVisualizar, setIsVisualizar] = useState(false);
    const [loading, setLoading] = useState(false);
    const [usinas, setUsinas] = useState([]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filters, setFilters] = useState(defaultFilters);
    const [position, setPosition] = useState(initialPosition);
    const [image, setImage] = useState(null);
    const { getUserPerfil } = useAuth();
    const markerRef = useRef(null);
    const userPerfil = getUserPerfil();
    const eventHandlers = useMemo(
        () => ({
            dragend() {
                const marker = markerRef.current;
                if (marker != null) {
                    const latLong = marker.getLatLng();
                    setPosition(latLong);
                }
            },
        }),
        [usina]
    );

    useEffect(() => {
        getLocalizacaoByLatLong();
    }, [position]);

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        getFilteredUsinas();

        let counter = 0;
        dispatch({ type: "SET_COUNTER", value: counter });

        const intervalId = setInterval(() => {
            counter++;
            dispatch({ type: "SET_COUNTER", value: counter });
            if (counter === 60) {
                // atualização a cada 1 minuto
                getFilteredUsinas();
                counter = 0;
                dispatch({ type: "SET_COUNTER", value: counter });
            }
        }, 1000);

        return () => clearInterval(intervalId);
    }, [page]);

    const getLocalizacaoByLatLong = async () => {
        try {
            if (position.lat && position.lng) {
                const { address } = await usinaService.getCidadeEstadoFromLatLong(
                    position.lat,
                    position.lng
                );

                const usinaLatLong = {
                    latitude: position.lat,
                    longitude: position.lng,
                };

                setUsina({
                    ...usina,
                    ...{
                        cidade: address?.city || address?.town,
                        estado: address?.state,
                        ...usinaLatLong,
                    },
                });
            }
        } catch (error) {
            console.log(
                "🚀 ~ file: ControleUsinas.jsx ~ line 232 ~ getLocalizacaoByLatLong ~ error",
                error
            );
        }
    };

    const getFilteredUsinas = async (limpar = false) => {
        try {
            setLoading(true);
            const { total, usinas } = await usinaService.getFilteredUsinas({
                filters: limpar ? defaultFilters : filters,
                currentPage: page,
            });
            setUsinas(usinas);
            setTotal(total);
            setLoading(false);
        } catch (error) {
            enqueueSnackbar("Ocorreu um erro ao buscar os usuários!", { variant: "error" });
            setLoading(false);
        }
    };

    const getUsina = async (userId) => {
        try {
            const response = await usinaService.getOneUsina(userId);
            setUsina(response);
            setPosition({ lat: response.latitude, lng: response.longitude });
        } catch (error) {
            enqueueSnackbar("Erro ao buscar usina", { variant: "error" });
        }
    };

    const handleOpenAddUsina = () => {
        setUsina(defaultUsina);
        setIsOpenAddUsina(true);
    };

    const handleOpenDeleteUsina = (selectedUsina) => {
        setUsina(selectedUsina);
        setIsOpenDeleteUsina(true);
    };

    const handleOpenEditUsina = async (userId) => {
        setIsEdit(true);
        await getUsina(userId);
        setIsOpenAddUsina(true);
    };

    const handleOpenVisualizeUsina = (selectedUsina) => {
        setUsina(selectedUsina);
        setIsVisualizar(true);
        setIsOpenAddUsina(true);
    };

    const handleCloseAddUsina = () => {
        setUsina(defaultUsina);
        setIsOpenAddUsina(false);
        setIsEdit(isEdit ? false : false);
        setImage(null);
        setIsVisualizar(null);
    };

    const handleAddUsina = async () => {
        try {
            const formData = new FormData();

            Object.keys(usina).forEach((field) => formData.append(field, usina[field]));

            await usinaService.addUsina(formData);

            enqueueSnackbar("Usina cadastrada com sucesso!", {
                variant: "success",
            });
            getFilteredUsinas();
            handleCloseAddUsina();
        } catch (error) {
            setLoading(false);
            if (error.response?.data?.message) {
                enqueueSnackbar(error.response?.data?.message, { variant: "error" });
            } else {
                enqueueSnackbar("Erro ao cadastrar a usina", { variant: "error" });
            }
        }
    };

    const handleEditUsina = async () => {
        try {
            const formData = new FormData();

            Object.keys(usina).forEach((field) => formData.append(field, usina[field]));

            await usinaService.editUsina({ usinaId: usina.id, usina: formData });
            enqueueSnackbar("Usina editada com sucesso!", {
                variant: "success",
            });
            getFilteredUsinas();
            handleCloseAddUsina();
        } catch (error) {
            setLoading(false);
            enqueueSnackbar("Erro ao editar a usina", { variant: "error" });
        }
    };

    const handleDeleteUsina = async () => {
        try {
            await usinaService.deleteUsina(usina.id);
            enqueueSnackbar("Usina deletada com sucesso!", {
                variant: "success",
            });
            getFilteredUsinas();
            setIsOpenDeleteUsina(false);
        } catch (error) {
            setLoading(false);
            enqueueSnackbar("Erro ao deletar a usina", { variant: "error" });
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const onFileChange = (event) => {
        setUsina({ ...usina, planta: event.target.files[0] });
        setImage(event.target.files[0]);
    };

    const onFileUpload = () => {
        // Create an object of formData
    };
    return (
        <>
            <Paper style={{ minHeight: " 90vh", padding: "38px" }} elevation={0}>
                <>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            mb: 8,
                        }}
                    >
                        <HeaderTitle>Controle de usinas</HeaderTitle>
                        {userPerfil === roles.ADMIN && (
                            <Button
                                variant="contained"
                                onClick={handleOpenAddUsina}
                                style={{ backgroundColor: "#0a5068" }}
                            >
                                Nova usina
                            </Button>
                        )}
                    </Box>
                    <Box sx={{ mb: 3, mt: -3 }}>
                        <HeaderTitle style={{ fontSize: 16 }}>Filtros</HeaderTitle>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "start",
                            mb: 4,
                        }}
                    >
                        <TextField
                            style={{ width: "20%", marginRight: 24 }}
                            id="filter-name"
                            label="Nome"
                            variant="outlined"
                            value={filters.nome}
                            onChange={(evt) => setFilters({ ...filters, nome: evt.target.value })}
                        />
                        <FormControl style={{ width: "20%", marginRight: 24 }}>
                            <InputLabel>Online</InputLabel>
                            <Select
                                value={filters.online}
                                onChange={(e) => setFilters({ ...filters, online: e.target.value })}
                                label="online"
                            >
                                <MenuItem value="">Selecione</MenuItem>
                                <MenuItem value={1}>Online</MenuItem>
                                <MenuItem value={0}>Offline</MenuItem>
                            </Select>
                        </FormControl>

                        <Button
                            variant="outlined"
                            onClick={() => {
                                setFilters(defaultFilters);
                                getFilteredUsinas(true);
                            }}
                            style={{ color: "#0a5068", borderColor: "#0a5068", marginRight: 16 }}
                        >
                            Limpar
                        </Button>

                        <Button
                            onClick={() => {
                                getFilteredUsinas();
                            }}
                            size="large"
                            style={{ backgroundColor: "#0a5068", color: "white" }}
                        >
                            Buscar
                        </Button>
                    </Box>

                    {!loading ? (
                        <>
                            <TableContainer sx={{ maxHeight: 460, marginTop: 6 }} component={Paper}>
                                <Table
                                    stickyHeader
                                    sx={{ minWidth: 460 }}
                                    aria-label="customized table"
                                >
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell colSpan={1}>ID</StyledTableCell>
                                            <StyledTableCell colSpan={1}>Nome</StyledTableCell>

                                            <StyledTableCell align="center" colSpan={1}>
                                                Online
                                            </StyledTableCell>
                                            <StyledTableCell
                                                colSpan={3}
                                                align="right"
                                            ></StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {usinas.map((usina) => (
                                            <StyledTableRow key={usina.id}>
                                                <StyledTableCell
                                                    colSpan={1}
                                                    component="th"
                                                    scope="row"
                                                >
                                                    {usina.id}
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    colSpan={1}
                                                    component="th"
                                                    scope="row"
                                                >
                                                    {usina.nome}
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    colSpan={1}
                                                    component="th"
                                                    scope="row"
                                                    align="center"
                                                >
                                                    {usina.online ? (
                                                        <Chip
                                                            label="Ativo"
                                                            variant="outlined"
                                                            color="success"
                                                        />
                                                    ) : (
                                                        <Chip
                                                            label="Inativo"
                                                            variant="outlined"
                                                            color="error"
                                                        />
                                                    )}
                                                </StyledTableCell>
                                                <StyledTableCell colSpan={3} align="right">
                                                    <Tooltip title="Visualizar" arrow>
                                                        <IconButton
                                                            onClick={() =>
                                                                handleOpenVisualizeUsina(usina)
                                                            }
                                                            size="large"
                                                        >
                                                            <>
                                                                <RemoveRedEyeIcon
                                                                    style={{ fill: "#0a5068" }}
                                                                />
                                                            </>
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Editar" arrow>
                                                        <IconButton
                                                            size="large"
                                                            onClick={() =>
                                                                handleOpenEditUsina(usina.id)
                                                            }
                                                        >
                                                            <EditIcon style={{ fill: "#0a5068" }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                    {userPerfil === roles.ADMIN && (
                                                        <Tooltip title="Remover" arrow>
                                                            <IconButton
                                                                onClick={() =>
                                                                    handleOpenDeleteUsina(usina)
                                                                }
                                                                size="large"
                                                            >
                                                                <DeleteIcon
                                                                    style={{ fill: "#0a5068" }}
                                                                />
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <TableContainer
                                style={{
                                    marginTop: 16,
                                    marginBottom: 0,
                                    borderBottom: 0,
                                    minHeight: 0,
                                }}
                            >
                                <Table stickyHeader aria-label="customized table">
                                    <TableFooter>
                                        <TablePagination
                                            rowsPerPageOptions={[]}
                                            count={total}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            SelectProps={{
                                                inputProps: {
                                                    "aria-label": "Linhas por página",
                                                },
                                            }}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationActions}
                                        />
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </>
                    ) : (
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "50vh",
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    )}
                </>
            </Paper>
            <Dialog
                maxWidth="md"
                fullWidth
                disableScrollLock={true}
                open={isOpenAddUsina}
                onClose={handleCloseAddUsina}
            >
                <DialogTitle style={{ color: "#0a5068" }}>
                    {isEdit ? "Editar usina" : isVisualizar ? usina.nome : "Adicionar nova usina"}
                </DialogTitle>
                <DialogContent>
                    <Divider variant="middle" sx={{ mb: 2, color: "#0a5068", padding: "0.5px" }} />
                    <Box
                        noValidate
                        component="form"
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                        }}
                    >
                        <Box
                            noValidate
                            component="form"
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                width: "fit-content",
                                flexGrow: 1,
                                marginLeft: 3,
                            }}
                        >
                            <TextField
                                label={usina.nome ? "Nome" : "Insira o nome da usina"}
                                id="input-nome"
                                style={{ marginBottom: "8px" }}
                                sx={{ m: 1, width: "95%" }}
                                variant="standard"
                                InputProps={{}}
                                value={usina.nome}
                                onChange={(evt) => setUsina({ ...usina, nome: evt.target.value })}
                                disabled={isVisualizar}
                            />
                            <TextField
                                label={"Capacidade instalada (kW)"}
                                id="input-capacidade"
                                style={{ marginBottom: "8px" }}
                                sx={{ m: 1, width: "95%" }}
                                variant="standard"
                                InputProps={{}}
                                value={usina.capacidade}
                                onChange={(evt) => {
                                    setUsina({ ...usina, capacidade: evt.target.value });
                                }}
                                disabled={isVisualizar}
                            />
                            <DialogContentText
                                sx={{ marginTop: 2, marginLeft: 1, marginBottom: 1 }}
                                id="alert-dialog-slide-description"
                            >
                                {isVisualizar
                                    ? "Local da usina:"
                                    : "Arraste o marcador para o local da usina:"}
                            </DialogContentText>

                            <Box
                                style={{
                                    height: "300px",
                                    width: "100%",
                                    marginLeft: 8,
                                    marginBottom: 8,
                                }}
                            >
                                <MapContainer
                                    center={[-16.3460888, -43.6239527]}
                                    zoom={4}
                                    scrollWheelZoom={true}
                                    style={{ height: "100%", width: "95%" }}
                                >
                                    <TileLayer
                                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    />
                                    <Marker
                                        icon={markerIconConst}
                                        position={position}
                                        ref={markerRef}
                                        eventHandlers={eventHandlers}
                                        draggable={isVisualizar ? false : true}
                                    >
                                        {usina.nome && <Popup>{usina.nome}</Popup>}
                                    </Marker>
                                </MapContainer>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                }}
                            >
                                <TextField
                                    label="Latitude"
                                    id="input-latitude"
                                    style={{ marginBottom: "8px" }}
                                    variant="standard"
                                    InputProps={{}}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    sx={{ m: 1, width: "46.5%" }}
                                    value={usina.latitude}
                                    onChange={(evt) => {
                                        setUsina({ ...usina, latitude: evt.target.value });
                                        setPosition({ ...position, lat: evt.target.value.trim() });
                                        console.log(
                                            "🚀 ~ file: ControleUsinas.jsx ~ line 722 ~ ControleUsinas ~ position",
                                            position
                                        );
                                    }}
                                    disabled={isVisualizar}
                                />
                                <TextField
                                    label="Longitude"
                                    id="input-longitude"
                                    style={{ marginBottom: "8px" }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="standard"
                                    InputProps={{}}
                                    sx={{ m: 1, width: "46.5%" }}
                                    value={usina.longitude}
                                    onChange={(evt) => {
                                        setUsina({ ...usina, longitude: evt.target.value });
                                        setPosition({ ...position, lng: evt.target.value.trim() });
                                    }}
                                    disabled={isVisualizar}
                                />
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                }}
                            >
                                <TextField
                                    label="Cidade"
                                    id="input-cidade"
                                    style={{ marginBottom: "8px" }}
                                    variant="standard"
                                    InputProps={{}}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    sx={{ m: 1, width: "46.5%" }}
                                    value={usina.cidade}
                                    onChange={(evt) => {
                                        setUsina({ ...usina, cidade: evt.target.value });
                                    }}
                                    disabled={isVisualizar}
                                />
                                <TextField
                                    label="Estado"
                                    id="input-estado"
                                    style={{ marginBottom: "8px" }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="standard"
                                    InputProps={{}}
                                    sx={{ m: 1, width: "46.5%" }}
                                    value={usina.estado}
                                    onChange={(evt) => {
                                        setUsina({ ...usina, estado: evt.target.value });
                                    }}
                                    disabled={isVisualizar}
                                />
                            </Box>

                            <DialogContentText
                                sx={{ marginTop: 2, marginLeft: 1, marginBottom: 2 }}
                                id="alert-dialog-slide-description"
                            >
                                {isVisualizar
                                    ? "Imagem da topografia da usina: "
                                    : "Adicione a imagem da topografia da usina:"}
                            </DialogContentText>

                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <label htmlFor="contained-button-file">
                                    {!isVisualizar && (
                                        <>
                                            <Input
                                                accept="image/*"
                                                id="contained-button-file"
                                                multiple
                                                type="file"
                                                onChange={onFileChange}
                                            />

                                            <Button
                                                onClick={onFileUpload}
                                                style={{
                                                    backgroundColor: "#0a5068",
                                                    marginTop: 16,
                                                }}
                                                variant="contained"
                                                component="span"
                                                disabled={isVisualizar}
                                            >
                                                {usina.planta ? "Alterar" : "Upload"}
                                            </Button>
                                        </>
                                    )}
                                </label>
                                {usina.planta && (
                                    <img
                                        style={{
                                            width: 230,
                                            height: 150,
                                            marginLeft: isVisualizar ? 8 : 36,
                                        }}
                                        className="playerProfilePic_home_tile"
                                        src={
                                            image ? URL.createObjectURL(usina.planta) : usina.planta
                                        }
                                    ></img>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseAddUsina}>Fechar</Button>
                    {!isVisualizar && (
                        <Button onClick={isEdit ? handleEditUsina : handleAddUsina}>
                            Confirmar
                        </Button>
                    )}
                </DialogActions>
            </Dialog>

            <Dialog
                maxWidth="sm"
                fullWidth
                disableScrollLock={true}
                open={isOpenDeleteUsina}
                onClose={() => setIsOpenDeleteUsina(false)}
            >
                <DialogTitle style={{ color: "#0a5068" }}>Remover usina</DialogTitle>
                <DialogContent>
                    <Divider variant="middle" sx={{ mb: 4, color: "#0a5068", padding: "0.5px" }} />
                    Você tem certeza que deseja remover a usina {usina.nome}?
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsOpenDeleteUsina(false)}>Fechar</Button>
                    {<Button onClick={handleDeleteUsina}>Confirmar</Button>}
                </DialogActions>
            </Dialog>
        </>
    );
}
