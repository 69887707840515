import React from "react";

export default function DadoEletricoUsina({ dado = "", unidade = "", valor = "" }) {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "10px",
            }}
        >
            <div
                style={{
                    flexDirection: "column",
                }}
            >
                <div style={{ display: "flex" }}>
                    <label
                        style={{
                            fontWeight: "700",
                            fontSize: "17px",
                            lineHeight: "100%",
                            color: "#4e596b",
                            letterSpacing: "0.02em",
                        }}
                    >
                        {dado}
                    </label>
                </div>
                <span
                    style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "100%",
                        display: "flex",
                        alignItems: "center",
                        color: "#4e596b",
                    }}
                >
                    {unidade}
                </span>
            </div>
            <span
                style={{
                    color: "#888888",
                    letterSpacing: "0.02em",
                    fontSize: "17px",
                    fontWeight: "700",
                }}
            >
                {valor}
            </span>
        </div>
    );
}
