import api from "./api";

export async function getAllUsinas() {
    const res = await api.get("/usina");
    return res.data;
}

export async function getAllUsinasHome() {
    const res = await api.get("/usina/home");
    return res.data;
}

export async function getAllUsinasTotalizadores() {
    const res = await api.get("/usina/home/totalizadores");
    return res.data;
}

export async function getUsinaTotalizador(usinaId) {
    const res = await api.get(`/usina/home/totalizadores/${usinaId}`);
    return res.data;
}

export async function getFilteredUsinas({ filters, currentPage }) {
    const res = await api.get(`/usina/filtered`, {
        params: {
            ...filters,
            currentPage: String(currentPage),
        },
    });
    return res.data;
}

export async function getOneUsina(usinaId) {
    const res = await api.get(`/usina/${usinaId}`);
    return res.data;
}

export async function addUsina(usina) {
    const res = await api.post("/usina/", usina, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return res.data;
}

export async function editUsina({ usinaId, usina }) {
    const res = await api.put(`/usina/${usinaId}`, usina, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return res.data;
}

export async function deleteUsina(usinaId) {
    const res = await api.delete(`/usina/${usinaId}`);
    return res.data;
}

export async function getCidadeEstadoFromLatLong(latitude, longitude) {
    const res = await api.get(
        `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`
    );

    return res.data;
}

export async function getEnergiaMedidor({ usinaId, intervaloDTO }) {
    const res = await api.get(`energia_medidor/usina/${usinaId}/findBetweenDates`, {
        params: { ...intervaloDTO },
    });
    return res.data;
}

export async function getEnergiaFromMonth({ usinaId, date }) {
    const res = await api.get(`/usina/${usinaId}/energyFromMonth?date=${date}`);
    return res.data;
}

export async function exportRelatorioEnergiaMedidor({ usinaId, intervaloDTO, reportFormat }) {
    const params = {
        startDate: intervaloDTO.startDate,
        endDate: intervaloDTO.endDate,
        reportFormat,
    };

    const res = await api.get(`energia_medidor/usina/${usinaId}/findBetweenDates`, {
        params: { ...params },
        responseType: reportFormat == "JSON" ? "json" : "blob",
    });
    return res.data;
}

export async function getUsinaChart({usinaId, params, startDate, endDate}){
    const requestParams = {
        startDate: startDate,
        endDate: endDate,
        ...params 
    };

    const res = await api.get(`usina/${usinaId}/chart`, {
        params: { ...requestParams }
    });
    return res.data;
}