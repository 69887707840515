import styled from "styled-components";
import placasVoltaicas from "../assets/placas_voltaicas.png";
export const ContainerGeral = styled.div`
    padding: 20px;
    background-color: #e5e5e5;
    height: 100%;
`;

export const SideBar = styled.div`
    background: url(${placasVoltaicas}), linear-gradient(180deg, #111111 0%, #126d8c 100%);
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: multiply;
    height: 100vh
`;

export const Painel = styled.div`
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export const HrDegrade = styled.hr`
    background: linear-gradient(
        270deg,
        rgba(78, 89, 107, 0) 0%,
        #4e596b 10%,
        #4e596b 90%,
        rgba(78, 89, 107, 0) 98.08%
    );
`;
