export const USER_KEY = "@Toth-userId";
export const TOKEN_KEY = "@Toth-token";

export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;

export const getToken = () => localStorage.getItem(TOKEN_KEY);

export const getUserId = () => localStorage.getItem(USER_KEY);

export const setLoginTokens = ({ access_token, userId }) => {
    localStorage.setItem(TOKEN_KEY, access_token);
    localStorage.setItem(USER_KEY, userId);
};

export const removeLoginTokens = () => {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(USER_KEY);
};
