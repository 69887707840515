import api from "./api";

export async function findBetweenDatesFromUsinaX({ usinaId, intervaloDTO }) {
    const res = await api.get(`usina/${usinaId}/findBetweenDates`, { params: { ...intervaloDTO } });
    return res.data;
}

export async function exportRelatorioGeracaoDiaria({ usinaId, intervaloDTO, reportFormat }) {
    const params = {
        startDate: intervaloDTO.startDate,
        endDate: intervaloDTO.endDate,
        reportFormat,
    };

    const res = await api.get(`usina/${usinaId}/findBetweenDates`, {
        params: { ...params },
        responseType: reportFormat == "JSON" ? "json" : "blob",
    });

    return res.data;
}
