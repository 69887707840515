import React from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    TimeScale,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import "chartjs-adapter-moment";
import moment from "moment";
import { converteParaHorarioLocal } from "../utils/utils";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    TimeScale,
    Title,
    Tooltip,
    Legend
);

export default function GraficoEnergiaMedidor({ energia, fullScreen, date, startDate, endDate }) {
    const options = {
        radius: 1, // desabilitar pontos
        locale: 'pt-br',
        responsive: true,
        scales: {
            x: {
                grid: {
                    display: false,
                },
                type: "time",
                time: {
                    unit: "hour",
                    displayFormats: {
                        hour: "HH:00",
                    },
                },
                min: moment(new Date(
                    fullScreen == 12 && startDate ? startDate.year : date.year,
                    fullScreen == 12 && startDate ? startDate.month - 1 : date.month - 1,
                    fullScreen == 12 && startDate ? startDate.day : date.day, 5, 0, 0)),
                max: moment(new Date(
                    fullScreen == 12 && endDate ? endDate.year : date.year,
                    fullScreen == 12 && endDate ? endDate.month - 1 : date.month - 1,
                    fullScreen == 12 && endDate ? endDate.day : date.day, 20, 0, 0)),
            },
            A: {
                type: "linear",
                position: "left",
                title: {
                    display: true,
                    text: "Energia medidor (kW/h)",
                },
            },
            B: {
                type: "linear",
                position: "right",
                grid: {
                    display: false,
                },
                title: {
                    display: true,
                    text: "Energia medidor (kW/h)",
                },
            },
        },
        plugins: {
            legend: {
                position: "top",
            },
        },
        tension: 0.4,

    };

    const data = {
        labels: energia?.map((g) => converteParaHorarioLocal(g.data)),
        datasets: [
            {
                label: "Energia Injetada",
                yAxisID: "A",
                data: energia?.map((g) => g.medicao_distribuida),
                borderColor: "rgb(255, 99, 132)",
                backgroundColor: "rgba(255, 99, 132, 0.5)",
            },
            // {
            //     label: "Potência",
            //     data: energia?.map((g) => g.potencia),
            //     borderColor: "rgb(153, 0, 204)",
            //     backgroundColor: "rgb(153, 0, 204, 0.5)",
            // },
            {
                label: "Energia Consumida",
                yAxisID: "B",
                data: energia?.map((g) => g.geracao),
                borderColor: "rgb(53, 162, 235)",
                backgroundColor: "rgba(53, 162, 235, 0.5)",
            },
        ],
    };

    return <Line options={options} data={data} fallbackContent />;
}
