import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "leaflet/dist/leaflet.css";
import "../App.css";
import { Checkbox } from "@mui/material";
import SquareIcon from '@mui/icons-material/Square';

export default function CheckBoxRele({ value = false }) {
    if (!value) {
        return (
            <Checkbox disabled checked={false} checkedIcon={<SquareIcon />} sx={{ '&.Mui-checked': { color: "red" } }} />
        );
    } else {
        return (
            <Checkbox disabled checked={true} checkedIcon={<SquareIcon />} sx={{ '&.Mui-checked': { color: "red" } }} />
        );
    }
}
