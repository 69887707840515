import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    IconButton,
    TextField,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from "@mui/material";
import { useAuth } from "../hooks/Auth";
import Skeleton from '@mui/material/Skeleton';
import okIcon from "../assets/ok.png";
import Divider from '@mui/material/Divider';
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { HeaderTitle } from "../styles/UsuariosStyle";
import styled from "styled-components";
import { SelectUsina } from "../components/SelectUsina";
import { SiGooglesheets, SiJson, SiMicrosoftexcel } from "react-icons/si";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { exportRelatorioGeracaoDiaria } from "../services/geracaoDiariaService";
import { downloadFile } from "../utils/utils";
import { exportRelatorioEstacao } from "../services/estacoesService";
import { exportRelatorioTrackers } from "../services/trackerService";
import { exportRelatorioInversor } from "../services/inversoresService";
import { exportRelatorioCombiner } from "../services/combinerService";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker from "@hassanmojab/react-modern-calendar-datepicker";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import * as relatorioService from "../services/relatorioService"
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useTheme } from "@mui/system";

const defaultFilters = {
    conteudo: "",
    usina: "",
    dataInicio: moment().subtract(1, "d"),
    dataFim: moment(),
};

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
                style={{ marginTop: -15 }}
            >
                {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
                style={{ marginTop: -15 }}
            >
                {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
                style={{ marginTop: -15 }}
            >
                {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
                style={{ marginTop: -15, marginRight: 8 }}
            >
                {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#0a5068",
        color: "#fff",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: "#e9ecef",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

export default function Usuarios() {
    const { getUserPerfil } = useAuth();
    const userPerfil = getUserPerfil();
    const dispatch = useDispatch();
    const [createRelatorioMensalDialog, setCreateRelatorioMensalDialog] = useState(false);
    const [relatorioMensalConfirmacao, setRelatorioMensalConfirmacao] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState(defaultFilters);
    const { enqueueSnackbar } = useSnackbar();
    const [relatorios, setRelatorios] = useState()
    const [dateRelatorio, setRealtorioDate] = useState('');
    const [valorKwh, setValorKwh] = useState('');
    const [usinaIdRelatorio, setUsinaIdRelatorio] = useState(0);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        let counter = 0;

        async function getAllRelatorios() {
            setLoading(true)
            try {
                const relatoriosData = await relatorioService.getAllRelatorios()
                setRelatorios(relatoriosData)
                setTotal(relatoriosData.length)
                setLoading(false)
            } catch {
                setLoading(false)
            }
        }

        getAllRelatorios()
        dispatch({ type: "SET_COUNTER", value: counter });

        const intervalId = setInterval(() => {
            counter++;
            dispatch({ type: "SET_COUNTER", value: counter });
            if (counter === 60) {
                // atualização a cada 1 minuto
                counter = 0;
                dispatch({ type: "SET_COUNTER", value: counter });
            }
        }, 1000);


        return () => clearInterval(intervalId);
    }, []);

    const dateRelatorioMask = (event) => {
        const { value } = event.target;

        const dateMask = value
            .replace(/\D/g, '')
            .replace(/^(\d{2})(\d{0,4})/, '$1/$2')

        setRealtorioDate(dateMask)
    }

    const usinaRelatorio = (event) => {
        setUsinaIdRelatorio(Number(event.target.value))
    }

    const valorKwhMask = (event) => {
        const { value } = event.target;

        const numberValue = parseFloat(value.replace(/\D/g, '')) / 100;

        setValorKwh(numberValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }));
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleGerarRelatorioMensal = async (event) => {
        try {
            event.preventDefault()

            const [month, year] = dateRelatorio.split('/')
            const convertedDate = `${year}-${month}-02`

            if (!usinaIdRelatorio || !valorKwh) {
                throw new Error("requiered");
            } else if (month > 12 || !year || !month || month <= 0) {
                throw new Error("date_error");
            }

            await relatorioService.createNewRelatorio(usinaIdRelatorio, parseFloat(valorKwh.replace(/[^\d,-]/g, '').replace(/,/g, '.')), convertedDate)

            setCreateRelatorioMensalDialog(false)

            setRelatorioMensalConfirmacao(true)
        } catch (error) {
            if (error.message === "requiered") {
                enqueueSnackbar(
                    "Preencha todos os campos corretamente para gerar o relatório.",
                    { variant: "error" }
                );
            } else if (error.message === "date_error") {
                enqueueSnackbar(
                    "Preencha a data corretamente para gerar o relatório. (MÊS/ANO)",
                    { variant: "error" }
                );
            } else {
                enqueueSnackbar(
                    "Houve um erro ao solicitar o relatório. Por favor, tente novamente!",
                    { variant: "error" }
                );
            }
        }

    }

    const handleExportRelatorio = async ({ reportFormat }) => {
        try {
            setIsLoading(true);
            const differenceInMonths = moment(filters.dataFim).diff(
                moment(filters.dataInicio),
                "months",
                true
            );
            if (differenceInMonths > 1) {
                enqueueSnackbar(
                    "O range máximo de datas permitido para exportar o relatório é de 1 mês!",
                    { variant: "error" }
                );
                return;
            }

            const intervaloDTO = {
                startDate: moment(filters.dataInicio).format("YYYY-MM-DDTHH:mm"),
                endDate: moment(filters.dataFim).format("YYYY-MM-DDTHH:mm"),
            };

            let responseRelatorio;

            switch (filters.conteudo) {
                case "COMBINER":
                    responseRelatorio = await exportRelatorioCombiner({
                        usinaId: filters.usina,
                        intervaloDTO,
                        reportFormat,
                    });
                    break;
                case "INVERSOR":
                    responseRelatorio = await exportRelatorioInversor({
                        usinaId: filters.usina,
                        intervaloDTO,
                        reportTypeDto: {
                            reportType: "TODOS DADOS",
                            reportFormat,
                        },
                    });
                    break;
                case "TRACKER":
                    responseRelatorio = await exportRelatorioTrackers({
                        usinaId: filters.usina,
                        intervaloDTO,
                        reportFormat,
                    });
                    break;
                case "ENERGIA_USINA":
                    responseRelatorio = await exportRelatorioGeracaoDiaria({
                        usinaId: filters.usina,
                        intervaloDTO,
                        reportFormat,
                    });
                    break;
                case "ESTACAO":
                    responseRelatorio = await exportRelatorioEstacao({
                        usinaId: filters.usina,
                        intervaloDTO,
                        reportTypeDto: {
                            reportType: "TODOS DADOS",
                            reportFormat,
                        },
                    });
                    break;
                default:
                    break;
            }

            downloadFile({
                data:
                    reportFormat == "JSON" ? JSON.stringify(responseRelatorio) : responseRelatorio,
                fileName: `relatorio-${filters.conteudo.toLowerCase()}.${intervaloDTO.startDate}-${intervaloDTO.endDate
                    }.${reportFormat.toLowerCase()}`,
                fileType: `text/${reportFormat.toLowerCase()}`,
            });
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            enqueueSnackbar("Ocorreu um ao gerar o relatório!", { variant: "error" });
        }
    };
    return (
        <>
            <Paper style={{ minHeight: " 90vh", padding: "38px" }} elevation={0}>
                <>
                    {isLoading ? (
                        <>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "50vh",
                                }}
                            >
                                <CircularProgress />
                            </Box>
                        </>
                    ) : (
                        <>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    mb: 8,
                                }}
                            >
                                <HeaderTitle>Relatórios</HeaderTitle>
                            </Box>
                            {/*  */}
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-evenly",
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Box sx={{ mb: 2, width: "100%" }}>
                                        <HeaderTitle style={{ fontSize: 16 }}>
                                            Selecione a usina
                                        </HeaderTitle>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            width: "100%",
                                        }}
                                    >
                                        <SelectUsina
                                            value={filters.usina}
                                            onChange={(e) =>
                                                setFilters({ ...filters, usina: e.target.value })
                                            }
                                            width={95}
                                        />
                                    </Box>

                                    <Box sx={{ mb: 2, mt: 10, width: "100%" }}>
                                        <HeaderTitle style={{ fontSize: 16 }}>
                                            Selecione o conteúdo a ser exportado
                                        </HeaderTitle>
                                    </Box>

                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            width: "100%",
                                        }}
                                    >
                                        <FormControl style={{ width: "95%" }}>
                                            <InputLabel>Conteúdo</InputLabel>
                                            <Select
                                                value={filters.conteudo}
                                                onChange={(e) =>
                                                    setFilters({
                                                        ...filters,
                                                        conteudo: e.target.value,
                                                    })
                                                }
                                                label="Selecione"
                                            >
                                                <MenuItem value="">Selecione</MenuItem>
                                                <MenuItem value={"COMBINER"}>Combiners</MenuItem>
                                                <MenuItem value={"INVERSOR"}>Inversors</MenuItem>
                                                <MenuItem value={"TRACKER"}>Trackers</MenuItem>
                                                <MenuItem value={"ENERGIA_USINA"}>
                                                    Energia da usina
                                                </MenuItem>
                                                <MenuItem value={"ESTACAO"}>
                                                    Estação Metereológica
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    {filters.usina && (
                                        <>
                                            {filters.conteudo && (
                                                <>
                                                    <Box sx={{ mb: 2 }}>
                                                        <HeaderTitle style={{ fontSize: 16 }}>
                                                            Selecione a data de início
                                                        </HeaderTitle>
                                                    </Box>

                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            justifyContent: "space-between",
                                                        }}
                                                    >
                                                        <LocalizationProvider
                                                            dateAdapter={AdapterMoment}
                                                        >
                                                            <DateTimePicker
                                                                renderInput={(params) => (
                                                                    <TextField {...params} />
                                                                )}
                                                                label="Data de início"
                                                                value={filters.dataInicio}
                                                                inputFormat="DD/MM/YYYY HH:mm:ss"
                                                                onChange={(newValue) => {
                                                                    setFilters({
                                                                        ...filters,
                                                                        dataInicio:
                                                                            moment(
                                                                                newValue
                                                                            ).toDate(),
                                                                    });
                                                                }}
                                                            />
                                                        </LocalizationProvider>
                                                    </Box>

                                                    <Box sx={{ mb: 2, mt: 10 }}>
                                                        <HeaderTitle style={{ fontSize: 16 }}>
                                                            Selecione a data final
                                                        </HeaderTitle>
                                                    </Box>

                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            justifyContent: "space-between",
                                                        }}
                                                    >
                                                        <LocalizationProvider
                                                            dateAdapter={AdapterMoment}
                                                        >
                                                            <DateTimePicker
                                                                renderInput={(params) => (
                                                                    <TextField {...params} />
                                                                )}
                                                                label="Data fim"
                                                                value={filters.dataFim}
                                                                inputFormat="DD/MM/YYYY HH:mm:ss"
                                                                onChange={(newValue) => {
                                                                    setFilters({
                                                                        ...filters,
                                                                        dataFim:
                                                                            moment(
                                                                                newValue
                                                                            ).toDate(),
                                                                    });
                                                                }}
                                                                minDate={moment(filters.dataInicio)}
                                                                maxDate={moment(
                                                                    filters.dataInicio
                                                                ).add(1, "M")}
                                                            />
                                                        </LocalizationProvider>
                                                    </Box>

                                                </>

                                            )}
                                        </>
                                    )}

                                </Box>
                            </Box>
                            {filters.usina && filters.conteudo && (
                                <>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignContent: "center",
                                        }}
                                    >
                                        {" "}
                                        <Box
                                            sx={{
                                                mb: 3,
                                                mt: 10,
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <HeaderTitle style={{ fontSize: 16 }}>
                                                Selecione o formato
                                            </HeaderTitle>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                mb: 4,
                                            }}
                                        >
                                            <Button
                                                variant="contained"
                                                style={{
                                                    color: "white",
                                                    backgroundColor: "#0a5068",
                                                    textTransform: "none",
                                                    marginRight: 16,
                                                }}
                                                onClick={() =>
                                                    handleExportRelatorio({
                                                        reportFormat: "CSV",
                                                    })
                                                }
                                            >
                                                <b>CSV</b>{" "}
                                                <SiGooglesheets
                                                    style={{ color: "white", marginLeft: 6 }}
                                                />
                                            </Button>
                                            <Button
                                                variant="contained"
                                                style={{
                                                    color: "white",
                                                    backgroundColor: "#0a5068",
                                                    textTransform: "none",
                                                    marginRight: 16,
                                                }}
                                                onClick={() =>
                                                    handleExportRelatorio({
                                                        reportFormat: "XLSX",
                                                    })
                                                }
                                            >
                                                <b>XLSX</b>{" "}
                                                <SiMicrosoftexcel
                                                    style={{ color: "white", marginLeft: 6 }}
                                                />
                                            </Button>

                                            <Button
                                                variant="contained"
                                                style={{
                                                    color: "white",
                                                    backgroundColor: "#0a5068",
                                                    textTransform: "none",
                                                    marginRight: 16,
                                                }}
                                                onClick={() =>
                                                    handleExportRelatorio({
                                                        reportFormat: "JSON",
                                                    })
                                                }
                                            >
                                                <b>JSON</b>{" "}
                                                <SiJson style={{ color: "white", marginLeft: 6 }} />
                                            </Button>
                                        </Box>{" "}
                                    </Box>
                                </>

                            )}
                            {userPerfil == 'ADMIN' && (
                                <>
                                    <Row className="pt-5">
                                        <Divider />
                                        <Col className="col-12 pt-5">
                                            <HeaderTitle>Relatórios Mensais</HeaderTitle>
                                        </Col>
                                        <Col className="text-end col-12" >
                                            <Box sx={{ mb: 2, mt: 5 }}>
                                                {!loading ? (
                                                    <Button
                                                        variant="contained"
                                                        style={{
                                                            color: "white",
                                                            backgroundColor: "#0a5068",
                                                            textTransform: "none",
                                                            marginRight: 16,
                                                        }}
                                                        onClick={() => setCreateRelatorioMensalDialog(true)}
                                                    >
                                                        Gerar novo relatório Mensal
                                                    </Button>
                                                ) : (
                                                    <Skeleton className="mb-1" variant="rectangular" style={{ width: '30%', height: '50px' }} />
                                                )}

                                            </Box>
                                        </Col>
                                    </Row>
                                    {!loading ? (
                                        <Row>
                                            <Col>
                                                <TableContainer
                                                    sx={{ maxHeight: 460, marginTop: 3 }}
                                                    component={Paper}
                                                >
                                                    <Table
                                                        stickyHeader
                                                        sx={{ minWidth: 460 }}
                                                        aria-label="customized table"
                                                    >
                                                        <TableHead>
                                                            <TableRow style={{ height: 1 }}>
                                                                <StyledTableCell>Nº RELATÓRIO</StyledTableCell>
                                                                <StyledTableCell>DATA RELATÓRIO (MÊS/ANO)</StyledTableCell>
                                                                <StyledTableCell>USINA</StyledTableCell>
                                                                <StyledTableCell>AÇÕES</StyledTableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {relatorios?.slice(page * 10, (page + 1) * 10).map((relatorio) => (
                                                                <StyledTableRow key={relatorio.id}>
                                                                    <StyledTableCell component="th" scope="row">
                                                                        {relatorio.id}
                                                                    </StyledTableCell>
                                                                    <StyledTableCell>
                                                                        {new Date(moment(relatorio.data_relatorio)).toLocaleString('pt-br', { month: 'numeric', year: 'numeric' })}
                                                                    </StyledTableCell>
                                                                    <StyledTableCell>
                                                                        {relatorio?.usina?.nome}
                                                                    </StyledTableCell>
                                                                    <StyledTableCell>
                                                                        <Button>
                                                                            <Link to="/relatorio_mensal" target='_blank' onClick={() => localStorage.setItem('data', JSON.stringify(relatorio))}>ABRIR RELATÓRIO</Link>
                                                                        </Button>
                                                                    </StyledTableCell>
                                                                </StyledTableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>

                                                <TableContainer
                                                    style={{
                                                        marginTop: 16,
                                                        marginBottom: 0,
                                                        borderBottom: 0,
                                                        minHeight: 0,
                                                    }}
                                                >
                                                    <Table stickyHeader aria-label="customized table">
                                                        <TableFooter>
                                                            <TablePagination
                                                                rowsPerPageOptions={[]}
                                                                count={total}
                                                                rowsPerPage={rowsPerPage}
                                                                page={page}
                                                                SelectProps={{
                                                                    inputProps: {
                                                                        "aria-label": "Linhas por página",
                                                                    },
                                                                }}
                                                                onPageChange={handleChangePage}
                                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                                ActionsComponent={TablePaginationActions}
                                                            />
                                                        </TableFooter>
                                                    </Table>
                                                </TableContainer>
                                            </Col>
                                        </Row>
                                    ) : (
                                        <>
                                            <Skeleton className="mb-2" variant="rectangular" animation="wave" style={{ width: '100%', height: '50px' }} />
                                            <Skeleton className="mb-1" variant="rectangular" animation="wave" style={{ width: '100%', height: '100px' }} />
                                            <Skeleton className="mb-1" variant="rectangular" animation="wave" style={{ width: '100%', height: '100px' }} />
                                            <Skeleton variant="rectangular" animation="wave" style={{ width: '100%', height: '100px' }} />
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </>

                <Dialog open={createRelatorioMensalDialog} style={{ height: '100vh' }} onClose={() => setCreateRelatorioMensalDialog(false)}>
                    <DialogTitle>
                        Gerar Relatório Personalizado: Preencha os Campos
                    </DialogTitle>
                    <form onSubmit={handleGerarRelatorioMensal}>
                        <DialogContent>
                            <TextField
                                label="Valor por kWh"
                                value={valorKwh}
                                onChange={valorKwhMask}
                                variant="outlined"
                                fullWidth
                                required
                                placeholder="Valor para kWh"
                                margin="normal"
                            />


                            <TextField
                                label="Mes do relatório (MM/AAAA)"
                                value={dateRelatorio}
                                onChange={dateRelatorioMask}
                                variant="outlined"
                                fullWidth
                                required
                                placeholder="MM/AAAA"
                                margin="normal"
                                inputProps={{
                                    maxLength: 7,
                                }}
                            />

                            <Box className="pt-3">
                                <SelectUsina
                                    onChange={(e) => usinaRelatorio(e)}
                                    width={100}
                                    required
                                />
                            </Box>

                        </DialogContent>
                        <DialogActions>
                            <Col className="col-6 text-start p-2" >
                                <Button variant="contained" color="error" onClick={() => setCreateRelatorioMensalDialog(false)}>
                                    Cancelar
                                </Button>
                            </Col>
                            <Col className="col-6 text-end" >
                                <Button variant="contained" type="submit" color="primary">
                                    Confirmar
                                </Button>
                            </Col>

                        </DialogActions>
                    </form>
                </Dialog>

                <Dialog open={relatorioMensalConfirmacao} style={{ height: '100vh' }} onClose={() => setRelatorioMensalConfirmacao(false)}>
                    <DialogContent>
                        <Col className="col-12 text-center mb-3" >
                            <img src={okIcon} style={{ width: "140px" }}></img>
                        </Col>
                        <p className="blockquote text-center" >Seu relatório está em processo de geração e estará disponível em breve. Por favor, aguarde alguns instantes e retorne para conferir os resultados.</p>
                    </DialogContent>
                    <DialogActions>
                        <Col className="col-12 text-center mb-2" >
                            <Button variant="contained" color="primary" onClick={() => setRelatorioMensalConfirmacao(false)}>OK</Button>
                        </Col>
                    </DialogActions>
                </Dialog>
            </Paper>
        </>
    );
}
