import styled from "styled-components";

export const HeaderTitle = styled.h1`
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 100%;
    letter-spacing: 0.02em;

    color: #4e596b;
`;
