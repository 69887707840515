import styled from "styled-components";

export const HeaderTitle = styled.h1`
    width: 305px;
    top: 0px;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 100%;
    /* identical to box height, or 24px */
    align-items: center;
    letter-spacing: 0.02em;

    color: #4e596b;
`;

export const DivisionLine = styled.span`
    width: 78px;
    height: 1px;
    margin: 15px -30px 0 0;
    background: linear-gradient(
        270deg,
        rgba(78, 89, 107, 0) 0%,
        #4e596b 48.53%,
        rgba(78, 89, 107, 0) 98.08%
    );
    transform: rotate(-90deg);
`;

export const HeaderDescription = styled.span`
    // width: 179px;
    // height: 26px;
    // margin-left: 5px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 100%;
    /* or 12px */
    color: #4e596b;
`;

export const HeaderUpdateText = styled.span`
    // width: 168px;
    // height: 12px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 100%;
    /* identical to box height, or 12px */

    color: #4e596b;
`;
