import React from "react";
/* eslint-disable @typescript-eslint/no-empty-function */
import { FormControl, IconButton, Input, InputAdornment, InputLabel } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { recoveryChangePassword } from "../services/recoverPassword";
import { Container } from "../styles/LoginStyle";
import logo_mvp from "../assets/logo_mvp.png";

const CustomButton = styled(Button)({
    color: "white",
    backgroundColor: "#232b2b",
    borderRadius: 28,
    padding: 12,
    paddingLeft: 40,
    paddingRight: 40,
    fontWeight: "bold",
    "&:hover": {
        backgroundColor: "#000",
    },
});

export default function RecoveryChangePassword() {
    const { state } = useLocation();

    const { token } = state;

    useEffect(() => {
        if (!token) navigate("/");
    }, []);

    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const [password, setPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmationPassword, setShowConfirmationPassword] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleChangePassword = async (event) => {
        try {
            event.preventDefault();
            setLoading(true);
            await recoveryChangePassword({
                senha: password,
                senhaConfirmacao: passwordConfirmation,
                token,
            });
            enqueueSnackbar("Senha alterada com sucesso! Realize seu login", {
                variant: "success",
            });
            setLoading(false);
            navigate("/");
        } catch (error) {
            enqueueSnackbar(error.response?.data?.message, { variant: "error" });
            setLoading(false);
        }
    };

    return (
        <Container
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Box
                width="600px"
                sx={{
                    display: "flex",
                    gridTemplateColumns: "repeat(1, 2fr)",
                    gridTemplateRows: "auto",
                    gridTemplateAreas: `"main"`,
                }}
            >
                <form onSubmit={handleChangePassword}>
                    <Box width="77%">
                        <span
                            style={{
                                marginLeft: "10px",
                                color: "#d7dae0",
                                fontSize: 16,
                                alignContent: "center",
                                alignSelf: "center",
                            }}
                        >
                            INSIRA A SUA NOVA SENHA
                        </span>

                        <FormControl sx={{ m: 1, mt: 3, width: "94%" }} variant="standard">
                            <InputLabel htmlFor="password-input">Senha</InputLabel>
                            <Input
                                id="password-input"
                                type={showPassword ? "text" : "password"}
                                value={password}
                                onChange={(evt) => setPassword(evt.target.value)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword(!showPassword)}
                                        >
                                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>

                        <FormControl sx={{ m: 1, mt: 3, width: "94%" }} variant="standard">
                            <InputLabel htmlFor="confirm-password-input">
                                Confirmação de senha
                            </InputLabel>
                            <Input
                                id="confirm-password-input"
                                type={showPassword ? "text" : "password"}
                                value={passwordConfirmation}
                                onChange={(evt) => setPasswordConfirmation(evt.target.value)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() =>
                                                setShowConfirmationPassword(
                                                    !showConfirmationPassword
                                                )
                                            }
                                        >
                                            {showConfirmationPassword ? <FaEyeSlash /> : <FaEye />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>

                        <Box textAlign="right">
                            <CustomButton
                                style={{
                                    marginTop: "36px",
                                    marginRight: "16px",
                                    alignSelf: "flex-end",
                                    justifyContent: "flex-end",
                                }}
                                variant="contained"
                                color="primary"
                                size="large"
                                type="submit"
                                disabled={loading}
                            >
                                CONFIRMAR
                            </CustomButton>
                        </Box>
                    </Box>
                </form>
            </Box>
            <Box>
                <img
                    src={logo_mvp}
                    alt="logo"
                    style={{
                        width: "200px",
                        margin: "auto",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                />
                <figcaption
                    style={{
                        color: "white",
                        fontStyle: "italic",
                        padding: "2px",
                        textAlign: "center",
                    }}
                >
                    Soluções de energia fotovoltaica
                </figcaption>
            </Box>
        </Container>
    );
}
