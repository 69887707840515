import { SET_COUNTER } from "../actions/setLastUpdateCounter";

const INITIAL_STATE = 0;

export default function LastUpdateCounterReducer(
    state = INITIAL_STATE,
    action
) {
    if (action.type === SET_COUNTER) {
        if (action.value < 10) {
            return `0${action.value}`;
        }
        return action.value;
    }
    return state;
}
