import styled from "styled-components";

export const InputText = styled.input`
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
`;
export const Circles = styled.input`
    height: 12px;
    width: 12px;
    background-color: #ff0000;
    border-radius: 50%;
    display: inline-block;
    margin: 0px 2px 0px 2px;
    border: none;
`;

export const InversorStatus = styled.span`
    height: 36px;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 0.02em;

    color: #888888;
`;
