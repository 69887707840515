import { Box } from "@mui/system";
import DadoEletricoInversores from "../components/DadoEletricoInversores";
import { Form } from 'react-bootstrap';
import Select from "react-select";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import { CUSTOM_LOCALE } from "../constants";
import { ReleStatus } from "../styles/ReleStyles"
import DatePicker from "@hassanmojab/react-modern-calendar-datepicker";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { downloadFile } from "../utils/utils";
import { useEffect, useState } from "react";
import moment from "moment";
import { HrDegrade } from "../styles/GlobalStyles";
import termometro from "./../assets/termometro.png";
import frequencia from "./../assets/frequencia.png";
import { Col, Container, Row } from "react-bootstrap";
import * as relesServices from "../services/relesService";
import * as alarmesServices from "../services/alarmeService"
import { checkPassword } from "../services/userService";
import LoadingSpin from "react-loading-spin";
import { SiGooglesheets, SiJson, SiMicrosoftexcel } from "react-icons/si";
import { ImageBox, ItemBox, SubTextMet, TextMet, TitleMet } from "../styles/EstacaoStyles";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ModelTrainingOutlinedIcon from "@mui/icons-material/ModelTrainingOutlined";
import ModeStandbyOutlinedIcon from "@mui/icons-material/ModeStandbyOutlined";
import CloudOffOutlinedIcon from '@mui/icons-material/CloudOffOutlined';
import * as auth from "../services/authService"
import {
    Button,
    Paper,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,

} from "@mui/material";
import GraficoVoltagem from "../components/GraficoVoltagem";
import GraficoCorrenteRele from "../components/GraficoCorrenteRele";
import GraficoTemperaturaRele from "../components/GraficoTemperaturaRele";
import CheckBoxRele from "../components/CheckBoxRele";
import GraficoFatorPotencia from "../components/GraficoFatorPotencia";
import { useAuth } from "../hooks/Auth";
import { converteParaHorarioLocal } from "../utils/utils";
import { contains } from "rsuite/esm/DOMHelper";

const horaAtual = moment(new Date, "YYYY-MM-DDTHH:mm:ss")

export default function Reles() {

    const [isOpenExportRelatorio, setIsOpenExportRelatorio] = useState(false);
    const [checkingPassword, setCheckingPassword] = useState(false);
    const [password, setPassword] = useState('');
    const { usinaId } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const [options, setOptions] = useState([{ value: 1, label: "Rele 1" }]);
    const [selectedOption, setSelectedOption] = useState(options[0]);
    const [isLoadingExportRelatorio, setIsLoadingExportRelatorio] = useState(false);
    const [dataRele, setRele] = useState([]);
    const [bandeirolas, setBandeirolas] = useState([]);
    const [statusRele, setStatusRele] = useState([]);
    const [loading, setLoading] = useState(true);
    const [dadosReleSelecionado, setDadosReleSelecionado] = useState();
    const dispatch = useDispatch();
    const [isOpenExportRelatorioTensao, setIsOpenExportRelatorioTensao] = useState(false);
    const [isOpenRemoteRele, setIsOpenRemoteRele] = useState(false);
    const [passwordDialog, setPasswordDialog] = useState(false);
    const [releAction, setReleAction] = useState('idle');
    const [isOpenExportRelatorioCorrente, setIsOpenExportRelatorioCorrente] = useState(false);
    const [isOpenExportRelatorioTemperatura, setIsOpenExportRelatorioTemperatura] = useState(false);
    const [isOpenExportRelatorioFatorPotencia, setIsOpenExportRelatorioFatorPotencia] = useState(false);
    const { getUserPerfil } = useAuth();
    const userPerfil = getUserPerfil();

    const [date, setDate] = useState({
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        day: new Date().getDate(),
    });
    const defaultFilters = {
        nome: "",
        usina: usinaId,
        tipo_equipamento: "RELE",
        status: "ativo",
        data_inicio: null,
        data_fim: null,
    };

    function responseTimeHandle(responseData) {
        let responseConverted = moment(new Date(converteParaHorarioLocal(responseData)), "YYYY-MM-DDTHH:mm:ss")

        let diff = horaAtual.diff(responseConverted, 'minutes')

        return diff
    }

    const getDadosRele = async () => {
        try {

            let intervaloDTO = {
                startDate: "",
                endDate: "",
            };

            intervaloDTO.startDate = moment(
                new Date(date.year, date.month - 1, date.day, 0, 0, 0, 0)
            ).format("YYYY-MM-DDTHH:mm");

            intervaloDTO.endDate = moment(
                new Date(date.year, date.month - 1, date.day, 23, 59, 59)
            ).format("YYYY-MM-DDTHH:mm");


            const [releData, statusReles] = await Promise.all([
                relesServices.findBetweenDatesFromRele({
                    usinaId,
                    intervaloDTO,
                    releId: selectedOption.value,
                }),
                relesServices.findAllLastRegister(usinaId),
            ]);

            if (moment().diff(intervaloDTO.startDate, 'days') >= 1) {
                defaultFilters.data_inicio = intervaloDTO.startDate
                defaultFilters.data_fim = intervaloDTO.endDate
                defaultFilters.status = "between_inicio"
            }
            const { total, alarmes } = await alarmesServices.getAllAlarms({
                filters: defaultFilters,
                currentPage: "0",
            });
            setBandeirolas(alarmes)

            setOptions(
                statusReles.map((rele) => ({
                    value: rele.cod,
                    label: `Rele ${rele.cod}`,
                }))
            );

            setRele(releData);
            setStatusRele(statusReles);

            setLoading(false);
        } catch (error) {
            setLoading(true);
        }
    }

    useEffect(() => {
        // usar setLoading(true) aqui para o usuário não sentir a atualização intervalada
        setLoading(true);
        async function updateReleData() {
            // usar setLoading(true) aqui para o usuário sentir a atualização intervalada
            // setLoading(true);

            const releData = await relesServices.findLastDataFromRele({
                usinaId,
                releId: selectedOption.value,
            });

            setDadosReleSelecionado(releData);

            await getDadosRele();
        }

        updateReleData(); // carregamento inicial

        let counter = 0;
        dispatch({ type: "SET_COUNTER", value: counter });

        const intervalId = setInterval(() => {
            counter++;
            dispatch({ type: "SET_COUNTER", value: counter });
            if (counter === 60) {
                // atualização a cada 1 minuto
                updateReleData();
                counter = 0;
                dispatch({ type: "SET_COUNTER", value: counter });
            }
        }, 1000);

        return () => clearInterval(intervalId);
    }, [selectedOption, date]);

    function handleSelectChange(e) {
        setSelectedOption(e);
    }

    async function handleCheckPassword(password) {
        event.preventDefault()
        setCheckingPassword(true)
        try {
            const responsePassword = await checkPassword(password)
            if (responsePassword.status == 200) {
                await handleRemoteReleAction(releAction)
            }
        } catch (error) {
            enqueueSnackbar("Senha incorreta. Tente novamente", {
                variant: "error",
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right",
                },
            });
        }
        setCheckingPassword(false)
    }

    async function handleRemoteReleAction(action) {
        try {
            if (userPerfil === "RESPONSAVEL_USINA") {
                throw new Error("Unauthorized");
            }

            if (action === "ligar") {
                await relesServices.ativaRele({ cod: selectedOption.value, usina_id: Number(usinaId), data: moment(new Date()) });
            } else if (action === "desligar") {
                await relesServices.desativaRele({ cod: selectedOption.value, usina_id: Number(usinaId), data: moment(new Date()) });
            } else {
                throw new Error("Ação inválida");
            }

            setPasswordDialog(false)

            enqueueSnackbar("Solicitação enviada para o rele. Aguarde alguns minutos!", {
                variant: "success",
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right",
                },
            });
        } catch (error) {
            if (error.toString().includes("401")) {
                auth.removeLoginTokens()
                window.location = "/login";
            }
            enqueueSnackbar("Erro ao enviar solicitação. Tente novamente em alguns instantes!", {
                variant: "error",
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right",
                },
            });

        }
    }

    const handleExportRelatorioTensãoRele = async ({ reportFormat }) => {
        try {
            let intervaloDTO = {
                startDate: "",
                endDate: "",
            };
            setIsOpenExportRelatorio(true);
            intervaloDTO.startDate = moment(
                new Date(date.year, date.month - 1, date.day, 0, 0, 0, 0)
            ).format("YYYY-MM-DDTHH:mm");

            intervaloDTO.endDate = moment(
                new Date(date.year, date.month - 1, date.day, 23, 59, 59)
            ).format("YYYY-MM-DDTHH:mm");

            const responseRelatorio = await relesServices.exportRelatorioRele({
                usinaId,
                intervaloDTO,
                reportTypeDto: {
                    reportType: "VOLTS",
                    reportFormat,
                },
            });
            const formattedDate = `${date.day}-${date.month}-${date.year}`;
            downloadFile({
                data:
                    reportFormat == "JSON" ? JSON.stringify(responseRelatorio) : responseRelatorio,
                fileName: `relatorio-tensao-rele-${formattedDate}.${reportFormat.toLowerCase()}`,
                fileType: `text/${reportFormat.toLowerCase()}`,
            });
            setIsOpenExportRelatorio(false);
        } catch (error) {
            enqueueSnackbar("Ocorreu um ao gerar o relatório!", { variant: "error" });

            setIsOpenExportRelatorio(false);
            console.log(
                "🚀 ~ file: Usinas.jsx ~ line 127 ~ handleExportRelatorioGeracaoDiaria ~ error",
                error
            );
        }
    };
    const handleExportRelatorioTemperaturaRele = async ({ reportFormat }) => {
        try {
            let intervaloDTO = {
                startDate: "",
                endDate: "",
            };
            setIsOpenExportRelatorio(true);
            intervaloDTO.startDate = moment(
                new Date(date.year, date.month - 1, date.day, 0, 0, 0, 0)
            ).format("YYYY-MM-DDTHH:mm");

            intervaloDTO.endDate = moment(
                new Date(date.year, date.month - 1, date.day, 23, 59, 59)
            ).format("YYYY-MM-DDTHH:mm");

            const responseRelatorio = await relesServices.exportRelatorioRele({
                usinaId,
                intervaloDTO,
                reportTypeDto: {
                    reportType: "TEMPERATURA",
                    reportFormat,
                },
            });
            const formattedDate = `${date.day}-${date.month}-${date.year}`;
            downloadFile({
                data:
                    reportFormat == "JSON" ? JSON.stringify(responseRelatorio) : responseRelatorio,
                fileName: `relatorio-temperatura-rele-${formattedDate}.${reportFormat.toLowerCase()}`,
                fileType: `text/${reportFormat.toLowerCase()}`,
            });
            setIsOpenExportRelatorio(false);
        } catch (error) {
            enqueueSnackbar("Ocorreu um ao gerar o relatório!", { variant: "error" });

            setIsOpenExportRelatorio(false);
            console.log(
                "🚀 ~ file: Usinas.jsx ~ line 127 ~ handleExportRelatorioGeracaoDiaria ~ error",
                error
            );
        }
    };
    const handleExportRelatorioCorrenteRele = async ({ reportFormat }) => {
        try {
            let intervaloDTO = {
                startDate: "",
                endDate: "",
            };
            setIsOpenExportRelatorio(true);
            intervaloDTO.startDate = moment(
                new Date(date.year, date.month - 1, date.day, 0, 0, 0, 0)
            ).format("YYYY-MM-DDTHH:mm");

            intervaloDTO.endDate = moment(
                new Date(date.year, date.month - 1, date.day, 23, 59, 59)
            ).format("YYYY-MM-DDTHH:mm");

            const responseRelatorio = await relesServices.exportRelatorioRele({
                usinaId,
                intervaloDTO,
                reportTypeDto: {
                    reportType: "AMPERES",
                    reportFormat,
                },
            });
            const formattedDate = `${date.day}-${date.month}-${date.year}`;
            downloadFile({
                data:
                    reportFormat == "JSON" ? JSON.stringify(responseRelatorio) : responseRelatorio,
                fileName: `relatorio-corrente-rele-${formattedDate}.${reportFormat.toLowerCase()}`,
                fileType: `text/${reportFormat.toLowerCase()}`,
            });
            setIsOpenExportRelatorio(false);
        } catch (error) {
            enqueueSnackbar("Ocorreu um ao gerar o relatório!", { variant: "error" });

            setIsOpenExportRelatorio(false);
            console.log(
                "🚀 ~ file: Usinas.jsx ~ line 127 ~ handleExportRelatorioGeracaoDiaria ~ error",
                error
            );
        }
    };
    const handleExportRelatorioFatorPotenciaRele = async ({ reportFormat }) => {
        try {
            let intervaloDTO = {
                startDate: "",
                endDate: "",
            };
            setIsOpenExportRelatorio(true);
            intervaloDTO.startDate = moment(
                new Date(date.year, date.month - 1, date.day, 0, 0, 0, 0)
            ).format("YYYY-MM-DDTHH:mm");

            intervaloDTO.endDate = moment(
                new Date(date.year, date.month - 1, date.day, 23, 59, 59)
            ).format("YYYY-MM-DDTHH:mm");

            const responseRelatorio = await relesServices.exportRelatorioRele({
                usinaId,
                intervaloDTO,
                reportTypeDto: {
                    reportType: "FATOR DE POTENCIA",
                    reportFormat,
                },
            });
            const formattedDate = `${date.day}-${date.month}-${date.year}`;
            downloadFile({
                data:
                    reportFormat == "JSON" ? JSON.stringify(responseRelatorio) : responseRelatorio,
                fileName: `relatorio-fatorPotencia-rele-${formattedDate}.${reportFormat.toLowerCase()}`,
                fileType: `text/${reportFormat.toLowerCase()}`,
            });
            setIsOpenExportRelatorio(false);
        } catch (error) {
            enqueueSnackbar("Ocorreu um ao gerar o relatório!", { variant: "error" });

            setIsOpenExportRelatorio(false);
            console.log(
                "🚀 ~ file: Usinas.jsx ~ line 127 ~ handleExportRelatorioGeracaoDiaria ~ error",
                error
            );
        }
    };

    let RelesDiario = (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                height: "75vh",
            }}
        >
            <CircularProgress />
        </Box>
    );

    function formatInputValue() {
        if (!date) return "";

        let format = date.day > 9 ? `${date.day}/` : `0${date.day}/`;
        format += date.month > 9 ? `${date.month}/` : `0${date.month}/`;
        format += date.year;

        return format;
    }


    if (!loading) {
        const renderCustomInput = ({ ref }) => (
            <input
                readOnly
                ref={ref} // necessary
                placeholder="Filtre por data"
                value={date ? formatInputValue() : ""}
                style={{
                    width: "96px",
                    height: "34px",
                    padding: "6px 12px",
                    fontSize: "14px",
                    lineHeight: "1.42857143",
                    color: "#555",
                    backgroundColor: "#fff",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                }}
                className="custom-input-class" // a styling class
            />
        );
        RelesDiario = (
            <>
                <Paper style={{ minHeight: " 90vh", padding: "38px" }} elevation={3}>

                    <Row>
                        <Col
                            sm={4}
                            style={{
                                borderRight: "0.6px solid #C4C4C4",
                            }}
                        >
                            <Row>
                                <Col sm={6}>
                                    <Select
                                        defaultValue={selectedOption}
                                        onChange={handleSelectChange}
                                        options={options}
                                    />
                                </Col>

                                <Col sm={6} style={{ textAlign: "right" }}>
                                    {dadosReleSelecionado?.status === 0 && responseTimeHandle(dadosReleSelecionado?.data) <= 20 ? (
                                        <span
                                            style={{
                                                fontWeight: 700,
                                                fontSize: "18px",
                                                letterSpacing: "0.02rem",
                                                color: "#049B5F",
                                            }}
                                        >
                                            Ativo
                                            <CheckCircleOutlineIcon style={{ color: "#049B5F" }} />
                                        </span>
                                    ) : dadosReleSelecionado?.status === 5120 && responseTimeHandle(dadosReleSelecionado?.data) <= 20 ? (
                                        <span
                                            style={{
                                                fontWeight: 700,
                                                fontSize: "18px",
                                                letterSpacing: "0.02rem",
                                                color: "#FBB041",
                                            }}
                                        >
                                            Stand By
                                            <ModeStandbyOutlinedIcon style={{ color: "#FBB041" }} />
                                        </span>
                                    ) : dadosReleSelecionado?.status === 5632 ? (
                                        <span
                                            style={{
                                                fontWeight: 700,
                                                fontSize: "18px",
                                                letterSpacing: "0.02rem",
                                                color: "#3C96C6",
                                            }}
                                        >
                                            Iniciando
                                            <ModelTrainingOutlinedIcon style={{ color: "#3C96C6" }} />
                                        </span>
                                    ) : dadosReleSelecionado?.status === 21760 && responseTimeHandle(dadosReleSelecionado?.data) <= 20 ? (
                                        <span
                                            style={{
                                                fontWeight: 700,
                                                fontSize: "18px",
                                                letterSpacing: "0.02rem",
                                                color: "#D40707",
                                            }}
                                        >
                                            Falha
                                            <CancelOutlinedIcon style={{ color: "#D40707" }} />
                                        </span>
                                    ) : (
                                        <span
                                            style={{
                                                fontWeight: 700,
                                                fontSize: "18px",
                                                letterSpacing: "0.02rem",
                                                color: "#ff9233",
                                                paddingLeft: "1%",
                                            }}
                                        >
                                            Offline
                                            <CloudOffOutlinedIcon style={{ paddingLeft: "1%", color: "#ff9233" }} />
                                        </span>
                                    )}
                                </Col>
                            </Row>

                            <hr />

                            <Row style={{ marginBottom: "1rem" }}>
                                <Col>
                                    <label className="device-label">Modelo</label>
                                </Col>
                                <Col>
                                    <nobr>
                                        <span className="device-value">
                                            {dadosReleSelecionado?.modelo ? dadosReleSelecionado?.modelo : "-"}
                                        </span>
                                    </nobr>
                                </Col>
                            </Row>
                            <Row style={{ marginBottom: "1rem" }}>
                                <Col>
                                    <label className="device-label">SN</label>
                                </Col>
                                <Col>
                                    <span className="device-value">
                                        {dadosReleSelecionado.sn ? dadosReleSelecionado.sn : "-"}
                                    </span>
                                </Col>
                            </Row>
                            <Row style={{ marginBottom: "1rem" }}>
                                <Col>
                                    <label className="device-label">ID</label>
                                </Col>
                                <Col>
                                    <span className="device-value">{selectedOption.value}</span>
                                </Col>
                            </Row>
                            <Row style={{ marginBottom: "1rem" }}>
                                <Col>
                                    <label className="device-label">Device Status</label>
                                </Col>
                                <Col>
                                    <span className="device-value">On Grid</span>
                                </Col>
                            </Row>
                        </Col>

                        <Col
                            style={{
                                textAlign: "left",
                                borderRight: "0.6px solid #C4C4C4",
                            }}
                            sm={4}
                        >

                            <br />
                            <Row>
                                <Col
                                    sm={6}
                                    style={{
                                        borderRight: "0.6px dashed #C4C4C4",
                                    }}
                                >
                                    <Row style={{ marginBottom: "10%" }}>
                                        <DadoEletricoInversores
                                            dado="Tensão A:"
                                            unidade="(Volts)"
                                            valor={dadosReleSelecionado?.tensao_a ? dadosReleSelecionado?.tensao_a?.toLocaleString(
                                                "pt-BR"
                                            ) : "-"}
                                        />
                                    </Row>
                                    <Row style={{ marginBottom: "10%" }}>
                                        <DadoEletricoInversores
                                            dado="Tensão B:"
                                            unidade="(Volts)"
                                            valor={dadosReleSelecionado?.tensao_b ? dadosReleSelecionado?.tensao_b?.toLocaleString(
                                                "pt-BR"
                                            ) : "-"}
                                        />
                                    </Row>
                                    <Row style={{ marginBottom: "10%" }}>
                                        <DadoEletricoInversores
                                            dado="Tensão C:"
                                            unidade="(Volts)"
                                            valor={dadosReleSelecionado?.tensao_c ? dadosReleSelecionado?.tensao_c?.toLocaleString(
                                                "pt-BR"
                                            ) : "-"}
                                        />
                                    </Row>
                                </Col>

                                <Col sm={6}>
                                    <Row style={{ marginBottom: "10%" }}>
                                        <DadoEletricoInversores
                                            dado="Corrente A:"
                                            unidade="(Amperes)"
                                            valor={dadosReleSelecionado?.corrente_a ? dadosReleSelecionado?.corrente_a?.toLocaleString(
                                                "pt-BR"
                                            ) : "-"}
                                        />
                                    </Row>
                                    <Row style={{ marginBottom: "10%" }}>
                                        <DadoEletricoInversores
                                            dado="Corrente B:"
                                            unidade="(Amperes)"
                                            valor={dadosReleSelecionado?.corrente_b ? dadosReleSelecionado?.corrente_b?.toLocaleString(
                                                "pt-BR"
                                            ) : "-"}
                                        />
                                    </Row>
                                    <Row style={{ marginBottom: "10%" }}>
                                        <DadoEletricoInversores
                                            dado="Corrente C:"
                                            unidade="(Amperes)"
                                            valor={dadosReleSelecionado?.corrente_c ? dadosReleSelecionado?.corrente_c?.toLocaleString(
                                                "pt-BR"
                                            ) : "-"}
                                        />
                                    </Row>

                                </Col>
                            </Row>
                        </Col>

                        <Col style={{ textAlign: "left" }} sm={4}>

                            <br />
                            <Row>
                                <Col
                                    sm={6}
                                    style={{
                                        borderRight: "0.6px dashed #C4C4C4",
                                    }}
                                >
                                    <Row style={{ marginBottom: "10%" }}>
                                        <DadoEletricoInversores
                                            dado="Fator de potência A"
                                            unidade=""
                                            valor={dadosReleSelecionado?.fator_potencia_a ? dadosReleSelecionado?.fator_potencia_a?.toLocaleString(
                                                "pt-BR"
                                            ) : "-"}
                                        />
                                    </Row>
                                    <Row style={{ marginBottom: "10%" }}>
                                        <DadoEletricoInversores
                                            dado="Fator de potência B"
                                            unidade=""
                                            valor={dadosReleSelecionado?.fator_potencia_b ? dadosReleSelecionado?.fator_potencia_b?.toLocaleString(
                                                "pt-BR"
                                            ) : "-"}
                                        />
                                    </Row>
                                    <Row style={{ marginBottom: "10%" }}>
                                        <DadoEletricoInversores
                                            dado="Fator de potência C"
                                            unidade=""
                                            valor={dadosReleSelecionado?.fator_potencia_c ? dadosReleSelecionado?.fator_potencia_c?.toLocaleString(
                                                "pt-BR"
                                            ) : "-"}
                                        />
                                    </Row>
                                </Col>

                                <Col sm={6}>
                                    <Row style={{ marginBottom: "5%" }}>
                                        <DadoEletricoInversores
                                            dado="Frequência de linha:"
                                            unidade="(Hz)"
                                            valor={dadosReleSelecionado?.frequencia_linha ? dadosReleSelecionado?.frequencia_linha?.toLocaleString(
                                                "pt-BR"
                                            ) : "-"}
                                        />
                                    </Row>
                                    <Row style={{ marginBottom: "5%" }}>
                                        <DadoEletricoInversores
                                            dado="Frequência de barra:"
                                            unidade="(Hz)"
                                            valor={dadosReleSelecionado?.frequencia_barra ? dadosReleSelecionado?.frequencia_barra?.toLocaleString(
                                                "pt-BR"
                                            ) : "-"}
                                        />
                                    </Row>
                                    <Row style={{ marginBottom: "5%" }}>
                                        <DadoEletricoInversores
                                            dado="Temperatura:"
                                            unidade="(ºC)"
                                            valor={dadosReleSelecionado?.temperatura ? dadosReleSelecionado?.temperatura?.toLocaleString(
                                                "pt-BR"
                                            ) : "-"}
                                        />
                                    </Row>
                                    <Row style={{ marginBottom: "5%" }}>
                                        <DadoEletricoInversores dado="DSP Colection:" valor={"YES"} />
                                    </Row>
                                    <Row>
                                        <DadoEletricoInversores
                                            dado="PV Connection:"
                                            valor={"CONNECTED"}
                                        />
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <HrDegrade />

                    {/* <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            mb: 8,
                        }}
                    >
                        <img src={rele} alt="" style={{ verticalAlign: "top", height: "32px", width: "36px" }} />
                        <b style={{ fontSize: "20px", marginLeft: 8, marginTop: 1 }}>
                            Reles
                        </b>
                    </Box> */}




                    <Row style={{ textAlign: "center", marginBottom: "1rem" }}>
                        <DatePicker
                            value={date} // ? `${date?.day}/${date?.month}/${date?.year}` : null
                            // formatInputText={formatInputValue}
                            onChange={setDate}
                            renderInput={renderCustomInput} // render a custom input
                            // inputPlaceholder="Selecione um dia"
                            locale={CUSTOM_LOCALE}
                        // maximumDate={maximumDate}
                        // shouldHighlightWeekends
                        ></DatePicker>
                    </Row>

                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-evenly",
                            mb: 8,
                        }}
                    >

                        <Row>
                            <Col className="col-12" style={{ width: "80%", marginRight: "100%" }}>
                                <ItemBox>
                                    <ImageBox>
                                        <img src={termometro} alt="" style={{ width: "36px" }} />
                                    </ImageBox>
                                    <TitleMet>Temperatura</TitleMet>
                                    <TextMet>
                                        {dadosReleSelecionado?.temperatura ? dadosReleSelecionado?.temperatura?.toLocaleString(
                                            "pt-BR"
                                        ) : "-"}{" "}
                                        <SubTextMet>°C</SubTextMet>
                                    </TextMet>
                                </ItemBox>
                            </Col>
                            <Col className="col-6" style={{ width: "40%" }}>
                                <ItemBox>
                                    <ImageBox>
                                        <img src={frequencia} alt="" style={{ width: "36px" }} />
                                    </ImageBox>
                                    <TitleMet>Frequência de Linha</TitleMet>
                                    <TextMet>
                                        {dadosReleSelecionado?.frequencia_linha ? dadosReleSelecionado?.frequencia_linha?.toLocaleString(
                                            "pt-BR"
                                        ) : "-"}{" "}
                                        <SubTextMet>Hz</SubTextMet>
                                    </TextMet>
                                </ItemBox>
                                {userPerfil !== "RESPONSAVEL_USINA" && (
                                    <div className="pt-4 text-center">
                                        <Button
                                            variant="contained"
                                            style={{
                                                padding: "0 14px",
                                                color: "white",
                                                backgroundColor: "#0a5068",
                                                textTransform: "none"
                                            }}
                                            onClick={() => {
                                                setReleAction('desligar')
                                                setIsOpenRemoteRele(true)
                                            }}
                                        >
                                            DESLIGAR
                                        </Button>
                                    </div>
                                )}
                            </Col>
                            <Col className="col-6" style={{ width: "40%" }}>
                                <ItemBox>
                                    <ImageBox>
                                        <img src={frequencia} alt="" style={{ width: "36px" }} />
                                    </ImageBox>
                                    <TitleMet>Frequência de Barra</TitleMet>
                                    <TextMet>
                                        {dadosReleSelecionado?.frequencia_barra ? dadosReleSelecionado?.frequencia_barra?.toLocaleString(
                                            "pt-BR"
                                        ) : "-"}{" "}
                                        <SubTextMet>Hz</SubTextMet>
                                    </TextMet>
                                </ItemBox>
                                {userPerfil !== "RESPONSAVEL_USINA" && (
                                    <div className="pt-4 text-center">
                                        <Button
                                            variant="contained"
                                            style={{
                                                padding: "0 14px",
                                                color: "white",
                                                backgroundColor: "#0a5068",
                                                textTransform: "none"
                                            }}
                                            onClick={() => {
                                                setReleAction('ligar')
                                                setIsOpenRemoteRele(true)
                                            }}
                                        >
                                            LIGAR
                                        </Button>
                                    </div>
                                )}
                            </Col>
                        </Row>
                        <ItemBox>
                            <TitleMet className="mb-3">Bandeirolas</TitleMet>
                            <table className="text-center" >
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>A</th>
                                        <th>B</th>
                                        <th>C</th>
                                        <th style={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}>N</th>
                                        <th>A</th>
                                        <th>B</th>
                                        <th>C</th>
                                        <th>#</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th>51</th>
                                        <th><CheckBoxRele value={bandeirolas.some(alarme => alarme.descricao === "51A")} /></th>
                                        <th><CheckBoxRele value={bandeirolas.some(alarme => alarme.descricao === "51B")} /></th>
                                        <th><CheckBoxRele value={bandeirolas.some(alarme => alarme.descricao === "51C")} /></th>
                                        <th style={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}><CheckBoxRele value={bandeirolas.some(alarme => alarme.descricao === "51N")} /></th>
                                        <th><CheckBoxRele value={bandeirolas.some(alarme => alarme.descricao === "27A")} /></th>
                                        <th><CheckBoxRele value={bandeirolas.some(alarme => alarme.descricao === "27B")} /></th>
                                        <th><CheckBoxRele value={bandeirolas.some(alarme => alarme.descricao === "27C")} /></th>
                                        <th>27</th>
                                    </tr>
                                    <tr>
                                        <th>50</th>
                                        <th><CheckBoxRele value={bandeirolas.some(alarme => alarme.descricao === "50A")} /></th>
                                        <th><CheckBoxRele value={bandeirolas.some(alarme => alarme.descricao === "50B")} /></th>
                                        <th><CheckBoxRele value={bandeirolas.some(alarme => alarme.descricao === "50C")} /></th>
                                        <th style={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}><CheckBoxRele value={bandeirolas.some(alarme => alarme.descricao === "50N")} /></th>
                                        <th><CheckBoxRele value={bandeirolas.some(alarme => alarme.descricao === "32A")} /></th>
                                        <th><CheckBoxRele value={bandeirolas.some(alarme => alarme.descricao === "32B")} /></th>
                                        <th><CheckBoxRele value={bandeirolas.some(alarme => alarme.descricao === "32C")} /></th>
                                        <th>32</th>
                                    </tr>
                                    <tr >
                                        <th>67</th>
                                        <th><CheckBoxRele value={bandeirolas.some(alarme => alarme.descricao === "67A")} /></th>
                                        <th><CheckBoxRele value={bandeirolas.some(alarme => alarme.descricao === "67B")} /></th>
                                        <th><CheckBoxRele value={bandeirolas.some(alarme => alarme.descricao === "67C")} /></th>
                                        <th style={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}><CheckBoxRele value={bandeirolas.some(alarme => alarme.descricao === "67N")} /></th>
                                        <th style={{ borderBottom: "1px solid rgba(224, 224, 224, 1)" }}><CheckBoxRele value={bandeirolas.some(alarme => alarme.descricao === "37A")} /></th>
                                        <th style={{ borderBottom: "1px solid rgba(224, 224, 224, 1)" }}><CheckBoxRele value={bandeirolas.some(alarme => alarme.descricao === "37B")} /></th>
                                        <th style={{ borderBottom: "1px solid rgba(224, 224, 224, 1)" }}><CheckBoxRele value={bandeirolas.some(alarme => alarme.descricao === "37C")} /></th>
                                        <th style={{ borderBottom: "1px solid rgba(224, 224, 224, 1)" }}>37</th>
                                    </tr>
                                    <tr >
                                        <th>59</th>
                                        <th style={{ borderBottom: "1px solid rgba(224, 224, 224, 1)" }}><CheckBoxRele value={bandeirolas.some(alarme => alarme.descricao === "59A")} /></th>
                                        <th style={{ borderBottom: "1px solid rgba(224, 224, 224, 1)" }}><CheckBoxRele value={bandeirolas.some(alarme => alarme.descricao === "59B")} /></th>
                                        <th style={{ borderBottom: "1px solid rgba(224, 224, 224, 1)" }}><CheckBoxRele value={bandeirolas.some(alarme => alarme.descricao === "59C")} /></th>
                                        <th style={{ borderBottom: "1px solid rgba(224, 224, 224, 1)", borderRight: "1px solid rgba(224, 224, 224, 1)" }}><CheckBoxRele value={bandeirolas.some(alarme => alarme.descricao === "59N")} /></th>
                                        <th><CheckBoxRele value={bandeirolas.some(alarme => alarme.descricao === "81A")} /></th>
                                        <th><CheckBoxRele value={bandeirolas.some(alarme => alarme.descricao === "QB")} /></th>
                                        <th><CheckBoxRele value={bandeirolas.some(alarme => alarme.descricao === "GSC")} /></th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th style={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}></th>
                                        <th>81</th>
                                        <th>Q</th>
                                        <th>GS</th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th style={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}></th>
                                        <th><CheckBoxRele value={bandeirolas.some(alarme => alarme.descricao === "47A")} /></th>
                                        <th><CheckBoxRele value={bandeirolas.some(alarme => alarme.descricao === "86B")} /></th>
                                        <th><CheckBoxRele value={bandeirolas.some(alarme => alarme.descricao === "78C")} /></th>
                                        <th><CheckBoxRele value={bandeirolas.some(alarme => alarme.descricao === "27-0")} /></th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th style={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}></th>
                                        <th>47</th>
                                        <th>86</th>
                                        <th>78</th>
                                        <th>27-0</th>
                                    </tr>
                                </tbody>
                            </table>
                        </ItemBox>
                    </Box>

                    <Row>

                        <Col md={12} lg={6} style={{ textAlign: "center" }}>
                            <b style={{ fontSize: "20px" }}>Tensão (Volts) x Tempo</b>{" "}
                            <Button
                                variant="contained"
                                style={{
                                    padding: "0 14px",
                                    color: "white",
                                    backgroundColor: "#0a5068",
                                    textTransform: "none",
                                    marginLeft: "20px",
                                }}
                                onClick={() => setIsOpenExportRelatorioTensao(true)}
                            >
                                Exportar relatório
                            </Button>
                            <div style={{ width: "100%" }}>
                                <GraficoVoltagem dataRele={dataRele} date={date} />
                            </div>
                        </Col>
                        <Col md={12} lg={6} style={{ textAlign: "center" }}>
                            <b style={{ fontSize: "20px" }}>Corrente (Amperes) x Tempo</b>{" "}
                            <Button
                                variant="contained"
                                style={{
                                    padding: "0 14px",
                                    color: "white",
                                    backgroundColor: "#0a5068",
                                    textTransform: "none",
                                    marginLeft: "20px",
                                }}
                                onClick={() => setIsOpenExportRelatorioCorrente(true)}
                            >
                                Exportar relatório
                            </Button>
                            <div style={{ width: "100%" }}>
                                <GraficoCorrenteRele dataRele={dataRele} date={date} />
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 64 }}>
                        <Col md={12} lg={6} style={{ textAlign: "center" }}>
                            <b style={{ fontSize: "20px" }}>Fator de potência x Tempo</b>{" "}
                            <Button
                                variant="contained"
                                style={{
                                    padding: "0 14px",
                                    color: "white",
                                    backgroundColor: "#0a5068",
                                    textTransform: "none",
                                    marginLeft: "20px",
                                }}
                                onClick={() => setIsOpenExportRelatorioFatorPotencia(true)}
                            >
                                Exportar relatório
                            </Button>
                            <div style={{ width: "100%" }}>
                                <GraficoFatorPotencia dataRele={dataRele} date={date} />
                            </div>
                        </Col>
                        <Col md={12} lg={6} style={{ textAlign: "center" }}>
                            <b style={{ fontSize: "20px" }}>Temperatura (ºC) x Tempo</b>{" "}
                            <Button
                                variant="contained"
                                style={{
                                    padding: "0 14px",
                                    color: "white",
                                    backgroundColor: "#0a5068",
                                    textTransform: "none",
                                    marginLeft: "20px",
                                }}
                                onClick={() => setIsOpenExportRelatorioTemperatura(true)}
                            >
                                Exportar relatório
                            </Button>
                            <div style={{ width: "100%" }}>
                                <GraficoTemperaturaRele dataRele={dataRele} date={date} />
                            </div>
                        </Col>
                    </Row>

                    <HrDegrade />

                    <Row style={{ textAlign: "center" }}>
                        {statusRele.map((inv, index) => (

                            <Col
                                key={index}
                                sm={1}
                                style={{
                                    background: "#FFFFFF",
                                    border: "1px solid #123C4B",
                                    boxSizing: "border-box",
                                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                                    borderRadius: "5px",
                                    margin: "5px",
                                    paddingBottom: "3px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                }}
                            >

                                <ReleStatus>
                                    Rele
                                    <br />
                                    {inv?.cod.toString().padStart(2, "0")}{" "}
                                </ReleStatus>
                                <br />
                                {inv.status === 0 && responseTimeHandle(inv.data) <= 20 ? (
                                    <span
                                        style={{
                                            fontWeight: 700,
                                            fontSize: "18px",
                                            letterSpacing: "0.02rem",
                                            color: "#049B5F",
                                        }}
                                    >
                                        Ativo
                                        <CheckCircleOutlineIcon style={{ color: "#049B5F" }} />
                                    </span>
                                ) : inv.status === 5120 && responseTimeHandle(inv.data) <= 20 ? (
                                    <span
                                        style={{
                                            fontWeight: 700,
                                            fontSize: "18px",
                                            letterSpacing: "0.02rem",
                                            color: "#FBB041",
                                        }}
                                    >
                                        Stand By
                                        <ModeStandbyOutlinedIcon style={{ color: "#FBB041" }} />
                                    </span>
                                ) : inv.status === 5632 && responseTimeHandle(inv.data) <= 20 ? (
                                    <span
                                        style={{
                                            fontWeight: 700,
                                            fontSize: "18px",
                                            letterSpacing: "0.02rem",
                                            color: "#3C96C6",
                                        }}
                                    >
                                        Iniciando
                                        <ModelTrainingOutlinedIcon style={{ color: "#3C96C6" }} />
                                    </span>
                                ) : dadosReleSelecionado?.status === 21760 && responseTimeHandle(inv.data) <= 20 ? (
                                    <span
                                        style={{
                                            fontWeight: 700,
                                            fontSize: "18px",
                                            letterSpacing: "0.02rem",
                                            color: "#D40707",
                                        }}
                                    >
                                        Falha
                                        <CancelOutlinedIcon style={{ color: "#D40707" }} />
                                    </span>
                                ) : (
                                    <span
                                        style={{
                                            fontWeight: 700,
                                            fontSize: "18px",
                                            letterSpacing: "0.02rem",
                                            color: "#ff9233",
                                            paddingLeft: "1%",
                                        }}
                                    >
                                        Offline
                                        <CloudOffOutlinedIcon style={{ paddingLeft: "1%", color: "#ff9233" }} />
                                    </span>
                                )}
                            </Col>
                        ))}
                    </Row>


                    <Dialog
                        open={passwordDialog}
                        onClose={() => setPasswordDialog(false)}
                        maxWidth="xs"

                    >
                        <DialogTitle>
                            CONFIRME SUA SENHA
                        </DialogTitle>
                        <DialogContent>
                            <Form onSubmit={(e) => { handleCheckPassword(password) }} >
                                <Form.Group controlId="passwordConfirmation">
                                    <Form.Control
                                        type="password"
                                        placeholder="Digite sua senha"
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </Form.Group>
                            </Form>
                        </DialogContent>
                        <DialogActions>
                            <Col className="col-6 p-2">
                                <Button
                                    variant="outlined"
                                    color="error"
                                    onClick={() => setPasswordDialog(false)}
                                >
                                    Cancelar
                                </Button>
                            </Col>
                            <Col className="col-6 text-end">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="success"
                                    disabled={checkingPassword}
                                    startIcon={checkingPassword ? <CircularProgress size={15} /> : null}
                                    onClick={() => {
                                        handleCheckPassword(password)
                                    }}
                                >
                                    Confirmar
                                </Button>
                            </Col>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        maxWidth="xs"
                        fullWidth
                        disableScrollLock={true}
                        open={isOpenRemoteRele}
                        onClose={() => setIsOpenRemoteRele(false)}
                    >
                        <DialogTitle className="text-center" style={{ color: "#0a5068" }}>
                            Certeza que deseja <b>{releAction.toUpperCase()}</b> O RELE {selectedOption.value}?
                        </DialogTitle>
                        <DialogContent>
                            <p>Por favor, tenha em mente que {releAction.toUpperCase()} o relé é uma ação que requer cuidado e pode afetar o funcionamento da usina solar.</p>
                            <p>Tem certeza que deseja {releAction.toUpperCase()}?</p>
                        </DialogContent>
                        <DialogActions style={{ color: "#0a5068" }}>

                            <Col className="col-6 p-2">
                                <Button

                                    className=""
                                    type="submit"
                                    variant="contained"
                                    color="success"
                                    onClick={() => setIsOpenRemoteRele(false)}
                                >
                                    Cancelar
                                </Button>
                            </Col>
                            <Col className="col-6 text-end">
                                <Button

                                    type="submit"
                                    variant="outlined"
                                    color="error"
                                    onClick={() => {
                                        setIsOpenRemoteRele(false)
                                        setPasswordDialog(true)
                                    }}
                                >
                                    Sim
                                </Button>
                            </Col>



                        </DialogActions>
                    </Dialog>

                    <Dialog
                        maxWidth="sm"
                        fullWidth
                        disableScrollLock={true}
                        open={isOpenExportRelatorioTensao}
                        onClose={() => setIsOpenExportRelatorioTensao(false)}
                    >
                        <DialogTitle style={{ color: "#0a5068" }}>
                            Exportar relatório de <b>Tensão</b>
                        </DialogTitle>
                        <DialogContent>
                            {isLoadingExportRelatorio ? (
                                <>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignContent: "center",
                                        }}
                                    >
                                        <LoadingSpin
                                            size="16px"
                                            width="2px"
                                            primaryColor={"#2e64a8"}
                                        />
                                    </Box>
                                </>
                            ) : (
                                <>
                                    <Divider
                                        variant="middle"
                                        sx={{ mb: 4, color: "#0a5068", padding: "0.5px" }}
                                    />
                                    <Box
                                        sx={{ display: "flex", justifyContent: "space-evenly" }}
                                    >
                                        <Button
                                            variant="contained"
                                            style={{
                                                color: "white",
                                                backgroundColor: "#0a5068",
                                                textTransform: "none",
                                            }}
                                            onClick={() =>
                                                handleExportRelatorioTensãoRele({
                                                    reportFormat: "CSV",
                                                })
                                            }
                                        >
                                            <b>CSV</b>{" "}
                                            <SiGooglesheets
                                                style={{ color: "white", marginLeft: 6 }}
                                            />
                                        </Button>
                                        <Button
                                            variant="contained"
                                            style={{
                                                color: "white",
                                                backgroundColor: "#0a5068",
                                                textTransform: "none",
                                            }}
                                            onClick={() =>
                                                handleExportRelatorioTensãoRele({
                                                    reportFormat: "XLSX",
                                                })
                                            }
                                        >
                                            <b>XLSX</b>{" "}
                                            <SiMicrosoftexcel
                                                style={{ color: "white", marginLeft: 6 }}
                                            />
                                        </Button>

                                        <Button
                                            variant="contained"
                                            style={{
                                                color: "white",
                                                backgroundColor: "#0a5068",
                                                textTransform: "none",
                                            }}
                                            onClick={() =>
                                                handleExportRelatorioTensãoRele({
                                                    reportFormat: "JSON",
                                                })
                                            }
                                        >
                                            <b>JSON</b>{" "}
                                            <SiJson style={{ color: "white", marginLeft: 6 }} />
                                        </Button>
                                    </Box>
                                </>
                            )}
                        </DialogContent>
                        <DialogActions style={{ color: "#0a5068" }}>
                            <Button
                                style={{ color: "#0a5068" }}
                                onClick={() => setIsOpenExportRelatorioTensao(false)}
                            >
                                Fechar
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        maxWidth="sm"
                        fullWidth
                        disableScrollLock={true}
                        open={isOpenExportRelatorioCorrente}
                        onClose={() => setIsOpenExportRelatorioCorrente(false)}
                    >
                        <DialogTitle style={{ color: "#0a5068" }}>
                            Exportar relatório de <b>Corrente</b>
                        </DialogTitle>
                        <DialogContent>
                            {isLoadingExportRelatorio ? (
                                <>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignContent: "center",
                                        }}
                                    >
                                        <LoadingSpin
                                            size="16px"
                                            width="2px"
                                            primaryColor={"#2e64a8"}
                                        />
                                    </Box>
                                </>
                            ) : (
                                <>
                                    <Divider
                                        variant="middle"
                                        sx={{ mb: 4, color: "#0a5068", padding: "0.5px" }}
                                    />
                                    <Box
                                        sx={{ display: "flex", justifyContent: "space-evenly" }}
                                    >
                                        <Button
                                            variant="contained"
                                            style={{
                                                color: "white",
                                                backgroundColor: "#0a5068",
                                                textTransform: "none",
                                            }}
                                            onClick={() =>
                                                handleExportRelatorioCorrenteRele({
                                                    reportFormat: "CSV",
                                                })
                                            }
                                        >
                                            <b>CSV</b>{" "}
                                            <SiGooglesheets
                                                style={{ color: "white", marginLeft: 6 }}
                                            />
                                        </Button>
                                        <Button
                                            variant="contained"
                                            style={{
                                                color: "white",
                                                backgroundColor: "#0a5068",
                                                textTransform: "none",
                                            }}
                                            onClick={() =>
                                                handleExportRelatorioCorrenteRele({
                                                    reportFormat: "XLSX",
                                                })
                                            }
                                        >
                                            <b>XLSX</b>{" "}
                                            <SiMicrosoftexcel
                                                style={{ color: "white", marginLeft: 6 }}
                                            />
                                        </Button>

                                        <Button
                                            variant="contained"
                                            style={{
                                                color: "white",
                                                backgroundColor: "#0a5068",
                                                textTransform: "none",
                                            }}
                                            onClick={() =>
                                                handleExportRelatorioCorrenteRele({
                                                    reportFormat: "JSON",
                                                })
                                            }
                                        >
                                            <b>JSON</b>{" "}
                                            <SiJson style={{ color: "white", marginLeft: 6 }} />
                                        </Button>
                                    </Box>
                                </>
                            )}
                        </DialogContent>
                        <DialogActions style={{ color: "#0a5068" }}>
                            <Button
                                style={{ color: "#0a5068" }}
                                onClick={() => setIsOpenExportRelatorioCorrente(false)}
                            >
                                Fechar
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        maxWidth="sm"
                        fullWidth
                        disableScrollLock={true}
                        open={isOpenExportRelatorioTemperatura}
                        onClose={() => setIsOpenExportRelatorioTemperatura(false)}
                    >
                        <DialogTitle style={{ color: "#0a5068" }}>
                            Exportar relatório de <b>Temperatura</b>
                        </DialogTitle>
                        <DialogContent>
                            {isLoadingExportRelatorio ? (
                                <>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignContent: "center",
                                        }}
                                    >
                                        <LoadingSpin
                                            size="16px"
                                            width="2px"
                                            primaryColor={"#2e64a8"}
                                        />
                                    </Box>
                                </>
                            ) : (
                                <>
                                    <Divider
                                        variant="middle"
                                        sx={{ mb: 4, color: "#0a5068", padding: "0.5px" }}
                                    />
                                    <Box
                                        sx={{ display: "flex", justifyContent: "space-evenly" }}
                                    >
                                        <Button
                                            variant="contained"
                                            style={{
                                                color: "white",
                                                backgroundColor: "#0a5068",
                                                textTransform: "none",
                                            }}
                                            onClick={() =>
                                                handleExportRelatorioTemperaturaRele({
                                                    reportFormat: "CSV",
                                                })
                                            }
                                        >
                                            <b>CSV</b>{" "}
                                            <SiGooglesheets
                                                style={{ color: "white", marginLeft: 6 }}
                                            />
                                        </Button>
                                        <Button
                                            variant="contained"
                                            style={{
                                                color: "white",
                                                backgroundColor: "#0a5068",
                                                textTransform: "none",
                                            }}
                                            onClick={() =>
                                                handleExportRelatorioTemperaturaRele({
                                                    reportFormat: "XLSX",
                                                })
                                            }
                                        >
                                            <b>XLSX</b>{" "}
                                            <SiMicrosoftexcel
                                                style={{ color: "white", marginLeft: 6 }}
                                            />
                                        </Button>

                                        <Button
                                            variant="contained"
                                            style={{
                                                color: "white",
                                                backgroundColor: "#0a5068",
                                                textTransform: "none",
                                            }}
                                            onClick={() =>
                                                handleExportRelatorioTemperaturaRele({
                                                    reportFormat: "JSON",
                                                })
                                            }
                                        >
                                            <b>JSON</b>{" "}
                                            <SiJson style={{ color: "white", marginLeft: 6 }} />
                                        </Button>
                                    </Box>
                                </>
                            )}
                        </DialogContent>
                        <DialogActions style={{ color: "#0a5068" }}>
                            <Button
                                style={{ color: "#0a5068" }}
                                onClick={() => setIsOpenExportRelatorioTemperatura(false)}
                            >
                                Fechar
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        maxWidth="sm"
                        fullWidth
                        disableScrollLock={true}
                        open={isOpenExportRelatorioFatorPotencia}
                        onClose={() => setIsOpenExportRelatorioFatorPotencia(false)}
                    >
                        <DialogTitle style={{ color: "#0a5068" }}>
                            Exportar relatório de <b>Fator de Potência</b>
                        </DialogTitle>
                        <DialogContent>
                            {isLoadingExportRelatorio ? (
                                <>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignContent: "center",
                                        }}
                                    >
                                        <LoadingSpin
                                            size="16px"
                                            width="2px"
                                            primaryColor={"#2e64a8"}
                                        />
                                    </Box>
                                </>
                            ) : (
                                <>
                                    <Divider
                                        variant="middle"
                                        sx={{ mb: 4, color: "#0a5068", padding: "0.5px" }}
                                    />
                                    <Box
                                        sx={{ display: "flex", justifyContent: "space-evenly" }}
                                    >
                                        <Button
                                            variant="contained"
                                            style={{
                                                color: "white",
                                                backgroundColor: "#0a5068",
                                                textTransform: "none",
                                            }}
                                            onClick={() =>
                                                handleExportRelatorioFatorPotenciaRele({
                                                    reportFormat: "CSV",
                                                })
                                            }
                                        >
                                            <b>CSV</b>{" "}
                                            <SiGooglesheets
                                                style={{ color: "white", marginLeft: 6 }}
                                            />
                                        </Button>
                                        <Button
                                            variant="contained"
                                            style={{
                                                color: "white",
                                                backgroundColor: "#0a5068",
                                                textTransform: "none",
                                            }}
                                            onClick={() =>
                                                handleExportRelatorioFatorPotenciaRele({
                                                    reportFormat: "XLSX",
                                                })
                                            }
                                        >
                                            <b>XLSX</b>{" "}
                                            <SiMicrosoftexcel
                                                style={{ color: "white", marginLeft: 6 }}
                                            />
                                        </Button>

                                        <Button
                                            variant="contained"
                                            style={{
                                                color: "white",
                                                backgroundColor: "#0a5068",
                                                textTransform: "none",
                                            }}
                                            onClick={() =>
                                                handleExportRelatorioFatorPotenciaRele({
                                                    reportFormat: "JSON",
                                                })
                                            }
                                        >
                                            <b>JSON</b>{" "}
                                            <SiJson style={{ color: "white", marginLeft: 6 }} />
                                        </Button>
                                    </Box>
                                </>
                            )}
                        </DialogContent>
                        <DialogActions style={{ color: "#0a5068" }}>
                            <Button
                                style={{ color: "#0a5068" }}
                                onClick={() => setIsOpenExportRelatorioFatorPotencia(false)}
                            >
                                Fechar
                            </Button>
                        </DialogActions>
                    </Dialog>


                </Paper >

            </>
        );
        formatInputValue
    }

    return RelesDiario
}