import axios from "axios";
import { getToken } from "./authService";

const apiCriacaoDados = axios.create({
    baseURL: "https://api-data-receiver.mv-toth.com",
    //baseURL: "http://localhost:3002",
});

apiCriacaoDados.interceptors.request.use(async (config) => {
    const token = getToken();
    if (token) {
        config.headers.Authorization = `${token}`;
    }
    return config;
});

// api.interceptors.response.use(
//     (response) => response,
//     (error) => {
//         if (error.response.status === 401) {
//             window.location = "/login";
//         }
//         throw error;
//     }
// );

export default apiCriacaoDados;
