import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Switch,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import * as userService from "../services/userService";
import { HeaderTitle } from "../styles/UsuariosStyle";
import { cpfMask, formatUserProfile } from "../utils/utils";

import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useTheme } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { SelectUsina } from "../components/SelectUsina";
import { updateReceiveAlarm } from "../services/alarmeService";
import { useAuth } from "../hooks/Auth";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#0a5068",
        color: "#fff",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: "#e9ecef",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
                style={{ marginTop: -15 }}
            >
                {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
                style={{ marginTop: -15 }}
            >
                {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
                style={{ marginTop: -15 }}
            >
                {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
                style={{ marginTop: -15, marginRight: 8 }}
            >
                {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

const defaultFilters = {
    nome: "",
    cpf: "",
    perfil: "",
    usina: "",
};

export default function Usuarios() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filters, setFilters] = useState(defaultFilters);

    const { enqueueSnackbar } = useSnackbar();
    const { setNotificationsCount, getUserPerfil } = useAuth();
    const userPerfil = getUserPerfil();

    useEffect(() => {
        if (filters.usina) getFilteredUsers();
        if (userPerfil === "RESPONSAVEL_USINA") navigate("/alarmes");

        let counter = 0;
        dispatch({ type: "SET_COUNTER", value: counter });

        const intervalId = setInterval(() => {
            counter++;
            dispatch({ type: "SET_COUNTER", value: counter });
            if (counter === 60) {
                // atualização a cada 1 minuto
                if (filters.usina) getFilteredUsers();
                counter = 0;
                dispatch({ type: "SET_COUNTER", value: counter });
            }
        }, 1000);

        return () => clearInterval(intervalId);
    }, [page, filters.usina]);

    const getFilteredUsers = async (limpar = false) => {
        try {
            setLoading(true);
            const { total, usuarios } = await userService.getFilteredUsers({
                filters: limpar ? { ...defaultFilters, usina: filters.usina } : filters,
                currentPage: page,
            });
            const formattedUsers = usuarios.map((item) => {
                return {
                    ...item,
                    recebe_alerta: item.usinasUsuario ? item.usinasUsuario[0].recebe_alarme : false,
                };
            });
            setUsers(formattedUsers);
            setTotal(total);
            setLoading(false);
        } catch (error) {
            enqueueSnackbar("Ocorreu um erro ao buscar os usuários!", { variant: "error" });
            setLoading(false);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleCheck = async (event, user) => {
        try {
            const newUsers = users.map((item) => {
                if (item.id === user.id) return { ...item, recebe_alerta: event.target.checked };
                return item;
            });
            setUsers(newUsers);
            await updateReceiveAlarm({
                usuario_id: user.id,
                usina_id: filters.usina,
                recebe_alarme: event.target.checked,
            });
        } catch (error) {
            const newUsers = users.map((item) => {
                if (item.id === user.id) return { ...item, recebe_alerta: !event.target.checked };
                return item;
            });
            setUsers(newUsers);
            enqueueSnackbar("Ocorreu um erro!", { variant: "error" });
        }
    };

    return (
        <>
            <Paper style={{ minHeight: " 90vh", padding: "38px" }} elevation={0}>
                <>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            mb: 8,
                        }}
                    >
                        <HeaderTitle>Controle de alarmes</HeaderTitle>
                        <Button
                            variant="contained"
                            onClick={() => {
                                navigate("/alarmes");
                            }}
                            style={{ backgroundColor: "#0a5068" }}
                        >
                            Visualizar alarmes
                        </Button>
                    </Box>
                    <Box sx={{ mb: 3, mt: -3 }}>
                        <HeaderTitle style={{ fontSize: 16 }}>Selecione a usina</HeaderTitle>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            mb: 4,
                        }}
                    >
                        <SelectUsina
                            value={filters.usina}
                            onChange={(e) => setFilters({ ...filters, usina: e.target.value })}
                        />
                    </Box>
                    {filters.usina && (
                        <>
                            <Box sx={{ mb: 3, mt: 6 }}>
                                <HeaderTitle style={{ fontSize: 16 }}>
                                    Selecione os usuários que receberão alarmes
                                </HeaderTitle>
                            </Box>

                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    mb: 4,
                                }}
                            >
                                <TextField
                                    style={{ width: "27%" }}
                                    id="filter-name"
                                    label="Nome"
                                    variant="outlined"
                                    value={filters.nome}
                                    onChange={(evt) =>
                                        setFilters({ ...filters, nome: evt.target.value })
                                    }
                                />
                                <TextField
                                    style={{ width: "27%" }}
                                    id="filtercpf"
                                    label="Cpf"
                                    variant="outlined"
                                    value={filters.cpf}
                                    onChange={(evt) =>
                                        setFilters({ ...filters, cpf: cpfMask(evt.target.value) })
                                    }
                                />
                                <FormControl style={{ width: "27%" }}>
                                    <InputLabel>Perfil</InputLabel>
                                    <Select
                                        value={filters.perfil}
                                        onChange={(e) =>
                                            setFilters({ ...filters, perfil: e.target.value })
                                        }
                                        label="perfil"
                                    >
                                        <MenuItem value="">Selecione</MenuItem>
                                        <MenuItem value={"ADMIN"}>Admin</MenuItem>
                                        <MenuItem value={"PROPRIETARIO_USINA"}>
                                            Proprietário da usina
                                        </MenuItem>
                                        <MenuItem value={"RESPONSAVEL_USINA"}>
                                            Responsável da usina
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                                <Button
                                    variant="outlined"
                                    onClick={() => {
                                        setFilters({ ...defaultFilters, usina: filters.usina });
                                        getFilteredUsers(true);
                                    }}
                                    style={{ color: "#0a5068", borderColor: "#0a5068" }}
                                >
                                    Limpar
                                </Button>

                                <Button
                                    onClick={() => {
                                        getFilteredUsers();
                                    }}
                                    size="large"
                                    style={{ backgroundColor: "#0a5068", color: "white" }}
                                >
                                    Buscar
                                </Button>
                            </Box>

                            {!loading ? (
                                <>
                                    <TableContainer
                                        sx={{ maxHeight: 460, marginTop: 3 }}
                                        component={Paper}
                                    >
                                        <Table
                                            stickyHeader
                                            sx={{ minWidth: 460 }}
                                            aria-label="customized table"
                                        >
                                            <TableHead>
                                                <TableRow style={{ height: 1 }}>
                                                    <StyledTableCell>Nome</StyledTableCell>
                                                    <StyledTableCell>Email</StyledTableCell>
                                                    <StyledTableCell>Cpf</StyledTableCell>
                                                    <StyledTableCell>Perfil</StyledTableCell>
                                                    <StyledTableCell align="center">
                                                        Recebe alarmes?
                                                    </StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {users.map((user) => (
                                                    <StyledTableRow key={user.id}>
                                                        <StyledTableCell component="th" scope="row">
                                                            {user.nome}
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            {user.email}
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            {user.cpf}
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            {formatUserProfile(user.perfil)}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="center">
                                                            <Switch
                                                                onChange={(e) =>
                                                                    handleCheck(e, user)
                                                                }
                                                                inputProps={{
                                                                    "aria-label": "controlled",
                                                                }}
                                                                checked={user.recebe_alerta}
                                                            />
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>

                                    <TableContainer
                                        style={{
                                            marginTop: 16,
                                            marginBottom: 0,
                                            borderBottom: 0,
                                            minHeight: 0,
                                        }}
                                    >
                                        <Table stickyHeader aria-label="customized table">
                                            <TableFooter>
                                                <TablePagination
                                                    rowsPerPageOptions={[]}
                                                    count={total}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    SelectProps={{
                                                        inputProps: {
                                                            "aria-label": "Linhas por página",
                                                        },
                                                    }}
                                                    onPageChange={handleChangePage}
                                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                                    ActionsComponent={TablePaginationActions}
                                                />
                                            </TableFooter>
                                        </Table>
                                    </TableContainer>
                                </>
                            ) : (
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        height: "50vh",
                                    }}
                                >
                                    <CircularProgress />
                                </Box>
                            )}
                        </>
                    )}
                </>
            </Paper>
        </>
    );
}
