import styled from "styled-components";

export const TitleMet = styled.div`
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.03em;
    font-size: 18px;
    line-height: 100%;
    color: #105168;
    grid-row-start: 2;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const TextMet = styled.div`
    font-family: Futura LT;
    font-style: normal;
    font-weight: bold;
    font-size: 29px;
    line-height: 100%;
    color: #105168;
    grid-row-start: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.02em;
    margin-top: 8px;
`;

export const SubTextMet = styled.div`
    font-family: Futura LT;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 100%;
    color: #105168;
    grid-row-start: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.02em;
    margin-top: 8px;
    margin-left: 6px;
`;

export const ImageBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
`;

export const ItemBox = styled.div`
    border: 1px solid #105168;
    padding: 16px;
    border-radius: 16px;
    min-width: 150px;
`;
