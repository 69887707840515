import React from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    TimeScale,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import "chartjs-adapter-moment";
import moment from "moment";
import { colors, converteParaHorarioLocal, irradianciasMap } from "../utils/utils";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    TimeScale,
    Title,
    Tooltip,
    Legend
);

export default function GraficoIrradiancia({ dataEstacao, date }) {
    const options = {
        radius: 1, // desabilitar pontos
        locale: 'pt-br',
        responsive: true,
        scales: {
            x: {
                grid: {
                    display: false,
                },
                type: "time",
                time: {
                    unit: "hour",
                    displayFormats: {
                        hour: "HH:00",
                    },
                },
                min: moment(new Date(date.year, date.month - 1, date.day, 5, 0, 0)),
                max: moment(new Date(date.year, date.month - 1, date.day, 20, 0, 0)),
            },
            y: {
                title: {
                    display: true,
                    text: "Irradiância (W/m²)",
                },
            },
        },
        tension: 0.4,
    };

    const data = {
        labels: dataEstacao?.map((g) => converteParaHorarioLocal(g.data)),
        datasets: [],
    };

    dataEstacao[0]?.estacaoDnis?.forEach((item, index) => {
        data.datasets.push({
            label: `Irradiância ${irradianciasMap[index + 1]}`,
            fill: true,
            data: dataEstacao?.map(
                (e) => e.estacaoDnis?.find((item) => item.cod == index + 1)?.valor
            ),
            borderColor: colors[index].borderColor,
            backgroundColor: colors[index].backgroundColor,
        });
    });
    return <Line options={options} data={data} />;
}
