import React from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    TimeScale,
    ArcElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import "chartjs-adapter-moment";
ChartJS.register(ArcElement);

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    TimeScale,
    Title,
    Tooltip,
    Legend
);

export default function GraficoDeConsumo() {

    const options = {
        plugins: {
            datalabels: {

                color: function (context) {
                    if (context.dataIndex === 1) {
                        return 'white';
                    } else {
                        return 'black';
                    }
                },
                formatter: function (value, context) {
                    return value + ' KWh';
                }
            }
        },
        responsive: true,
        maintainAspectRatio: false
    }

    const data = {
        labels: [
            'Cons. Instantâneo (KWh)',
            'Cons. da Concessionária (KWh)'
        ],
        datasets: [{
            label: 'Consumo(KWh)',
            data: [400, 500],
            backgroundColor: ['rgb(250, 199, 85)', 'rgb(0, 156, 119)'],
            hoverOffset: 4
        }]
    };

    return <Doughnut plugins={[ChartDataLabels]} options={options} data={data} />;
}
