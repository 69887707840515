import api from "./api";
import apiCriacaoDados from "./apiCriacaoDados"

export async function ativaRele({ cod, usina_id, data }) {
    const res = await apiCriacaoDados.post('reles/ativa-rele', { cod, usina_id, data });
    return res;
}

export async function desativaRele({ cod, usina_id, data }) {
    const res = await apiCriacaoDados.post('reles/desativa-rele', { cod, usina_id, data });
    return res;
}

export async function findBetweenDatesFromRele({ usinaId, intervaloDTO, releId }) {
    const res = await api.get(`/rele/${releId}/usina/${usinaId}/getBetweenDates`, {
        params: {
            ...intervaloDTO,
        },
    });
    return res.data;
}


export async function findAllLastRegister(usinaId) {
    const res = await api.get(`rele/usina/${usinaId}/getAllLastData`);
    return res.data;
}

export async function findLastDataFromRele({ usinaId, releId }) {
    const res = await api.get(`/rele/${releId}/usina/${usinaId}/getLastData`);
    return res.data;
}

export async function exportRelatorioRele({ usinaId, intervaloDTO, reportTypeDto }) {
    const params = {
        startDate: intervaloDTO.startDate,
        endDate: intervaloDTO.endDate,
        ...reportTypeDto,
    };

    const res = await api.get(`rele/0/usina/${usinaId}/getBetweenDates`, {
        params: { ...params },
        responseType: reportTypeDto.reportFormat == "JSON" ? "json" : "blob",
    });

    return res.data;
}
